// APP COMPONENT
// Upon rendering of App component, make a request to create and
// obtain a link token to be used in the Link component
import React, { useEffect, useState } from "react"
import { usePlaidLink } from "react-plaid-link"
import NCSvgIcon from "../../shared/NCSvgIcon"
import NCButton from "../../shared/NCButton"
import { AddIcon } from "../../libs/icons"
import { getPlaidAccessToken, getPlaidLinkToken } from "../../services/queries"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
const LinkBankAccount = ({ refetchBankDetails }) => {
  const [linkToken, setLinkToken] = useState(null)
  const generateToken = async () => {
    const data = await getPlaidLinkToken()
    const token = data?.data?.data?.link_token
    // eslint-disable-next-line no-console
    setLinkToken(token)
  }
  useEffect(() => {
    generateToken()
  }, [])
  return linkToken != null ? (
    <Link linkToken={linkToken} refetchBankDetails={refetchBankDetails} />
  ) : (
    <></>
  )
}
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link
const Link = props => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const onSuccess = React.useCallback(async (public_token, metadata) => {
    // send public_token to server
    try {
      const data = await getPlaidAccessToken({ public_token })
      const message = data?.data?.data?.message
      toast.success(message)
      props?.refetchBankDetails?.()
    } catch (err) {
      // console.log(err);
    }
  }, [])
  const config = {
    token: props.linkToken,
    onSuccess
  }
  const { open, ready } = usePlaidLink(config)
  return (
    <NCButton
      variant="contained"
      color="primary"
      onClick={() => open()}
      disabled={!ready}
      endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
    >
      {t("link_account")}
    </NCButton>
  )
}
export default LinkBankAccount
