import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  root: {
    height: "10px",
    width: "10px",
    backgroundColor: props => {
      if (
        props?.status?.toLowerCase() === props?.t("client.inactive")?.toLowerCase() ||
        props?.status?.toLowerCase() === "offline" ||
        props?.status?.toLowerCase() === props?.t("requests.declined")?.toLowerCase() ||
        props?.status?.toLowerCase() === "failed"
      ) {
        return "#E22134"
      } else if (
        props?.status?.toLowerCase() === props?.t("client.active")?.toLowerCase() ||
        props?.status?.toLowerCase() === props?.t("requests.approved")?.toLowerCase() ||
        props?.status?.toLowerCase() === "on a trip/ride" ||
        props?.status?.toLowerCase() === "verified" ||
        props?.status?.toLowerCase() === "success"
      ) {
        return "#1DB954"
      } else if (
        props?.status?.toLowerCase() === props?.t("requests.pending")?.toLowerCase() ||
        props?.status?.toLowerCase() === "idle" ||
        props?.status?.toLowerCase() === props?.t("requests.on_hold")?.toLowerCase() ||
        props?.status?.toLowerCase() === "processing"
      ) {
        return "#FF9800"
      }
    },
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px"
  }
})

const NCTableStatus = props => {
  const { t } = useTranslation();
  const cls = useStyles({...props,t})
  return (
    <>
      <div className={cls.root} />
      {props.status}
    </>
  )
}

export default NCTableStatus
