import { Box, Grid } from "@material-ui/core"
import React, { useState } from "react"
import NCText from "../../shared/NCText"
import { useTranslation } from "react-i18next"
import NCInput from "../../shared/NCInput"
import { DetailIcon, SearchIcon } from "../../libs/icons"
import { formatDateString } from "../../libs/utils"
import { useGetTransactionList } from "../../services/queries"
import useDebounce from "../../libs/hooks/Debounce"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import NCTable from "../../shared/table/NCTable"
import NCLoader from "../../shared/NCLoader"
import { useNavigate } from "react-router-dom"
import Filter from "../../shared/table/Filter"
import { IconButton } from "@mui/material"
import NCTableStatus from "../../shared/table/NCTableStatus"

const Transactions = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [statusFilter, setStatusFilter] = useState([])

  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const { isLoading, data } = useGetTransactionList(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: reportsData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <Filter
        options={[
          { key: "CANCEL", name: t("transactions.cancelled") },
          { key: "FAILED", name: t("transactions.failed") },
          { key: "PROCESSING", name: t("transactions.processing") },
          { key: "SUCCESS", name: t("transactions.success") }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }

  const getIdCell = cell => {
    return (
      <Grid container alignItems="center" spacing={20}>
        <NCText>{cell?.id}</NCText>
      </Grid>
    )
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton onClick={() => nav(`/transactions/${cell?.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  const getAdminName = cell => {
    return (
      <div>
        <NCText>{cell?.user?.name}</NCText>
      </div>
    )
  }

  const getTransactionAmount = cell => {
    return (
      <div>
        <NCText>$ {cell?.amount}</NCText>
      </div>
    )
  }

  const columns = [
    {
      Header: t("serial"),
      id: "sr_no",
      accessor: cell => cell?.id,
      disableSortBy: true
    },
    {
      Header: t("transaction_id"),
      id: "id",
      accessor: cell => getIdCell(cell),
      disableSortBy: true
    },
    {
      Header: t("admin.admin"),
      id: "admin",
      accessor: cell => getAdminName(cell),
      disableSortBy: true
    },
    {
      Header: t("amount"),
      id: "amount",
      accessor: cell => getTransactionAmount(cell),
      disableSortBy: true
    },
    // {
    //   Header: t("admin.admin"),
    //   id: "admin",
    //   accessor: cell =>
    //     cell?.client_request?.client_subadmin_info?.admin?.company_name,
    //   disableSortBy: true
    // },
    {
      Header: t("dateTime"),
      accessor: cell => formatDateString(cell.created_at),
      disableSortBy: true
    },
    {
      Header: t("status"),
      id: "status",
      accessor: cell => <NCTableStatus status={cell.status} />,
      defaultCanFilter: true,
      Filter: SelectColumnFilter,
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  return (
    <Box>
      <Box mb={2}>
        <Grid container justifyContent="space-between" mb={20}>
          <Grid item xs={12} md={4}>
            <NCText variant={"h4"}>{t("trasanctions")}</NCText>
          </Grid>
          <Grid item xs={12} md={2}>
            <NCInput
              value={query}
              onChange={e => {
                setQuery(e.target.value)
              }}
              placeholder={t("search_here")}
              startIcon={SearchIcon}
              className="rounded"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <NCTable
            columns={columns}
            data={reportsData || []}
            page={page}
            onNext={onNext}
            onPrev={onPrev}
            pageCount={total_pages}
            gotoPage={setPage}
            pageSize={pageSize || TABLE_CONFIG.PAGE_SIZE}
            setPageSize={setPageSize}
            // sorting={ordering}
            // setSorting={setOrdering}
            filter={statusFilter}
            setFilter={setStatusFilter}
          />
          {isLoading && <NCLoader open={true} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Transactions
