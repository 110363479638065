import { Box, Grid } from "@material-ui/core"
// import NCText from "../../shared/NCText"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import { FieldArray, Form, FormikProvider, useFormik } from "formik"
import AddProfileIcon from "../../assets/icons/add_profile_image.svg"
import { GENDER } from "../../libs/config"
import PhoneNumber from "../../components/PhoneNumber"
import * as Yup from "yup"
import NCButton from "../../shared/NCButton"
import { AddIcon, RemoveItemIcon } from "../../libs/icons"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { useEffect } from "react"
import { getAge } from "../../libs/utils"
import { useGetClientDetail, useGetSubAdmins } from "../../services/queries"
import { useAddClient, useUpdateClient } from "../../services/mutations"
import { toast } from "react-toastify"
import { useState } from "react"
import { useQueryClient } from "react-query"
// import TopNavBar from "../../components/TopNavBar"
import NCLoader from "../../shared/NCLoader"
import { useNavigate, useParams } from "react-router-dom"
import NCAddress from "../../shared/NCAddress"
import NCDatePicker from "../../shared/NCDatePicker"
import NCAutocomplete from "../../shared/NCAutocomplete"
import FormTemplate from "../../components/FormTemplate"
import { GoogleAnalyticsData, gaActions } from "../../libs/const"
import { useTranslation } from "react-i18next"

const AddClient = ({ edit = false }) => {
  const { clientId } = useParams()
  const { t } = useTranslation();
  const nav = useNavigate()
  const [preview, setPreview] = useState()
  const [search, setSearch] = useState(null)
  const { data, isLoading } = useGetSubAdmins(null, search)
  const addClientApi = edit ? useUpdateClient() : useAddClient()
  const subAdmins = data?.data?.data?.results || []
  const { data: clientResult } = clientId ? useGetClientDetail(clientId) : {}
  const clientData = clientResult?.data?.data || {}
  const queryClient = useQueryClient()
  const [adminName, setAdminName] = useState("")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: clientData?.avatar || null,
      first_name: clientData?.first_name || "",
      middle_name: clientData?.middle_name || "",
      last_name: clientData?.last_name || "",
      email: clientData?.email || "",
      phone: clientData?.phone || "",
      gender: clientData?.gender || "",
      dob: clientData?.dob || "",
      age: clientData?.age || "",
      address: clientData?.address || "",
      subadmin: clientData?.subadmin || "",
      latitude: clientData?.latitude || "",
      longitude: clientData?.longitude || "",
      place: clientData?.place || "",
      emergency_contact: {
        email: clientData?.emergency_contact?.email || "",
        phone: clientData?.emergency_contact?.phone || "",
        name: clientData?.emergency_contact?.name || ""
      },
      guardian: (clientData && clientData.guardian) || [
        {
          email: "",
          phone: "",
          first_name: "",
          last_name: ""
        }
      ]
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("client.error.first_name")),
      last_name: Yup.string().required(t("client.error.last_name")),
      email: Yup.string().email(t("client.error.email")),
      phone: Yup.string().required(t("client.error.phone")),
      gender: Yup.string().required(t("client.error.gender")),
      dob: Yup.date().typeError(t("client.error.dob_type_error")).required(t("client.error.dob")),
      address: Yup.string().required(t("client.error.address")),
      latitude: Yup.string().required(t("client.error.latitude")),
      longitude: Yup.string().required(t("client.error.longitude")),
      place: Yup.string().required(t("client.error.place")),
      identifier: Yup.string().required(t("client.error.identifier")),
      subadmin: Yup.string().required(t("client.error.subadmin")),
      emergency_contact: Yup.object().shape({
        email: Yup.string()
          .email(t("client.error.invalid_email"))
          .required(t("client.error.emergency_email")),
        phone: Yup.string().required(t("client.error.emergency_phone")),
        name: Yup.string().required(t("client.error.emergency_name"))
      }),
      guardian: Yup.array()
        .of(
          Yup.object().shape({
            first_name: Yup.string().required(
              t("client.error.guardian_first_name")
            ),
            last_name: Yup.string().required(t("client.error.guardian_last_name")),
            phone: Yup.string().required(t("client.error.guardian_phone")),
            email: Yup.string()
              .email(t("client.error.invalid_email"))
              .required(t("client.error.guardian_email"))
          })
        )
        // .required("Must have guardian")
        .min(1, t("client.error.min_1_guardian"))
    }),
    onSubmit: (values, { resetForm }) => {
      let updatedValues = { ...values }
      if (clientId) {
        updatedValues.id = clientId
      }
      addClientApi.mutate(updatedValues, {
        onSuccess: async () => {
          toast.success(t("client.success"))
          resetForm({ values: "" })
          queryClient.invalidateQueries(["getClientList"])
          GoogleAnalyticsData(gaActions.client.category,gaActions.client.action.add)
          nav("/clients")
        }
      })
    }
  })
  useEffect(() => {
    if (formik.values.dob) {
      const age = getAge(formik.values.dob)
      formik.setFieldValue("age", age)
    }
  }, [formik.values.dob])
  useEffect(() => {
    const obj = subAdmins?.filter(item => item.id == formik.values.subadmin)
    setAdminName(obj?.[0]?.admin?.company_name)
  }, [formik.values.subadmin])

  const footerButtons = (
    <>
      <Grid item>
        <NCButton
          variant="outlined"
          color="secondary"
          onClick={() => nav("/clients")}
        >
          {t("common.cancel")}
        </NCButton>
      </Grid>
      <Grid item>
        <NCButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
        >
          {edit ? t("common.update") : t("common.save")}
        </NCButton>
      </Grid>
    </>
  )
  return (
    <FormTemplate title={t("client.add_client")} footerButtons={footerButtons}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={2}>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={e => {
                      setPreview(URL.createObjectURL(e.target.files[0]))
                      formik.setFieldValue("avatar", e.target.files[0])
                    }}
                  />
                  <Box
                    // width={"150px"}
                    // height={"150px"}
                    className="cursor-pointer"
                  >
                    {preview || formik?.values?.avatar ? (
                      <img
                        src={preview || formik?.values?.avatar}
                        width={"100%"}
                        style={{ borderRadius: "100%" }}
                        htmlFor="contained-button-file"
                      />
                    ) : (
                      <img
                        src={AddProfileIcon}
                        width={"100%"}
                        htmlFor="contained-button-file"
                      />
                    )}
                  </Box>
                </label>
              </Grid>
              <Grid item xs={12} sm={8} md={10}>
                <FormSection title="Client Details">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.first_name")}
                        name={"first_name"}
                        required={true}
                        error={formik?.errors?.first_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.middle_name")}
                        name={"middle_name"}
                        error={formik?.errors?.middle_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.last_name")}
                        name={"last_name"}
                        required={true}
                        error={formik?.errors?.last_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.email_address")}
                        name={"email"}
                        error={formik?.errors?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={PhoneNumber}
                        label={t("common.phone_number")}
                        name={"phone"}
                        onChange={e => formik.setFieldValue("phone", `+${e}`)}
                        required={true}
                        error={formik?.errors?.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAutocomplete}
                        options={
                          Object.keys(GENDER).map(item => {
                            return { id: item, name: GENDER[item] }
                          }) || []
                        }
                        label={t("common.gender")}
                        name={"gender"}
                        onChange={v => {
                          formik.setFieldValue("gender", v)
                        }}
                        required={true}
                        error={formik?.errors?.gender}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCDatePicker}
                        label={t("common.dob")}
                        name={"dob"}
                        onChange={v => formik.setFieldValue("dob", v)}
                        max={new Date().toISOString().split("T")[0]}
                        required={true}
                        error={formik?.errors?.dob}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.age")}
                        name={"age"}
                        readOnly={true}
                        error={formik?.errors?.age}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAddress}
                        label={t("common.address")}
                        name={"address"}
                        onChange={e => {
                          formik.setFieldValue("place", e?.placeId);
                          formik.setFieldValue("longitude", e?.geometry?.longitude);
                          formik.setFieldValue("latitude", e?.geometry?.latitude);
                          formik.setFieldValue("address", e?.name);
                        }}
                        required={true}
                        error={formik?.errors?.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.student_id")}
                        name={"identifier"}
                        required={true}
                        error={formik?.errors?.identifier}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection title={t("client.subadmin")}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAutocomplete}
                        options={subAdmins || []}
                        label={t("client.select_subadmin")}
                        name={"subadmin"}
                        onChange={v => {
                          formik.setFieldValue("subadmin", v)
                        }}
                        loading={isLoading}
                        onSearch={v => setSearch(v)}
                        required={true}
                        error={formik?.errors?.subadmin}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("client.admin")}
                        name={"admin"}
                        value={adminName}
                        readOnly={true}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection title={t("client.add_emg_contact")}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("client.emg_contact_name")}
                        name={"emergency_contact.name"}
                        required={true}
                        error={formik?.errors?.emergency_contact?.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("client.emg_contact_email")}
                        name={"emergency_contact.email"}
                        required={true}
                        error={formik?.errors?.emergency_contact?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={PhoneNumber}
                        label={t("client.emg_phone_no")}
                        name={"emergency_contact.phone"}
                        onChange={e =>
                          formik.setFieldValue(
                            "emergency_contact.phone",
                            `+${e}`
                          )
                        }
                        required={true}
                        error={formik?.errors?.emergency_contact?.phone}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FieldArray
                  name="guardian"
                  render={arrayHelpers => (
                    <FormSection
                      title={
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <span>{t("client.guardians")}</span>
                          </Grid>
                          <Grid item>
                            <NCButton
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                arrayHelpers.push({
                                  first_name: "",
                                  last_name: "",
                                  email: "",
                                  phone: ""
                                })
                              }
                              endIcon={
                                <NCSvgIcon component={AddIcon} inheritViewBox />
                              }
                            >
                              {t("client.add_guardian")}
                            </NCButton>
                          </Grid>
                        </Grid>
                      }
                    >
                      <Grid container spacing={2}>
                        {formik.values.guardian &&
                          formik.values.guardian.length > 0
                          ? formik.values.guardian.map((guard, index) => (
                            <>
                              <Grid item container spacing={1} xs={11}>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={t("common.first_name")}
                                    name={`guardian[${index}].first_name`}
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]
                                        ?.first_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={t("common.last_name")}
                                    name={`guardian[${index}].last_name`}
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]
                                        ?.last_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={t("common.contact_email")}
                                    name={`guardian[${index}].email`}
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]?.email
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    as={PhoneNumber}
                                    label={t("client.emg_phone_no")}
                                    name={`guardian[${index}].phone`}
                                    onChange={e =>
                                      formik.setFieldValue(
                                        `guardian[${index}].phone`,
                                        `+${e}`
                                      )
                                    }
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]?.phone
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                  mt={"30px"}
                                >
                                  <RemoveItemIcon
                                    onClick={() => arrayHelpers.remove(index)}
                                    className="cursor-pointer"
                                  />
                                </Box>
                              </Grid>
                            </>
                          ))
                          : ""}
                      </Grid>
                    </FormSection>
                  )}
                ></FieldArray>
              </Grid>
            </Grid>
          </Grid>

          {addClientApi.isLoading && <NCLoader open={true} />}
        </Form>
      </FormikProvider>
    </FormTemplate>

    // </Grid>
  )
}

export default AddClient
