export const validationRegex = {
	onlyDigits: /^(?:\d+)?$/,
	alphabetsOnly: /^[A-Za-z ]+$/,
	email: /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+)?$/,
	specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
};

export const MAX_LENGTH = {
	EIGHT: 8,
	FIFTY: 50,
	HUNDRED: 100,
	TWOHUNDRED: 200,
	FIFTEEN_DIGIT: 999999999999999
};
