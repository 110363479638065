import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  styled
} from "@material-ui/core"
import expandIcon from "../assets/icons/expand.svg"
import collapseIcon from "../assets/icons/collapse.svg"

const CustomAccordion = styled(props => {
  // eslint-disable-next-line no-console
  console.log(props)
  return <Accordion disableGutters elevation={0} square {...props} />
})(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  color: "white",
  backgroundColor: "transparent",
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "& .MuiButtonBase-root .MuiIconButton-root": {
    padding: 0,
    paddingRight: "12px"
  },
  "&::before": {
    display: "none"
  }
}))

const CustomAccordionSummary = styled(props => {
  // eslint-disable-next-line no-console
  console.log(props)
  return (
    <AccordionSummary
      expandIcon={
        <img
          src={props.expanded ? collapseIcon : expandIcon}
          width={"18px"}
          height={"18px"}
        />
      }
      {...props}
    />
  )
})(({ theme }) => ({
  backgroundColor: "transparent",
  padding: 0,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  },
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(0deg)"
  }
}))

const useStyles = makeStyles(() => ({
  accordianDetails: {
    marginLeft: "20px",
    borderLeft: "5px solid #1EAAC4",
    paddingLeft: "10px"
  }
}))

const NCAccordion = ({ onChange, title, expanded, description }) => {
  const cls = useStyles()

  return (
    <CustomAccordion onChange={onChange}>
      <CustomAccordionSummary
        aria-controls="panel1-content"
        id={title}
        expanded={expanded}
      >
        {title}
      </CustomAccordionSummary>
      <AccordionDetails>
        <div className={cls.accordianDetails}>{description}</div>
      </AccordionDetails>
    </CustomAccordion>
  )
}

export default NCAccordion
