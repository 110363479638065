import React, { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import NCText from "../../shared/NCText"
import {
  Grid,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  ButtonGroup,
  Button,
  makeStyles
} from "@material-ui/core"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import NCInput from "../../shared/NCInput"
import { SearchIcon, DetailIcon } from "../../libs/icons"
import NCLoader from "../../shared/NCLoader"
import useDebounce from "../../libs/hooks/Debounce"
// import getQueryParams from "../libs/hooks/useQuery"
import { useGetRideDetails } from "../../services/queries"
import { formatDate } from "../../libs/utils"
import { rideStatus } from "../../libs/const"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  toggleButton: {
    borderRadius: "20px",
    fontSize: "12px"
  },
  circle: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px"
  },
  yColor: {
    backgroundColor: "#F9B50B"
  },
  gColor: {
    backgroundColor: "#4CAF50"
  }
}))

const MapViewListing = () => {
  const nav = useNavigate()
  const cls = useStyles()
  const {t} = useTranslation();
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])

  const WAITING = 5
  const ON_ROUTE = 7
  const ALL = null;

  const [currentView, setCurrentView] = useState(ALL)
  // let queryParams = getQueryParams()
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const { isLoading, data } = useGetRideDetails({
    page,
    search: debouncedSearchTerm,
    pageSize,
    ordering,
    // ongoing: true,
    status: currentView
  })

  const {
    next,
    previous,
    total_pages,
    results: driverData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => nav(`/map-view-listing/${cell.id}`)}>
            <DetailIcon />
          </IconButton>
        </Box>
      </div>
    )
  }

  const getDetailsByClientName = cell => {
    //   console.log(cell)
    return (
      <div onClick={() => nav(`/map-view-listing/${cell.id}`)}>
        <NCText className={"clickable-text"}>{cell?.client?.name}</NCText>
      </div>
    )
  }

  const getStatus = cell => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <span
          className={[
            cls.circle,
            cell.status !== 5 ? cls.gColor : cls.yColor
          ].join(" ")}
        ></span>
        <span>{t("status_o."+rideStatus[cell.status])}</span>
      </Box>
    )
  }

  const handleToggleView = view => {
    setCurrentView(view)
  }

  const columns = [
    {
      Header: t("list.request_id"),
      id: "id",
      accessor: cell => cell.id,
      Cell: ({ value }) => (
        <NCText
          className={"clickable-text"}
          onClick={() => nav(`/map-view-listing/${value}`)}
        >
          {value}
        </NCText>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.user_name"),
      id: "name",
      accessor: cell => getDetailsByClientName(cell)
    },
    {
      Header: t("list.date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    {
      Header: t("list.status"),
      accessor: cell => getStatus(cell)
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{t("map.heading")}</NCText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container justifyContent="flex-end" mb={20}>
            {/* <Grid item xs={12} md={4}>
              <NCText variant={"h5"}>On-Going Requests</NCText>
            </Grid> */}
            <Grid
              item
              xs={12}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <ButtonGroup disableElevation>
                    <Button
                      className={cls.toggleButton}
                      variant={currentView === ALL ? "contained" : "outlined"}
                      onClick={handleToggleView.bind(this, ALL)}
                      color={currentView === ALL ? "primary" : ""}
                    >
                      {t("map.all")}
                    </Button>
                    <Button
                      className={cls.toggleButton}
                      variant={
                        currentView === WAITING ? "contained" : "outlined"
                      }
                      onClick={handleToggleView.bind(this, WAITING)}
                      color={currentView === WAITING ? "primary" : ""}
                    >
                      {t("map.waiting_for_driver")}
                    </Button>
                    <Button
                      className={cls.toggleButton}
                      variant={
                        currentView === ON_ROUTE ? "contained" : "outlined"
                      }
                      onClick={handleToggleView.bind(this, ON_ROUTE)}
                      color={currentView === ON_ROUTE ? "primary" : ""}
                    >
                      {t("map.on_route")}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={driverData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
    </Grid>
  )
}
export default MapViewListing
