import { useState } from "react"
import { Grid, makeStyles, Paper, Box } from "@material-ui/core"
import NCTitle from "../shared/NCTitle"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
// import { toast } from "react-toastify"
import { useAuthentication } from "../services/mutations"
import VerifyAuth from "./VerifyAuth"
import NCLoader from "../shared/NCLoader"
import FormField from "../components/FormField"
import NCInput from "../shared/NCInput"
import withContext from "../components/DrawerConsumer"
import { STORAGE } from "../libs/storage"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
    minWidth: "350px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& .MuiFormLabel-root": {
      marginBottom: "5px"
    },
    "& .MuiButtonBase-root": {
      marginTop: "25px"
    }
  }
}))

const EmailAuthentication = props => {
  const cls = useStyles()
  const user = STORAGE.getUserDetails();
  const emailAuth = useAuthentication()
  const [isLinkSent, setIsLinkSent] = useState(false)
  const [isEmailContent, setIsEmailContent] = useState(true)
  const [verificationId, setVerificationId] = useState()
  const formik = useFormik({
    initialValues: {
      email: user?.email
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required")
    }),
    onSubmit(values) {
      emailAuth.mutate(
        { two_factor_config: { method: "EMAIL", email: values.email } },
        {
          onSuccess: response => {
            setVerificationId(response?.data?.data?.id)
            //toast.success("Verification code is sent to the email")
            props.setEmailMessage(
              "Please check your inbox and spam/junk folder for the verification code email."
            )
            props.showEmailModal(true)
            props.showModalHeader("Email sent!")
            setIsEmailContent(false)
            setIsLinkSent(true)
          },
          onError: () => {
            //console.log("error block")
          }
        }
      )
    }
  })

  const enterEmailContent = (
    <Grid item xs={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>Enter Email</NCTitle>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <Box mt={"15px"}>
              <FormField
                as={NCInput}
                name={"email"}
                readOnly
                placeholder="Enter email address"
                required={true}
                error={formik?.errors?.email}
              />
            </Box>
            <NCButton variant="contained" color="primary" type="submit">
              Send Code
            </NCButton>
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )

  return (
    <>
      {emailAuth.isLoading && <NCLoader open={true} />}
      {isEmailContent && <AuthComponent> {enterEmailContent} </AuthComponent>}
      {isLinkSent && (
        <VerifyAuth
          verificationId={verificationId}
          email={formik?.values?.email}
        />
      )}
    </>
  )
}

export default withContext(EmailAuthentication)
