import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import NCInput from "../../shared/NCInput"
import NCText from "../../shared/NCText"
import { SearchIcon, UpDownIcon } from "../../libs/icons"
import NCList from "../../shared/NCList"
import SimpleMenu from "../../components/ExportMenu"
import NCCalendarButton from "../../shared/NCCalendarButton"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useGetDrivers, useGetIncidentreports } from "../../services/queries"
import useDebounce from "../../libs/hooks/Debounce"
import { SEARCH_CONFIG } from "../../libs/config"
import NCLoader from "../../shared/NCLoader"
import { formatIncidentDate } from "../../libs/utils"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  search: {
    width: "100%"
  },
  container: {
    height: "calc(100vh - 200px)",
    overflow: "auto"
  },
  pointer: {
    cursor: "pointer",
    marginRight: "20px"
  }
}))

const Incidents = () => {
  const cls = useStyles()

  const { t } = useTranslation();

  const navigate = useNavigate()
  const location = useLocation()

  const mobileTheme = useTheme()
  const isMediumScreen = useMediaQuery(mobileTheme.breakpoints.up("md"))

  const [searchText, setSearchText] = useState(null)

  const [items, setItems] = useState([])

  const [driverList, setDriverList] = useState([])

  const [selectedDriver, setSelectedDriver] = useState(null)

  const [selectedDate, setSelectedDate] = useState(null)

  const order = "-created_at"

  const [ordering, setOrdering] = useState(order)

  const debouncedSearchTerm = useDebounce(
    searchText,
    SEARCH_CONFIG.TIME_IN_MILLI
  )

  const userListPath = "/incidents"

  const { data: driverData, isLoading: isDriverLoading } = useGetDrivers(
    1,
    null,
    100
  )

  const { data, isLoading } = useGetIncidentreports(
    null,
    debouncedSearchTerm,
    null,
    selectedDriver,
    selectedDate,
    ordering
  )

  const handleSearch = e => {
    const value = e.target.value
    setSearchText(value)
  }

  useEffect(() => {
    const reportList = data?.data?.data?.results || []
    const UpdatedReportItem = reportList?.map(report => {
      return {
        id: report.id,
        avatar: report.driver_details?.avatar,
        name: report.driver_details?.name,
        title: report?.title,
        date: report.created_at ? new Date(report.created_at) : null
      }
    })
    setItems(UpdatedReportItem)
  }, [data])

  useEffect(() => {
    const driverList = driverData?.data?.data?.results || []
    driverList.unshift({
      key: null,
      name: "All Drivers"
    })
    const driverListForSelection = driverList?.map(driver => ({
      key: driver?.id,
      name: driver?.name
    }))
    setDriverList(driverListForSelection)
  }, [driverData])

  const handleClick = item => {
    navigate("/incidents/" + item.id, {
      replace: location.pathname !== userListPath
    })
  }

  const handleOrdering = () => {
    if (ordering === order) {
      setOrdering("created_at")
    } else {
      setOrdering("-created_at")
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12}>
              <NCText variant={"h4"}>{t("incident.heading")}</NCText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {(!isMediumScreen ? location.pathname === userListPath : true) && (
        <Grid item xs={12} md={4}>
          <Grid>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <NCInput
                      value={searchText}
                      startIcon={SearchIcon}
                      onChange={handleSearch}
                      className={["rounded", cls.search].join(" ")}
                      placeholder={t("common.search_here")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginTop={"20px"}
                    marginBottom={"10px"}
                    alignItems={"center"}
                  >
                    <Box display={"flex"} gridGap={"20px"}>
                      <Box width={"150px"}>
                        <SimpleMenu
                          items={driverList}
                          itemAction={item => {
                            setSelectedDriver(item.key)
                          }}
                          asDropdown={true}
                          heading={"All Drivers"}
                        />
                      </Box>
                      <Box width={"150px"}>
                        <NCCalendarButton
                          selectedDate={selectedDate => {
                            selectedDate
                              ? setSelectedDate(
                                  formatIncidentDate(selectedDate, "YYYY-MM-DD")
                                )
                              : setSelectedDate(null)
                          }}
                        />
                      </Box>
                    </Box>
                    <UpDownIcon
                      className={cls.pointer}
                      onClick={handleOrdering}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2} className={cls.container}>
              <Grid container>
                <Grid item xs={12}>
                  <NCList items={items} onClick={handleClick} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {(!isMediumScreen ? location.pathname !== userListPath : true) && (
        <Grid item xs={12} md={8}>
          <Outlet />
        </Grid>
      )}
      {(isLoading || isDriverLoading) && <NCLoader open={true} />}
    </Grid>
  )
}

export default Incidents
