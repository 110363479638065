import { Box, Grid } from "@material-ui/core"
import NCText from "../../shared/NCText"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import { Form, FormikProvider, useFormik } from "formik"
import AddProfileIcon from "../../assets/icons/add_profile_image.svg"
import {
  CITIES,
  COUNTRIES,
  DRIVER_TYPE,
  GENDER,
  STATES,
  VEHICLE_FACILITIES
} from "../../libs/config"
import PhoneNumber from "../../components/PhoneNumber"
import * as Yup from "yup"
import NCButton from "../../shared/NCButton"
import { useEffect, useRef } from "react"
import { getAge, getDriverTypeById, saveQR } from "../../libs/utils"
import { useGetDriverDetail } from "../../services/queries"
import { useAddDriver, useUpdateDriver } from "../../services/mutations"
import { toast } from "react-toastify"
import { useState } from "react"
import { useQueryClient } from "react-query"
import TopNavBar from "../../components/TopNavBar"
import NCLoader from "../../shared/NCLoader"
import { useNavigate, useParams } from "react-router-dom"
// import NCAddress from "../../shared/NCAddress"
import NCAutocomplete from "../../shared/NCAutocomplete"
import NotificationAlert from "../../components/notification/NotificationAlert"
import NCModal from "../../shared/NCModal"
import { QRCodeCanvas } from "qrcode.react"
import { useReactToPrint } from "react-to-print"
import { useTranslation } from "react-i18next"
import FormSwitch from "../../components/FormSwitch"
import NCAddress from "../../shared/NCAddress"

const AddDriver = () => {
  const { id } = useParams()
  const edit = !window.location.href.includes("add")
  const nav = useNavigate()
  const [preview, setPreview] = useState()
  const addDriverApi = edit ? useUpdateDriver() : useAddDriver()
  const { data: driverResult } = id ? useGetDriverDetail(id) : {}
  const driverData = driverResult?.data?.data || {}
  const queryClient = useQueryClient()
  const [modalState, setModalState] = useState(false)
  const [uuid, setUuid] = useState(null)
  const [facilityArr, setFacilityArr] = useState(
    driverData?.vehicle?.vehicle_facilities || []
  )
  const qrRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (driverData?.vehicle?.vehicle_facilities) {
      setFacilityArr(driverData?.vehicle?.vehicle_facilities || [])
    }
  }, [driverData])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: null,
      first_name: driverData?.first_name || "",
      middle_name: driverData?.middle_name || "",
      last_name: driverData?.last_name || "",
      email: driverData?.email || "",
      phone: driverData?.phone || "",
      gender: driverData?.gender || "",
      address: driverData?.address || "",
      latitude: driverData?.latitude || "",
      longitude: driverData?.longitude || "",
      job_location_country: driverData?.job_location_country || "",
      job_location_state: driverData?.job_location_state || "",
      job_location_county: driverData?.job_location_county || "",
      job_location_city: driverData?.job_location_city || "",
      type: getDriverTypeById(driverData?.driver_type) || "",
      vehicle: {
        vin_no: driverData?.vehicle?.vin_no,
        mark: driverData?.vehicle?.mark,
        model: driverData?.vehicle?.model,
        licence_plate: driverData?.vehicle?.licence_plate,
        capacity: driverData?.vehicle?.capacity
      }
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("admin.first_name_required")),
      last_name: Yup.string().required(t("admin.last_name_required")),
      email: Yup.string()
        .email(t("admin.invalid_email"))
        .required(t("admin.email_required")),
      phone: Yup.string().required(t("admin.phone_required")),
      gender: Yup.string().required(t("client.error.gender")),
      address: Yup.string().required(t("client.error.address")),
      latitude: Yup.string().required(t("client.error.latitude")),
      longitude: Yup.string().required(t("client.error.longitude")),
      job_location_country: Yup.string().required(t("drivers.error.job_country")),
      job_location_state: Yup.string().required(t("drivers.error.job_state")),
      job_location_city: Yup.string().required(t("drivers.error.job_city")),
      type: Yup.string().required(t("drivers.error.type")),
      vehicle: Yup.object().shape({
        vin_no: Yup.string().required(t("drivers.error.vin")),
        mark: Yup.string().required(t("drivers.error.make")),
        model: Yup.string().required(t("drivers.error.model")),
        licence_plate: Yup.string().required(t("drivers.error.license_plate")),
        capacity: Yup.number().required(t("drivers.error.capacity")).min(1,t("drivers.error.capacity_min"))
      })
    }),
    onSubmit: (values, { resetForm }) => {
      let updatedValues = { ...values }
      if (id) {
        updatedValues.id = id
      }
      updatedValues.vehicle.vehicle_facilities = facilityArr
      addDriverApi.mutate(updatedValues, {
        onSuccess: async resp => {
          toast.success(
            id ? t("drivers.updated") : t("drivers.added")
          )
          resetForm({ values: "" })
          queryClient.invalidateQueries(["getDriverList"])
          queryClient.invalidateQueries(["getDriverAuditLog"])
          setUuid(resp?.data?.data?.uuid)
          id ? handleClose() : setModalState(true)
        }
      })
    }
  })

  const handlePrint = useReactToPrint({
    content: () => qrRef.current
  })

  const requestModal = (
    <>
      <Grid container>
        <Grid item xs={12}>
          <NCText
            style={{
              marginLeft: "16px",
              color: "#2E3B36",
              fontSize: "14px",
              fontWeight: "550"
            }}
          >
            {t("drivers.qr_message")}
          </NCText>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            marginTop={"20px"}
            marginBottom={"20px"}
            ref={qrRef}
          >
            <QRCodeCanvas
              id="qrcode-canvas"
              style={{ height: "auto", width: "200px" }}
              value={`${window.location.origin}/verify-driver/?uuid=${uuid}`}
              level="H"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )

  const handleClose = () => {
    nav("/drivers")
  }

  useEffect(() => {
    if (formik.values.dob) {
      const age = getAge(formik.values.dob)
      formik.setFieldValue("age", age)
    }
  }, [formik.values.dob])

  const getCityListForState = state => {
    if (!state) {
      return []
    }
    const cityList = CITIES.filter(item => item.state === state)
    const list = cityList.map(item => {
      return { id: item.city, name: item.city }
    })
    return list || []
  }

  // const onFacilityToggle = (facilityName, checked) => {
  //   let facilityIndex = getFacilityFromArray(facilityName, facilityArr)
  //   if (facilityIndex > 1 && !checked) {
  //     // Clear the facility item from array
  //     const newFacilityArr = facilityArr[facilityIndex]
  //     newFacilityArr.splice(facilityIndex)
  //     setFacilityArr(newFacilityArr)
  //   } else if (checked) {
  //     // Add the facility item to array
  //     setFacilityArr([
  //       ...facilityArr,
  //       {
  //         type: facilityName,
  //         quantity: 1
  //       }
  //     ])
  //   }
  // }

  /**
   * Method to add/remove facility from vehicle facility array
   * @param {Name of the facility} facilityName
   * @param {Current toggle state of facility} onChecked
   * @returns
   */
  const onFacilityToggle = (facilityName, onChecked) => {
    const updatedArr = [...facilityArr]
    const facilityIndex = updatedArr.findIndex(f => f.type === facilityName)
    if (facilityIndex > -1 && !onChecked) {
      // If facility is available and toggled off
      updatedArr.splice(facilityIndex, 1)
    } else if (facilityIndex === -1 && onChecked) {
      // If facility is not available and toggled on, add it array
      updatedArr.push({
        type: facilityName,
        quantity: 1
      })
    }
    setFacilityArr(updatedArr)
  }

  /**
   * Method to update the quantity of the facility
   * @param {Name of the facility} facilityName
   * @param {Quantity of the facility} quantity
   */
  const onFacilityQuantityChanged = (facilityName, quantity) => {
    const updatedArr = [...facilityArr]
    const facilityIndex = updatedArr.findIndex(f => f.type === facilityName)
    if (facilityIndex > -1) {
      updatedArr[facilityIndex].quantity = quantity > 0 ? quantity : 1
    }
    setFacilityArr(updatedArr)
  }

  const isFacilityAvailable = facilityName => {
    if (facilityArr.length === 0) return false
    const facility = facilityArr.find(f => f.type === facilityName)
    return facility ? true : false
  }

  const getFacilityQuantityFromArray = facilityName => {
    if (facilityArr.length === 0) return ""
    const facility = facilityArr.find(f => f.type === facilityName)
    return facility ? facility.quantity : ""
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2}>
          <NCText variant={"h4"}>
            {edit ? t("drivers.update_driver") : t("drivers.add_driver")}
          </NCText>
        </Box>
        <Box>
          <NotificationAlert />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <FormikProvider value={formik}>
        <Form
          onSubmit={e => {
            formik.handleSubmit(e)
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={2}>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={e => {
                      setPreview(URL.createObjectURL(e.target.files[0]))
                      formik.setFieldValue("avatar", e.target.files[0])
                    }}
                  />
                  <Box
                    // width={"150px"}
                    // height={"150px"}
                    className="cursor-pointer"
                  >
                    {preview || formik?.values?.avatar ? (
                      <img
                        src={preview || formik?.values?.avatar}
                        width={"100%"}
                        style={{ borderRadius: "100%" }}
                        htmlFor="contained-button-file"
                      />
                    ) : (
                      <img
                        src={AddProfileIcon}
                        width={"100%"}
                        htmlFor="contained-button-file"
                      />
                    )}
                  </Box>
                </label>
              </Grid>
              <Grid item xs={12} sm={8} md={10}>
                <FormSection title="Driver Details">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.first_name")}
                        name={"first_name"}
                        required={true}
                        error={formik?.errors?.first_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.middle_name")}
                        name={"middle_name"}
                        error={formik?.errors?.middle_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.last_name")}
                        name={"last_name"}
                        required={true}
                        error={formik?.errors?.last_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("common.email_address")}
                        name={"email"}
                        required={true}
                        error={formik?.errors?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={PhoneNumber}
                        label={t("common.phone_number")}
                        name={"phone"}
                        getOptionLabel={option => option.name || ""}
                        onChange={e => formik.setFieldValue("phone", `+${e}`)}
                        required={true}
                        error={formik?.errors?.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAutocomplete}
                        options={
                          Object.keys(GENDER).map(item => {
                            return { id: item, name: GENDER[item] }
                          }) || []
                        }
                        label={t("common.gender")}
                        name={"gender"}
                        onChange={v => {
                          formik.setFieldValue("gender", v)
                        }}
                        required={true}
                        error={formik?.errors?.gender}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormField
                        as={NCAddress}
                        label={t("common.address")}
                        name={"address"}
                        onChange={e => {
                          formik.setFieldValue(
                            "longitude",
                            e?.geometry?.longitude
                          )
                          formik.setFieldValue(
                            "latitude",
                            e?.geometry?.latitude
                          )
                          formik.setFieldValue("address", e?.name)
                        }}
                        required={true}
                        error={formik?.errors?.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("job_country")}
                        name={"job_location_country"}
                        as={NCAutocomplete}
                        options={COUNTRIES.map(item => {
                          return {
                            id: item.value,
                            name: item.label
                          }
                        })}
                        onChange={v => {
                          formik.setFieldValue("job_location_country", v)
                        }}
                        required={true}
                        error={formik?.errors?.job_location_country}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("job_state")}
                        name={"job_location_state"}
                        as={NCAutocomplete}
                        options={
                          formik.values?.job_location_country
                            ? STATES.map(item => {
                                return {
                                  id: item.value,
                                  name: item.label
                                }
                              })
                            : []
                        }
                        onChange={v => {
                          formik.setFieldValue("job_location_state", v)
                        }}
                        required={true}
                        error={formik?.errors?.job_location_state}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("job_city")}
                        name={"job_location_city"}
                        as={NCAutocomplete}
                        options={getCityListForState(
                          formik.values.job_location_state
                        )}
                        onChange={v => {
                          formik.setFieldValue("job_location_city", v)
                        }}
                        required={true}
                        error={formik?.errors?.job_location_city}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("driver_type")}
                        name={"type"}
                        placeholder={t("select_driver_type")}
                        as={NCAutocomplete}
                        options={
                          Object.keys(DRIVER_TYPE).map(item => {
                            return { id: item, name: DRIVER_TYPE[item] }
                          }) || []
                        }
                        onChange={v => {
                          formik.setFieldValue("type", v)
                        }}
                        required={true}
                        error={formik?.errors?.type}
                        disabled={
                          driverData?.driver_type &&
                          driverData?.driver_type !== ""
                        }
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection title="Vehicle Details">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("list.mark")}
                        name={"vehicle.mark"}
                        required={true}
                        error={formik?.errors?.vehicle?.mark}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("list.model")}
                        name={"vehicle.model"}
                        required={true}
                        error={formik?.errors?.vehicle?.model}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("list.vin")}
                        name={"vehicle.vin_no"}
                        required={true}
                        error={formik?.errors?.vehicle?.vin_no}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={t("list.license_plate")}
                        name={"vehicle.licence_plate"}
                        required={true}
                        error={formik?.errors?.vehicle?.licence_plate}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection title={t("vehicle_offering")}>
                  <Grid container spacing={4}>
                    <Grid item md={7}>
                      <FormSwitch
                        label={t("vehicles.buckle_guard")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.BUCKLE_GUARD
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.BUCKLE_GUARD,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={5}>
                      <FormField
                        label={ t("vehicles.quantity")}
                        type="number"
                        value={getFacilityQuantityFromArray(
                          VEHICLE_FACILITIES.BUCKLE_GUARD
                        )}
                        onChange={e =>
                          onFacilityQuantityChanged(
                            VEHICLE_FACILITIES.BUCKLE_GUARD,
                            e.target.value
                          )
                        }
                        disabled={
                          !isFacilityAvailable(VEHICLE_FACILITIES.BUCKLE_GUARD)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={7}>
                      <FormSwitch
                        label={t("vehicles.booster")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.BOOSTER
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.BOOSTER,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={5}>
                      <FormField
                        label={ t("vehicles.quantity")}
                        value={getFacilityQuantityFromArray(
                          VEHICLE_FACILITIES.BOOSTER
                        )}
                        disabled={
                          !isFacilityAvailable(VEHICLE_FACILITIES.BOOSTER)
                        }
                        type="number"
                        onChange={e =>
                          onFacilityQuantityChanged(
                            VEHICLE_FACILITIES.BOOSTER,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={7}>
                      <FormSwitch
                        label={t("vehicles.safety_vest")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.SAFETY_VEST
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.SAFETY_VEST,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={5}>
                      <FormField
                        label={ t("vehicles.quantity")}
                        value={getFacilityQuantityFromArray(
                          VEHICLE_FACILITIES.SAFETY_VEST
                        )}
                        type="number"
                        disabled={
                          !isFacilityAvailable(VEHICLE_FACILITIES.SAFETY_VEST)
                        }
                        onChange={e =>
                          onFacilityQuantityChanged(
                            VEHICLE_FACILITIES.SAFETY_VEST,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={7}>
                      <FormSwitch
                        label={t("vehicles.car_seat")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.CAR_SEAT
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.CAR_SEAT,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={5}>
                      <FormField
                        label={ t("vehicles.quantity")}
                        value={getFacilityQuantityFromArray(
                          VEHICLE_FACILITIES.CAR_SEAT
                        )}
                        type="number"
                        disabled={
                          !isFacilityAvailable(VEHICLE_FACILITIES.CAR_SEAT)
                        }
                        onChange={e =>
                          onFacilityQuantityChanged(
                            VEHICLE_FACILITIES.CAR_SEAT,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} xs={12}>
                    <Grid item md={4}>
                      <FormSwitch
                        label={t("vehicles.wheel_chair")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.WHEELCHAIR
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.WHEELCHAIR,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormSwitch
                        label={t("vehicles.service_dog")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.SERVICE_DOG
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.SERVICE_DOG,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormSwitch
                        label={t("vehicles.travel_alone")}
                        checked={isFacilityAvailable(
                          VEHICLE_FACILITIES.TRAVEL_ALONE
                        )}
                        onChange={e => {
                          onFacilityToggle(
                            VEHICLE_FACILITIES.TRAVEL_ALONE,
                            e.target.checked
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={7} md={5}>
                    <FormField
                      label={t("vehicles.vehicle_capacity")}
                      name={"vehicle.capacity"}
                      required={true}
                      type="number"
                      error={formik?.errors?.vehicle?.capacity}
                    />
                  </Grid>
                </FormSection>
              </Grid>
            </Grid>
          </Grid>
          <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <NCButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => nav("/drivers")}
                  >
                    {t("common.cancel")}
                  </NCButton>
                </Grid>
                <Grid item>
                  <NCButton type="submit" variant="contained" color="primary">
                    {edit ? t("common.update") : t("common.save")}
                  </NCButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {addDriverApi.isLoading && <NCLoader open={true} />}
        </Form>
      </FormikProvider>
      <NCModal
        open={modalState}
        className={"w-400"}
        title={t("drivers.new_driver_added")}
        text={requestModal}
        onClose={() => handleClose()}
        cancelButton={<></>}
        submitButton={
          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            <NCButton
              variant="contained"
              color="primary"
              disableElevation
              onClick={saveQR.bind(this, qrRef)}
              style={{ width: "auto", marginRight: "12px" }}
            >
               {t("common.save_qr")}
            </NCButton>
            <NCButton
              variant="contained"
              color="primary"
              disableElevation
              onClick={handlePrint}
              style={{ width: "auto", marginRight: "12px" }}
            >
              {t("common.print_qr")}
            </NCButton>
          </Box>
        }
      />
    </Grid>
  )
}

export default AddDriver
