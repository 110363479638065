import { Box, Grid } from "@material-ui/core"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import NCText from "../../shared/NCText"
import { Form, FormikProvider, useFormik } from "formik"
import FormField from "../../components/FormField"
import * as Yup from "yup"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { useGetDriverList } from "../../services/queries"
import { formatAMPM, formatDate, getAddressValue } from "../../libs/utils"
import FormSection from "../../components/FormSection"
import { useAssignDriver, useReadNotification } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useState } from "react"

const CancelledPopup = ({ modal, showModal, detail }) => {
  const [search, setSearch] = useState(null)
  const { data, isLoading } = useGetDriverList(search)
  const queryClient = useQueryClient()
  const assignDriver = useAssignDriver()
  const readNotificationApi = useReadNotification()

  const results = data?.data?.data?.results || []

  const formik = useFormik({
    initialValues: {
      driver: null
    },
    validationSchema: Yup.object().shape({
      driver: Yup.string().required("Driver is required")
    }),
    onSubmit: values => {
      const data = {
        driver: values.driver,
        id: detail?.data?.trip_id
      }
      assignDriver.mutate(data, {
        onSuccess: () => {
          readNotificationApi.mutate(
            {
              notification: detail.id
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(["getNotificationData"])
                queryClient.refetchQueries(["getNotificationSummary"])
                toast.success("Driver Assigned successfully!")
                showModal(false)
              }
            }
          )
        }
      })
    }
  })
  const item = (key, value, size = 6) => {
    return (
      <Grid item md={size}>
        <Box mb={"10px"}>
          <NCText>
            <Box fontSize={"13px"} fontWeight={"bold"}>
              {key}
            </Box>
          </NCText>
          <NCText>
            <Box fontSize={"12px"} color={"#9CA3AF"}>
              {value}
            </Box>
          </NCText>
        </Box>
      </Grid>
    )
  }

  const renderContent = (
    <Box>
      <FormSection title={"Ride Detail"}>
        <Grid container>
          {item(
            "Time and Date",
            `${formatDate(detail?.data?.date)} / ${formatAMPM(
              detail?.data?.date
            )}`
          )}
        </Grid>
        <Grid container>
          {item(
            "Pickup Address",
            getAddressValue(detail?.data?.pickup_address)
          )}
          {item(
            "Drop off Address",
            getAddressValue(detail?.data?.drop_off_address)
          )}
        </Grid>
        <Grid container>{item("Designated Driver", detail?.data?.driver)}</Grid>
        <Grid container>
          {item(
            "Canceled By - Driver",
            <span>
              <b>Note From Driver-</b> {detail?.data?.note}
            </span>,
            12
          )}
        </Grid>
      </FormSection>
      <Box my={1}>
        <NCText>
          <Box fontSize={"13px"} fontWeight={"bold"}>
            Assign New Driver
          </Box>
        </NCText>
        <NCText variant={"body2"}>
          Best suitable Drivers list in recommended order for this request
        </NCText>
      </Box>
      <Box>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormField
              as={NCAutocomplete}
              options={results
                .filter(
                  driver => driver?.driver?.id !== detail?.data?.driver_id
                )
                .map(item => {
                  return { id: item?.driver?.id, name: item?.driver?.name }
                })}
              label={"Select Driver"}
              name={"driver"}
              onChange={v => {
                formik.setFieldValue("driver", v)
              }}
              loading={isLoading}
              onSearch={v => setSearch(v)}
              required={true}
              error={formik?.errors?.driver}
            />
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  )
  return (
    <div>
      <NCModal
        open={modal}
        title={
          <Box fontWeight={"bold"} fontSize={"16px"}>
            Ride Cancelled
          </Box>
        }
        text={renderContent}
        onClose={() => showModal(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => formik.handleSubmit()}
            style={{ width: "auto" }}
          >
            Continue
          </NCButton>
        }
      />
    </div>
  )
}

export default CancelledPopup
