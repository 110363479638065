import {
  Box,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCTable from "../../shared/table/NCTable"
import { useState } from "react"
import { TABLE_CONFIG } from "../../libs/config"
import { DeleteIcon } from "../../libs/icons"
import NCLoader from "../../shared/NCLoader"
import NCButton from "../../shared/NCButton"
import NCModal from "../../shared/NCModal"
import { useDeleteBankAccount } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useGetBankDetails } from "../../services/queries"
import LinkBankAccount from "./link-bank-account"
import { useTranslation } from "react-i18next"

const BankAccountList = () => {
  const deleteBankAccountMutation = useDeleteBankAccount()

  const { t } = useTranslation();

  const queryClient = useQueryClient()

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)

  const [showDeleteModel, setShowDeleteModel] = useState(false)

  const { isLoading, data, refetch } = useGetBankDetails()

  const total_pages = 1;

  const next = null,
    previous = null

  const bankDetails = data?.data?.data || {}

  const bankData = bankDetails?.bank_name ?  [bankDetails] : [];

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const removeBankAccount = () => {
    deleteBankAccountMutation.mutate(
      {},
      {
        onSuccess: () => {
          toast.success(t("bank_account_removed"))
          queryClient.refetchQueries(["getBankDetails"])
          refetch()
        }
      }
    )
  }

  const getActionColumn = () => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton
            onClick={() => {
              setShowDeleteModel(true)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </div>
    )
  }

  const columns = [
    {
      Header: t("list.bank_name"),
      accessor: cell => cell?.bank_name || "-",
      disableSortBy: true
    },
    {
      Header: t("list.account_number"),
      accessor: cell =>
        cell?.bank_number ? "****-****-" + cell?.bank_number : "-",
      disableSortBy: true
    },
    {
      Header: t("list.date_added"),
      accessor: cell => cell?.created_at || "-",
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{t("navigation.bank_details")}</NCText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item style={{ paddingRight: "0" }}>
                  {!isLoading && bankData?.length === 0 && (
                    <LinkBankAccount refetchBankDetails={refetch} />
                  )}
                  {/* <LinkBankAccount refetchBankDetails={refetch} /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={bankData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
      <NCModal
        open={showDeleteModel}
        title={t("remove_ba")}
        text={t("remove_ba_confirm")}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              removeBankAccount()
              setShowDeleteModel(false)
            }}
            style={{ width: "auto" }}
          >
            {t("common.remove")}
          </NCButton>
        }
      />
    </Grid>
  )
}

export default BankAccountList
