import { Route, Routes as DomRoutes } from "react-router-dom"
import LoginPage from "./containers/LoginPage"
import AuthenticationPage from "./containers/AuthenticationPage"
import ResetPassword from "./containers/ResetPassword"
import NewPassword from "./containers/NewPassword"
import AuthenticatedRoute from "./AuthenticatedRoute"
import ClientList from "./containers/client/List"
import SubAdminList from "./containers/subadmin/List"
import React from "react"
import AuthenticationSetup from "./containers/AuthenticationSetup"
import AddAdmin from "./containers/admin/AddAdmin"
import AddSubAdmin from "./containers/subadmin/add"
import OverviewList from "./containers/request/List"
import AdminList from "./containers/admin/List"
import AddClient from "./containers/client/add"
import ClientDetail from "./containers/client/Detail"
import SelectedAdmin from "./containers/admin/SelectedAdmin"
import Setting from "./containers/setting"
import Logout from "./containers/Logout"
import AddRequest from "./containers/request/AddRequest"
import FeedbackList from "./containers/feedback/List"
import RequestDetails from "./containers/request/RequestDetails"
import PrivacyPolicy from "./containers/meta/PrivacyPolicy"
import TermsandConditions from "./containers/meta/TermsandConditions"
import DispatcherList from "./containers/dispatcher/list"
import AddDispatcher from "./containers/dispatcher/add"
import AddAidMonitor from "./containers/aidMonitor/add"
import DriverList from "./containers/driver/list"
import AddDriver from "./containers/driver/add"
import SubAdminDetail from "./containers/subadmin/Detail"
import VehicleList from "./containers/vehicle/List"
import VehicleDetail from "./containers/vehicle/details"
import AidMonitorList from "./containers/aidMonitor/list"
import DriverDetail from "./containers/driver/details"
import Appointments from "./components/appointments/Appointments"
import DispatcherNotificationList from "./containers/dispatcherNotification/List"
import LandingPage from "./containers/landing/LandingPage"
import DirectMessage from "./containers/direct-message/user-channel-list"
import Chat from "./containers/direct-message/user-messaging"
import UserAccessManagementList from "./components/users/UserAccessManagementList"
import UserAccessManagementUpdateRole from "./components/users/UserAccessManagementUpdateRole"
import UserAccessManagementAddRole from "./components/users/UserAccessManagementAddRole"
import Incidents from "./containers/incidents/incidents"
import IncidentsDetail from "./containers/incidents/details"
import BasicDetails from "./containers/driver/basicDetails"
import MapViewListing from "./containers/map-view-listing/map-view-listing"
import MapViewListingDetails from "./containers/map-view-listing/map-view-listing-details"
import TrainingList from "./containers/training/list"
import CreateTraining from "./containers/training/add"
import TrainingDetails from "./containers/training/details"
import ManageReports from "./containers/reports/ManageReports"
import ReportDetails from "./containers/reports/ReportDetails"
import MyReports from "./containers/reports/MyReports"
import InspectionList from "./containers/inspection/list"
import Introduction from "./containers/introduction"
import TripLog from "./containers/trip-log/trip-log"
import LiveLocation from "./containers/live-location/live-location"
import BankAccountList from "./containers/bank-account/list"
import CallUser from "./containers/direct-message/call"
import PhoneCalls from "./containers/phone-calls/phone-calls"
import TransactionList from "./containers/transactions/list"
import TransactionDetails from "./containers/transactions/transactionDetails"
import DriverInspectionDetails from "./components/driver/DriverInspectionDetails"

const Routes = () => {
  return (
    <DomRoutes>
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path="/overview" element={<OverviewList />} />
        <Route path="/admins" element={<AdminList />} />
        <Route exact path="/admins/add-admin" element={<AddAdmin />} />
        <Route exact path="/admins/:adminId" element={<SelectedAdmin />} />
        <Route path="/clients" element={<ClientList />} />
        <Route path="/direct-message" element={<DirectMessage />}>
          <Route path="/direct-message/chat" element={<Chat />} />
          <Route path="/direct-message/call/:pubnubId" element={<CallUser />} />
        </Route>
        <Route path="/incidents" element={<Incidents />}>
          <Route path="/incidents/:id" element={<IncidentsDetail />} />
        </Route>
        <Route path="/clients/add-client" element={<AddClient />} />
        <Route exact path="/clients/:clientId" element={<ClientDetail />} />
        <Route exact path="/vehicles" element={<VehicleList />} />
        <Route exact path="/vehicles/:vehicleId" element={<VehicleDetail />} />
        {/* <Route path="/map" element={<TestMap />} /> */}
        <Route path="/feedback" element={<FeedbackList />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/logout" element={<Logout />} />
        <Route exact path="/subadmins/add" element={<AddSubAdmin />} />
        <Route exact path="/appointments" element={<Appointments />} />
        {/* <Route exact path="/ridedetails" element={<RideDetails />} /> */}
        <Route
          exact
          path="/subadmins/:subAdminId"
          element={<SubAdminDetail />}
        />
        <Route exact path="/subadmins" element={<SubAdminList />} />
        <Route exact path="/overview/add-request" element={<AddRequest />} />
        <Route exact path="/overview/:id" element={<RequestDetails />} />
        <Route path="/" element={<OverviewList />} />
        <Route path="/dispatchers" element={<DispatcherList />} />
        <Route path="/dispatchers/add-dispatcher" element={<AddDispatcher />} />
        <Route path="/dispatchers/:dispatcherId" element={<AddDispatcher />} />
        <Route path="/aidmonitor" element={<AidMonitorList />} />
        <Route path="/aidmonitor/add-aidmonitor" element={<AddAidMonitor />} />
        <Route path="/aidmonitor/:id" element={<AddAidMonitor />} />
        <Route path="/drivers" element={<DriverList />} />
        <Route
          path="/map-view-listing/:id"
          element={<MapViewListingDetails />}
        />
        <Route path="/map-view-listing" element={<MapViewListing />} />
        <Route path="/inspection" element={<InspectionList />} />
        <Route path="/live-location" element={<LiveLocation />} />
        <Route path="/bank-account" element={<BankAccountList />} />
        <Route path="/training" element={<TrainingList />} />
        <Route path="/phonecalls" element={<PhoneCalls />} />
        <Route path="/training/:id" element={<TrainingDetails />} />
        <Route path="/training/:id/edit" element={<CreateTraining />} />
        <Route path="/training/add-training" element={<CreateTraining />} />
        <Route path="/drivers/add-driver" element={<AddDriver />} />
        <Route path="/drivers/:driverId" element={<DriverDetail />} />
        <Route path="/drivers/:id/edit" element={<AddDriver />} />
        <Route
          path="/drivers/:driverId/:inspectionId"
          element={<DriverInspectionDetails />}
        />
        <Route path="/trip-log" element={<TripLog />} />
        <Route
          path="/user-access-management"
          element={<UserAccessManagementList />}
        />
        <Route
          path="/user-access-management/add-user-role"
          element={<UserAccessManagementAddRole />}
        />
        <Route
          path="/user-access-management/:roleId"
          element={<UserAccessManagementUpdateRole />}
        />
        <Route path="/notification" element={<DispatcherNotificationList />} />

        <Route path="*" element={<div>Development is under progress</div>} />
        <Route path="/manage-reports" element={<ManageReports />} />
        <Route path="/manage-reports/:id" element={<ReportDetails />} />
        <Route path="/my-reports" element={<MyReports />} />
        <Route path="/my-reports/:id" element={<ReportDetails />} />
        <Route path="/intro/" element={<Introduction />} />
        <Route path="/transactions/" element={<TransactionList />} />
        <Route path="/transactions/:id" element={<TransactionDetails />} />
        <Route path="/transactions/:id/:id" element={<ReportDetails />} />
      </Route>
      <Route exact path="/authsetup" element={<AuthenticationSetup />} />
      <Route exact path="/authenticate" element={<AuthenticationPage />} />
      <Route exact path="/resetpassword" element={<ResetPassword />} />
      <Route exact path="/reset-password-confirm" element={<NewPassword />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="/verify-driver" element={<BasicDetails />} />
      <Route exact path="/landing-page" element={<LandingPage />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-conditions" element={<TermsandConditions />} />
      <Route path="/reports" element={<AdminList />} />
    </DomRoutes>
  )
}

export default Routes
