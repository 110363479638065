import React, { useState } from "react"
import NCText from "../../shared/NCText"
import NCInput from "../../shared/NCInput"
import { DetailIcon, SearchIcon } from "../../libs/icons"
import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import ExportMenu from "../../components/ExportMenu"
import NCTable from "../../shared/table/NCTable"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import { useGetVehiclesFile, useGetVehiclesList } from "../../services/queries"
import NCLoader from "../../shared/NCLoader"
import useDebounce from "../../libs/hooks/Debounce"
import { downloadFileFromResponse } from "../../libs/utils"
import { useNavigate } from "react-router-dom"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"
const VehicleList = () => {
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [ordering, setOrdering] = useState("id")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [statusFilter, setStatusFilter] = useState([])
  const [downloadType, setDownloadType] = useState()
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const nav = useNavigate()
  const {t} = useTranslation();

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const { isLoading, data } = useGetVehiclesList(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null
    }
  )
  const { refetch } = useGetVehiclesFile(
    downloadType,
    query,
    { ordering },
    { enabled: false }
  )

  const {
    next,
    previous,
    total_pages,
    results: vehiclesData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton onClick={() => nav(`/vehicles/${cell.driver.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  const getDetailsByClientName = cell => {
    return (
      <div onClick={() => nav(`/vehicles/${cell.driver.id}`)}>
        <NCText className={"clickable-text"}>{cell?.driver?.name}</NCText>
      </div>
    )
  }

  const columns = [
    {
      Header: t("list.driver_id"),
      id: "driver.id",
      accessor: cell => cell.driver.id,
      Cell: ({ value }) => (
        <NCText
          className={"clickable-text"}
          onClick={() => nav(`/vehicles/${value}`)}
        >
          {value}
        </NCText>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.driver_name"),
      //id: "driver.name",
      accessor: cell => getDetailsByClientName(cell)
    },
    {
      Header: t("list.mark"),
      accessor: "mark"
    },
    {
      Header: t("list.model"),
      accessor: "model"
    },
    {
      Header: t("list.vin"),
      accessor: "vin_no"
    },
    {
      Header: t("list.license_plate"),
      accessor: "licence_plate"
    },

    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  const downloadFile = async type => {
    await setDownloadType(type)
    const data = await refetch()
    const contentDispositionHeaders =
      data?.data?.headers["content-disposition"].split("=")
    downloadFileFromResponse(
      data?.data?.data,
      contentDispositionHeaders[contentDispositionHeaders.length - 1]
        .replaceAll(/[/"]/g, "")
        .trim()
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={4}>
              {!isSmallScreen ? (
                <NCText variant={"h4"}>{t("vehicles.heading")}</NCText>
              ) : (
                <Grid container justifyContent="space-between">
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NCText variant={"h4"}>{t("vehicles.heading")}</NCText>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} justifyContent={isSmallScreen ? "flex-start" : "flex-end"}>
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                <Grid item>
                  <ExportMenu itemAction={item => downloadFile(item.key)} />
                </Grid>
                {!isSmallScreen && <Grid item style={{ paddingLeft: "0" }}>
                  <NotificationAlert />
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={vehiclesData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
    </Grid>
  )
}

export default VehicleList
