import { Grid } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useUpdateRepresentative } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import FormField from "../FormField"
import PhoneNumber from "../PhoneNumber"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import { useTranslation } from "react-i18next"

const EditCompanyRepresentativeModal = ({
  modalState = false,
  handleClose,
  refetchRepresentative,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const editRepresentativeRequest = useUpdateRepresentative()
  const id = data?.results?.[0]?.id || ""
  const formik = useFormik({
    initialValues: {
      first_name: data?.results?.[0]?.first_name || "",
      last_name: data?.results?.[0]?.last_name || "",
      email: data?.results?.[0]?.email || "",
      phone: data?.results?.[0]?.phone || ""
    },
    validationSchema: Yup.object().shape({}),
    enableReinitialize: true,
    onSubmit(values) {
      editRepresentativeRequest.mutate(
        { ...values, id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getRepresentativeDetail"])
            queryClient.invalidateQueries(["getAdminAuditLog"])
            toast.success(t("admin.representative_edited"))
            refetchRepresentative();
            handleClose()
          }
        }
      )
    }
  })
  const companyRepresentativeModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.first_name")}
              name={"first_name"}
              required={true}
              error={formik?.errors?.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.last_name")}
              name={"last_name"}
              required={true}
              error={formik?.errors?.last_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.email_address")}
              name={"email"}
              required={true}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              as={PhoneNumber}
              label={t("common.phone_number")}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={t("admin.edit_company_representative")}
        text={companyRepresentativeModal}
        onClose={() => handleClose()}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            {t("common.update")}
          </NCButton>
        }
      />
      {editRepresentativeRequest.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default EditCompanyRepresentativeModal
