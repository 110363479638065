import { useState } from "react"
import { Grid, makeStyles, ButtonGroup, Button, Box, useTheme, useMediaQuery } from "@material-ui/core"
import NCText from "../../shared/NCText"
import EmployeeList from "../EmployeeList"
import FormSection from "../../components/FormSection"
import {
  useGetRepresentativeDetail,
  useGetAdminDetail,
  useGetAdminAuditLog
} from "../../services/queries"
import TopNavBar from "../../components/TopNavBar"
import { useParams } from "react-router-dom"
import ClientRequestList from "../admin/ClientRequestList"
import NCLoader from "../../shared/NCLoader"
import AddEmployeeModal from "../../components/admin/AddEmployeeModal"
import EditCompanyRepresentativeModal from "../../components/admin/EditCompanyRepresentativeModal"
import EditCompanyModal from "../../components/admin/EditCompanyModal"
import { getAddressValue, loggedInUser } from "../../libs/utils"
import NCAuditButton from "../../shared/NCAuditButton"
import NotificationAlert from "../../components/notification/NotificationAlert"
import NCTooltip from "../../shared/NCTooltip"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "20px",
    fontSize: "12px",
    width: "140px"
  },
  toggleButton: {
    borderRadius: "20px",
    fontSize: "12px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between",
    fintWeight: "600"
  },
  subSectionTitle: {
    color: "#9CA3AF",
    fontSize: "12px"
  },
  "& .MuiBox-root": {
    display: "flex"
  }
}))
const SelectedAdmin = () => {
  const { t } = useTranslation()
  const cls = useStyles()
  let { adminId } = useParams()
  const user = loggedInUser()
  const [employeeModal, setEmployeeModal] = useState(false)
  const [companyModal, setCompanyModal] = useState(false)
  const [companyRepresentativeModal, setCompanyRepresentativeModal] =
    useState(false)
  const [adminDetailsToggle, setAdminDetailsToggle] = useState(true)

  const handleAdminDetails = () => {
    setAdminDetailsToggle(true)
  }
  const handleAdminRequest = () => {
    setAdminDetailsToggle(false)
  }

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const { data: representativeData, refetch: refetchRepresentative } = useGetRepresentativeDetail(adminId)
  const { isLoading, data: adminData } = useGetAdminDetail(adminId)
  const { first_name, last_name, email, phone } =
    representativeData?.data?.data?.results?.[0] || {}
  const { company_name, company_address, company_email_address } =
    adminData?.data?.data || {}

  const { data } = useGetAdminAuditLog(
    {
      admin_id: adminId
    },
    { enabled: adminId && user?.user_type === 1 ? true : false }
  )
  const adminAuditData = data?.data?.data?.results || []
  const companyTitle = (
    <Grid item className={cls.titleSection}>
      {t("container.admin.dist_details")}
      <Button
        onClick={() => setCompanyModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        {t("admin.edit_districts")}
      </Button>
    </Grid>
  )
  const empoyeesTitle = (
    <Grid item className={cls.titleSection}>
      {t("admin.employees")}
      <Button
        onClick={() => setEmployeeModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
       {t("admin.add_employee")} +
      </Button>
    </Grid>
  )
  const representativeTitle = (
    <Grid item className={cls.titleSection}>
      {t("container.admin.dist_reprentative")}
      <Button
        onClick={() => setCompanyRepresentativeModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        {t("container.admin.edit_reprentative")}
      </Button>
    </Grid>
  )

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          <NCText variant={"h4"} style={{ marginRight: "10px" }}>
          {t("container.admin.admin_details")}
          </NCText>
        </Box>

        <Box display={"flex"}>
          {!isSmallScreen && user.user_type === 1 && adminId ? (
            <Box mb={2} mr={1}>
              <NCAuditButton data={adminAuditData} isLoading={isLoading} />
            </Box>
          ) : null}
          <NotificationAlert />
        </Box>
      </Grid>
      {isSmallScreen && (
        <Grid item xs={6} sm={4}>
          {user.user_type === 1 && adminId ? (
            <Box mb={2} mr={1}>
              <NCAuditButton data={adminAuditData} isLoading={isLoading} />
            </Box>
          ) : null}
        </Grid>
      )}

      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid mb={2}>
          <Box mb={2}>
            <ButtonGroup disableElevation>
              <Button
                className={cls.toggleButton}
                variant={adminDetailsToggle ? "contained" : "outlined"}
                onClick={handleAdminDetails}
                color={adminDetailsToggle ? "primary" : ""}
              >
                {t("container.admin.admin_details")}
              </Button>
              <Button
                className={cls.toggleButton}
                variant={adminDetailsToggle ? "outlined" : "contained"}
                onClick={handleAdminRequest}
                color={adminDetailsToggle ? "" : "primary"}
              >
                {t("container.admin.admin_request")}
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
        {adminDetailsToggle ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title={companyTitle}>
                <Grid container>
                  <Grid item md={4}>
                    <NCText className={cls.subSectionTitle}>
                    {t("container.admin.dist_name")}
                    </NCText>
                    <NCText variant="body2">{company_name || ""}</NCText>
                  </Grid>
                  <Grid item md={4}>
                    <NCText className={cls.subSectionTitle}>
                    {t("container.admin.dist_address")}
                    </NCText>
                    <NCText variant="body2">
                      {getAddressValue(company_address)}
                    </NCText>
                  </Grid>
                  <Grid item md={4}>
                    <NCText className={cls.subSectionTitle}>
                    {t("container.admin.dist_email_address")}
                    </NCText>
                    <NCTooltip title={company_email_address || ""}>
                      <div>
                        <NCText className={"overflow-ellipsis"} variant="body2">
                          {company_email_address || ""}
                        </NCText>
                      </div>
                    </NCTooltip>
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title={representativeTitle}>
                <Grid container>
                  <Grid item md={3} sm={6}>
                    <NCText className={cls.subSectionTitle}>{t("form_fields.first_name")}</NCText>
                    <NCText variant="body2">{first_name || ""}</NCText>
                  </Grid>
                  <Grid item md={3} sm={6}>
                    <NCText className={cls.subSectionTitle}>{t("form_fields.last_name")}</NCText>
                    <NCText variant="body2">{last_name || ""}</NCText>
                  </Grid>
                  <Grid item md={4} sm={6}>
                    <NCText className={cls.subSectionTitle}>
                    {t("form_fields.email_address")}
                    </NCText>
                    <NCTooltip title={email}>
                      <div>
                        <NCText className={"overflow-ellipsis"} variant="body2">
                          {email || ""}
                        </NCText>
                      </div>
                    </NCTooltip>
                  </Grid>
                  <Grid item md={2} sm={6}>
                    <NCText className={cls.subSectionTitle}>
                    {t("form_fields.phone_num")}
                    </NCText>
                    <NCText variant="body2">{phone || ""}</NCText>
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title={empoyeesTitle}>
                <EmployeeList adminId={adminId} />
              </FormSection>
            </Grid>
            <AddEmployeeModal
              adminId={adminId}
              modalState={employeeModal}
              handleClose={() => setEmployeeModal(false)}
            />
            <EditCompanyRepresentativeModal
              adminId={adminId}
              refetchRepresentative={refetchRepresentative}
              modalState={companyRepresentativeModal}
              handleClose={() => setCompanyRepresentativeModal(false)}
              data={representativeData?.data?.data}
            />
            <EditCompanyModal
              adminId={adminId}
              modalState={companyModal}
              handleClose={() => setCompanyModal(false)}
              data={adminData?.data?.data}
            />
            {isLoading && <NCLoader open={true} />}
          </Grid>
        ) : (
          <Grid item xs={12} sm={8} md={12}>
            <ClientRequestList adminId={adminId} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default SelectedAdmin
