import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Box, Grid } from "@material-ui/core"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import FormField from "../FormField"
import { makeStyles } from "@material-ui/core"
import { useQueryClient } from "react-query"
import NCDatePicker from "../../shared/NCDatePicker"
import NCTimePicker from "../../shared/NCTimePicker"
import NCText from "../../shared/NCText"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { WORK_WEEK } from "../../libs/config"
import { useDriverAvailability } from "../../services/mutations"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { ArrowRight } from "../../libs/icons"
import { useEffect, useState } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles({
  arrow: {
    width: "40px",
    height: "40px",
    alignItems: "center"
  }
})

const AppointmentModal = ({
  modalState = false,
  handleClose,
  allData,
  refetchDriverAppointments,
  setSearch,
  isLoading,
  data = {}
}) => {
  const { t } = useTranslation();
  const [hr_time_diff, setHourTimeDiff] = useState()
  const [min_time_diff, setMinTimeDiff] = useState()
  const [startMin, setStartMin] = useState()
  const cls = useStyles()
  const queryClient = useQueryClient()
  const driverAvailability = useDriverAvailability()
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      driver: "",
      work_week: "",
      until: ""
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.date().typeError("Use format(MM/dd/YYYY)").required(t("appointments.start_date_required")),
      end_date: Yup.date().typeError("Use format(MM/dd/YYYY)").required(t("appointments.end_date_required")),
      start_time: Yup.date()
        .when("end_time", (endDate, schema) => {
          if (endDate && endDate.length && endDate[0]) {
            const timeBefore = new Date(new Date(endDate).getTime() - 60000);
            return schema.max(new Date(timeBefore),t("appointments.less_than_end_time"));
          }
          return schema.required(t("appointments.start_time_required"));
        }),
      end_time: Yup.date()
        .when("start_time", (startDate, schema) => {
          if (startDate && startDate.length && startDate[0]) {
            const timeAfter = new Date(new Date(startDate).getTime() + 60000);
            return schema.min(new Date(timeAfter),t("appointments.greater_than_end_time"));
          }
          return schema.required(t("appointments.end_time_required"));
        }),
      driver: Yup.string().required(t("appointments.driver_name_required")),
      work_week: Yup.string().required(t("appointments.work_week_required")),
      until: Yup.date().typeError("Use format(MM/dd/YYYY)").required(t("appointments.until_date_required"))
    }, ["end_time", "start_time"]),
    enableReinitialize: true,
    onSubmit(values) {
      driverAvailability.mutate(
        {
          ...values,
          start_time: new Date(
            `${values.start_date} ${values.start_time.split(" ")[1]} ${values.start_time.split(" ")[2]}`
          ).toISOString(),
          end_time: new Date(
            `${values.end_date} ${values.end_time.split(" ")[1]}  ${values.end_time.split(" ")[2]}`
          ).toISOString(),
          until: new Date(
            `${values.until} ${values.end_time.split(" ")[1]} ${values.end_time.split(" ")[2]}`
          ).toISOString()
        },
        {
          onSuccess: () => {
            toast.success(t("appointments.added_success"))
            queryClient.invalidateQueries(["getDriverAvailability"])
            refetchDriverAppointments();
            handleClose()
          }
        }
      )
    }
  })

  useEffect(() => {
    if (formik.values.start_time && formik.values.end_time) {
      const start = moment(formik.values.start_time)
      const end = moment(formik.values.end_time)
      const hours = end.diff(start, "hours")
      setHourTimeDiff(hours)
      const minutes = end.diff(start, "minutes") % 60
      setMinTimeDiff(minutes)
    }
  }, [formik.values.start_time, formik.values.end_time])

  useEffect(() => {
    formik.setFieldValue("end_date", formik.values.start_date)
  }, [formik.values.start_date])

  useEffect(() => {
    formik.setFieldValue("until", formik.values.end_date)
  }, [formik.values.end_date])

  useEffect(() => {
    setStartMin(formik.values.end_date)
  }, [formik.values.until])

  const requestModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box>
              <Grid item xs={12}>
                <NCText style={{ color: "#9CA3AF" }}>
                  {t("appointments.select_driver_for_avail")}
                </NCText>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "-16px" }}>
                <FormField
                  placeholder={t("appointments.driver_name")}
                  as={NCAutocomplete}
                  options={
                    allData.map(item => {
                      return {
                        id: item?.driver?.id,
                        name: item?.driver?.name
                      }
                    }) || []
                  }
                  name={"driver"}
                  onChange={v => {
                    formik.setFieldValue("driver", v)
                  }}
                  loading={isLoading}
                  onSearch={v => setSearch(v)}
                  required={true}
                  error={formik?.errors?.driver}
                />
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: "-16px" }}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Grid xs={11}>
                <NCText style={{ color: "#9CA3AF" }}>{t("appointments.select_slot_time")}</NCText>
              </Grid>
              <Grid item>
                <NCText
                  style={{
                    color: "#9CA3AF",
                    fontSize: "14px",
                    padding: "px"
                  }}
                >
                  {hr_time_diff || min_time_diff
                    ? hr_time_diff === 0
                      ? min_time_diff + "M"
                      : min_time_diff === 0
                        ? hr_time_diff + "H"
                        : hr_time_diff + "H " + min_time_diff + "M"
                    : null}
                </NCText>
              </Grid>
            </Grid>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"}>
                <Grid item style={{ marginRight: "5px" }}>
                  <FormField
                    as={NCDatePicker}
                    style={{ width: "165px" }}
                    name={"start_date"}
                    onChange={v => formik.setFieldValue("start_date", v)}
                    min={
                      data?.start_date || new Date().toISOString().split("T")[0]
                    }
                    required={true}
                    error={formik?.errors?.start_date}
                  />
                </Grid>

                <Grid item>
                  <FormField
                    as={NCTimePicker}
                    style={{ width: "135px" }}
                    name={"start_time"}
                    onChange={v => formik.setFieldValue("start_time", v)}
                    required={true}
                    error={formik?.errors?.start_time}
                  />
                </Grid>
              </Box>
              <Grid item style={{ marginLeft: "8px", marginRight: "8px" }}>
                <NCSvgIcon component={ArrowRight} className={cls.arrow} />
              </Grid>
              <Box display={"flex"}>
                <Grid item style={{ marginRight: "5px" }}>
                  <FormField
                    as={NCDatePicker}
                    style={{ width: "165px" }}
                    name={"end_date"}
                    onChange={v => formik.setFieldValue("end_date", v)}
                    min={
                      data?.end_date || new Date().toISOString().split("T")[0]
                    }
                    required={true}
                    error={formik?.errors?.end_date}
                  />
                </Grid>
                <Grid item>
                  <FormField
                    as={NCTimePicker}
                    style={{ width: "135px" }}
                    name={"end_time"}
                    onChange={v => formik.setFieldValue("end_time", v)}
                    required={true}
                    error={formik?.errors?.end_time}
                  />
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "-16px" }}>
            <Box>
              <Grid item xs={12}>
                <NCText style={{ color: "#9CA3AF" }}>{t("appointments.workweek")}</NCText>
              </Grid>
              <Grid item xs={12}>
                <FormField
                  placeholder={t("appointments.work_week")}
                  as={NCAutocomplete}
                  options={
                    Object.keys(WORK_WEEK).map(item => {
                      return { id: item, name: WORK_WEEK[item] }
                    }) || []
                  }
                  name={"work_week"}
                  onChange={v => {
                    formik.setFieldValue("work_week", v)
                  }}
                  required={true}
                  error={formik?.errors?.work_week}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "-16px" }}>
            <Grid item xs={12}>
              <NCText style={{ color: "#9CA3AF" }}>{t("appointments.until")}</NCText>
            </Grid>

            <Grid item xs={3} style={{ marginRight: "5px" }}>
              <FormField
                as={NCDatePicker}
                name={"until"}
                placeholder={t("appointments.until_date")}
                disablePast
                onChange={v => formik.setFieldValue("until", v)}
                min={startMin}
                error={formik?.errors?.until}
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )

  return (
    <NCModal
      open={modalState}
      title={t("appointments.add_availability")}
      text={requestModal}
      onClose={() => handleClose()}
      submitButton={
        <NCButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={formik.handleSubmit}
          style={{ width: "auto" }}
        >
          {t("common.save")}
        </NCButton>
      }
    />
  )
}
export default AppointmentModal
