import { Grid, makeStyles } from "@material-ui/core"
import Navigation from "./Navigation"
import { CONTACT_US } from "./const"
import FormField from "../../components/FormField"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import userImage from "../../assets/images/user.png"
import NCButton from "../../shared/NCButton"
import { useContactUs } from "../../services/mutations"
import { toast } from "react-toastify"

const useStyles = makeStyles(theme => ({
  container: {
    background: "#FAFAFA",
    display: "grid",
    gridTemplateColumns: "12fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "3fr 9fr"
    },
    paddingTop: "2rem",
    paddingBottom: "2rem"
  },
  header: {
    fontSize: "32px",
    color: "black",
    marginBottom: "2rem",
    marginTop: "2rem"
  },
  description: {
    fontSize: "16px",
    color: "black",
    borderLeft: "3px solid #1EAAC4",
    paddingLeft: "10px",
    marginBottom: "2rem"
  },
  content: {
    backgroundColor: "white",
    borderRadius: "10px",
    borderTopRightRadius: "250px",
    borderBottomRightRadius: "250px",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "12fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "5fr 7fr"
    },
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  imgContainer: {
    display: "none",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  userImage: {
    width: "96%",
    height: "92%"
  }
}))

const ContactUs = () => {
  const cls = useStyles()

  const contactUsRequest = useContactUs()

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Full name is required"),
      email: Yup.string()
        .required("Email address is required")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,"Invalid email address"),
      message: Yup.string().required("Message is required")
    }),
    onSubmit: values => {
      contactUsRequest.mutate(values, {
        onSuccess: () => {
          formik.resetForm()
          toast.success("Thank You for contacting us!")
        }
      })
    }
  })

  return (
    <div className={cls.container} id="contactUs">
      <Navigation currentPage={CONTACT_US} mode={"dark"} />
      <div className={cls.content}>
        <div style={{ padding: "20px" }}>
          <div className={cls.header}>Contact Us</div>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <FormField
                    label={"Full name"}
                    placeholder={"Enter Full Name"}
                    name={"name"}
                    required={true}
                    error={formik?.errors?.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={"Email address"}
                    name={"email"}
                    placeholder={"Enter Email Address"}
                    required={true}
                    error={formik?.errors?.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={"Message"}
                    multiline
                    rows={3}
                    placeholder={"Please type your message"}
                    name={"message"}
                    required={true}
                    error={formik?.errors?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NCButton variant="contained" color="primary" type="submit">
                    Send
                  </NCButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </div>
        <div className={cls.imgContainer}>
          <img src={userImage} className={cls.userImage} />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
