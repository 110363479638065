import {
  Box,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCTable from "../../shared/table/NCTable"
import { useState } from "react"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import { useGetAllInspectionQuestions } from "../../services/queries"
import { AddIcon, DeleteIcon, EditIcon, SearchIcon } from "../../libs/icons"
import NCLoader from "../../shared/NCLoader"
import NCSvgIcon from "../../shared/NCSvgIcon"
import NCButton from "../../shared/NCButton"
import NCModal from "../../shared/NCModal"
import NCInput from "../../shared/NCInput"
import useDebounce from "../../libs/hooks/Debounce"
import { useDeleteInspectionQuestion } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import AddQuestionModal from "./addQuestionModal"
import { INSPECTION_CATEGORY } from "../../libs/const"
import { useTranslation } from "react-i18next"

const InspectionList = () => {

  const deleteInspectionMutation = useDeleteInspectionQuestion()

  const {t} = useTranslation();

  const queryClient = useQueryClient()

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [query, setQuery] = useState("")
  const [ordering, setOrdering] = useState("name")
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false)

  const [selectedRequest, setSelectedRequest] = useState()

  const { isLoading, data, refetch } = useGetAllInspectionQuestions(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: driverData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const deleteInspection = () => {
    deleteInspectionMutation.mutate(selectedRequest.id, {
      onSuccess: () => {
        toast.success(t("inspection.delete"))
        queryClient.refetchQueries(["getAllInspectionQuestions"])
        refetch()
      }
    })
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton
            onClick={() => {
              setSelectedRequest(cell)
              setShowAddQuestionModal(true)
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDeleteModel(true)
              setSelectedRequest(cell)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </div>
    )
  }

  const columns = [
    {
      Header: t("list.question"),
      id: "question",
      accessor: cell => cell.name,
      disableSortBy: true
    },
    {
      Header:  t("list.type"),
      id: "type",
      accessor: cell => cell.type === 1 ? "Pre" : "Post",
      disableSortBy: true
    },
    {
      Header:  t("list.category"),
      accessor: cell => INSPECTION_CATEGORY.find(cat => cat.id === cell.category).name,
      disableSortBy: true
    },
    {
      Header:  t("list.sequence"),
      accessor: cell => cell.sequence,
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{t("inspection.heading")}</NCText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                <Grid item style={{ paddingRight: "0" }}>
                  <NCButton
                    variant="contained"
                    color="primary"
                    endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
                    onClick={() => setShowAddQuestionModal(true)}
                  >
                    {t("inspection.add_question")}
                  </NCButton>
                </Grid>
                {/* <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={driverData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
      {showAddQuestionModal && <AddQuestionModal
        data={selectedRequest}
        modalState={showAddQuestionModal}
        handleClose={status => {
          setSelectedRequest(null);
          setShowAddQuestionModal(false)
          queryClient.invalidateQueries(["getInspectionList"])
          status && refetch()
        }}
      />}
      <NCModal
        open={showDeleteModel}
        title={t("inspection.delete_question")}
        text={t("inspection.confirm_delete")}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              deleteInspection()
              setShowDeleteModel(false)
            }}
            style={{ width: "auto" }}
          >
            {t("common.delete")}
          </NCButton>
        }
      />
    </Grid>
  )
}

export default InspectionList
