import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCTable from "../../shared/table/NCTable"
import { useEffect, useRef, useState } from "react"
import {
  LIVE_LOCATION_STATUS,
  SEARCH_CONFIG,
  TABLE_CONFIG
} from "../../libs/config"
import { useGetDriverList } from "../../services/queries"
import { SearchIcon } from "../../libs/icons"
import NCLoader from "../../shared/NCLoader"
import NCInput from "../../shared/NCInput"
import useDebounce from "../../libs/hooks/Debounce"
import NCTableStatus from "../../shared/table/NCTableStatus"
import Map from "../../components/map/Map"
import { useTranslation } from "react-i18next"
// import Filter from "../../shared/table/Filter"

const useStyles = makeStyles(() => ({
  headingContainer: {
    backgroundColor: "#F9FAFB",
    marginBottom: "10px"
  },
  name: {
    fontSize: "18px",
    fontWeight: 700,
    color: "rgba(16, 101, 142, 1)"
  },
  id: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#444444"
  }
}))

const LiveLocation = () => {
  const cls = useStyles()

  const {t} = useTranslation();

  const ZOOM = 15

  const intervalRef = useRef()

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [stops, setStops] = useState([])
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0
  })
  const [zoom, setZoom] = useState(ZOOM)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [query, setQuery] = useState("")
  const [ordering, setOrdering] = useState("driver_name")
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const [statusFilter, setStatusFilter] = useState([])

  const { isLoading, data, refetch } = useGetDriverList(
    debouncedSearchTerm,
    page,
    pageSize,
    ordering,
    statusFilter && statusFilter.length
      ? statusFilter[0].value.toString()
      : null
  )

  const {
    next,
    previous,
    count,
    total_pages,
    results: driverData
  } = data?.data?.data || {}

  // const {
  //   data: selectedDriverData
  //   // refetch
  // } = useGetLiveLocation(selectedDriver?.driver?.id, {
  //   enabled: !!selectedDriver?.driver?.id
  // })

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  // function SelectColumnFilter({ column: { filterValue, setFilter } }) {
  //   return (
  //     <Filter
  //       options={[
  //         { key: "1", name: "On a Trip/Ride" },
  //         { key: "2", name: "Idle" },
  //         { key: "3", name: "Offline" }
  //       ]}
  //       filters={filterValue}
  //       setFilters={setFilter}
  //     />
  //   )
  // }

  const columns = [
    {
      Header: t("list.driver_id"),
      id: "driver_id",
      accessor: cell => cell?.driver?.id,
      Cell: ({ value }) => (
        <Box pt={1} pb={1}>
          <NCText>{value}</NCText>
        </Box>
      )
    },
    {
      Header: t("list.driver_name"),
      id: "driver_name",
      accessor: cell => cell?.driver?.name
    },
    {
      Header: t("list.email"),
      accessor: cell => cell?.driver?.email,
      disableSortBy: true
    },
    {
      Header: t("list.phone_number"),
      accessor: cell => cell?.driver?.phone,
      disableSortBy: true
    },
    {
      Header: t("list.current_location"),
      accessor: cell => cell?.driver?.address,
      disableSortBy: true
    },
    {
      Header: t("list.status"),
      id: "status",
      accessor: cell => (
        <NCTableStatus
          status={t("live_location."+LIVE_LOCATION_STATUS[cell?.driver?.driver_status]) || "-"}
        />
      ),
      // defaultCanFilter: true,
      // Filter: SelectColumnFilter,
      disableSortBy: true
    }
  ]

  const onRowClicked = row => {
    const index = row.index
    const driver = driverData[index]
    setSelectedDriver(driver)
    const cordinates = {
      lat: driver?.driver?.latitude,
      lng: driver?.driver?.longitude
    }
    setCenter(cordinates)
    setZoom(ZOOM)
  }

  useEffect(() => {
    if (driverData) {
      const stops =
        driverData?.map((driver,index) => {
          const info = (
            <div>
              <div className={cls.name}>{driver?.driver?.name}</div>
              <div className={cls.id}>{driver?.driver?.id}</div>
            </div>
          )
          let latitude = driver?.driver?.latitude;
          let longitude = driver?.driver?.longitude;

          const list = JSON.parse(JSON.stringify(driverData)).splice(0,index)
          const sameFound = list.filter(item => item.driver.latitude == latitude && item.driver.longitude === longitude);

          latitude += (0.00001 * sameFound.length)
          return {
            latitude: latitude,
            longitude: longitude,
            blueMark: true,
            showPopup: true,
            info: info
          }
        }) || []
      if (stops.length > 0) {
        setCenter({
          lat: selectedDriver?.driver?.latitude || stops[0].latitude,
          lng: selectedDriver?.driver?.longitude || stops[0].longitude
        })
      }
      setStops(stops)
    }
  }, [driverData])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      refetch()
    }, [1000 * 30])

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(()=>{
    setSelectedDriver(null)
  },[page])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{t("live_location.heading")}</NCText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                {/* <Grid item style={{ paddingLeft: "0" }}>
                      <NotificationAlert />
                    </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} pl={1} pr={1} mt={2} className={cls.headingContainer}>
          <Box
            pt={1}
            pb={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <NCText>
              {!selectedDriver
                ? t("live_location.no_driver_selected")
                : selectedDriver?.driver?.name +
                  " - " +
                  selectedDriver?.driver?.id +
                  " "}
              {selectedDriver && (
                <NCTableStatus
                  status={
                   t("live_location."+LIVE_LOCATION_STATUS[
                      selectedDriver?.driver?.driver_status
                    ]) || "-"
                  }
                />
              )}
            </NCText>
          </Box>
        </Box>
        <Box width={"100%"} height={"400px"}>
          <Map
            center={center}
            stops={stops}
            showStartToEnd={false}
            showClientToDriver={false}
            zoomControl={true}
            zoom={zoom}
          />
        </Box>
        <Box mb={2} pl={1} pr={1} mt={2} className={cls.headingContainer}>
          <Box
            pt={1}
            pb={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <NCText>{t("live_location.drivers")}</NCText>
            <NCText style={{ fontWeight: "bold" }}>{count}</NCText>
          </Box>
        </Box>
        <NCTable
          columns={columns}
          data={driverData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          isLoading={isLoading}
          filter={statusFilter}
          setFilter={setStatusFilter}
          onRowClicked={onRowClicked}
          rowHightLight={true}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
    </Grid>
  )
}

export default LiveLocation
