import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Carousel from "react-material-ui-carousel"
import { Box, Button, Modal } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import NCText from "../../shared/NCText"

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "auto",
    backgroundColor: "white",
    boxShadow: 24,
    padding: "10px",
    borderRadius: "5px",
    outline: "none",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  carousel: {
    width: "100%",
    height: "100%"
  },
  paper: {
    width: "100%",
    height: "100%",
    margin: theme.spacing(0),
    backgroundColor: "rgba(255, 255, 255, 0.7)" // Translucent paper
  },
  headline: {
    marginTop: "10px",
    marginBottom: "30px"
  },
  img: {
    width: "470px",
    height: "288px",
    borderRadius: "16px"
  },
  title: {
    fontFamily: "Popins",
    fontWeight: 700,
    fontSize: "16px",
    color: "#2E3B36",
    marginTop: "20px",
    marginBottom: "20px"
  },
  description: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    color: "#444444"
  },
  close: {
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "16px"
  }
}))

const Introduction = props => {
  const cls = useStyles()
  const { t } = useTranslation()
  const items = props?.data?.data?.data?.results || []

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={`${cls.root} ${props.className}`}>
        <NCText variant="h5" className={cls.headline}>
          {t("intro_to_app")}
        </NCText>
        <Carousel className={cls.carousel} autoPlay={false}>
          {items.map((item, i) => (
            <Box
              key={i}
              className={cls.paper}
              display="flex"
              flexDirection={"column"}
              flexWrap={"wrap"}
              alignItems="center"
            >
              <img className={cls.img} src={item.image} alt={item.title} />
              <Box marginY={"15px"}>
                <NCText variant="h6">{item.title}</NCText>
              </Box>
              <NCText variant="subtitle1">{item.content}</NCText>
            </Box>
          ))}
        </Carousel>
        <Button onClick={props.onClose} className={cls.close}>
          {t("common.close")}
        </Button>
      </Box>
    </Modal>
  )
}

export default Introduction
