import { Box, Grid, makeStyles } from "@material-ui/core"
import Navigation from "./Navigation"
import { ABOUT_US } from "./const"
import missionCover from "../../assets/images/mission-cover.png"
import vissionCover from "../../assets/images/vision-cover.png"

const useStyles = makeStyles(theme => ({
  container: {
    background: "#FAFAFA",
    display: "grid",
    gridTemplateColumns: "12fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "3fr 9fr"
    }
  },
  header: {
    fontSize: "50px",
    color: "black",
    marginBottom: "2rem",
    marginTop: "2rem"
  },
  description: {
    fontSize: "16px",
    color: "black",
    borderLeft: "3px solid #1EAAC4",
    paddingLeft: "10px",
    marginBottom: "2rem"
  },
  content: {
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%"
  },
  card: {
    borderRadius: "15px",
    position: "relative",
    padding: "20px",
    color: "white"
  },
  values: {
    background: "#1EAAC4"
  },
  mission: {
    backgroundImage: `url(${missionCover})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "20px",
    overflow: "hidden",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      top: "0",
      left: "0",
      height: "100%",
      zIndex: 1,
      backgroundColor: "rgba(1, 101, 134, 0.64)"
    }
  },
  vission: {
    backgroundImage: `url(${vissionCover})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "20px",
    overflow: "hidden",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      top: "0",
      left: "0",
      height: "100%",
      zIndex: 1,
      backgroundColor: "rgba(1, 101, 134, 0.64)"
    }
  },
  cardHeading: {
    fontSize: "32px",
    fontWeight: "bold",
    marginBottom: "1rem",
    zIndex: 2,
    position: "relative"
  },
  cardDescription: {
    zIndex: 2,
    position: "relative"
  }
}))

const AboutUs = ({ description, mission, values, vision }) => {
  const cls = useStyles()

  const valuesList = values?.split("*") || []

  valuesList?.splice(0, 1)

  return (
    <div className={cls.container} id="aboutUs">
      <Navigation currentPage={ABOUT_US} mode={"dark"} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={cls.content}
      >
        <Grid item className={cls.header}>
          About Us
        </Grid>
        <Grid item className={cls.description}>
          {description}
        </Grid>
        <Grid item>
          <Box
            display={"grid"}
            gridTemplateColumns={"6fr 6fr"}
            style={{ gap: "10px" }}
          >
            <div className={[cls.card, cls.mission].join(" ")}>
              <div className={cls.cardHeading}>Mission</div>
              <div className={cls.cardDescription}>{mission}</div>
            </div>
            <div className={[cls.card, cls.vission].join(" ")}>
              <div className={cls.cardHeading}>Vision</div>
              <div className={cls.cardDescription}>{vision}</div>
            </div>
          </Box>
          <div
            className={[cls.card, cls.values].join(" ")}
            style={{ marginTop: "10px" }}
          >
            <div className={cls.cardHeading}>Our Values</div>
            <ul>
              {valuesList?.map(value => (
                <li>{value}</li>
              ))}
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default AboutUs
