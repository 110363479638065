import { useState } from "react"
import { useSearchParams, Link } from "react-router-dom"
import { Grid, makeStyles, Paper } from "@material-ui/core"
import NCContent from "../shared/NCContent"
import NCTitle from "../shared/NCTitle"
import NCText from "../shared/NCText"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import successLogo from "../assets/images/successLogo.svg"
import { ErrorMessage, Form, Field, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { useNewPassword } from "../services/mutations"
import NCLoader from "../shared/NCLoader"
import NCPasswordInput from "../shared/NCPasswordInput"
import { validationMessages } from "../constants/validation-messages";
import { validationRegex, MAX_LENGTH } from "../constants/validation";
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
  },
  title: {
    marginTop: "12px",
    marginBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  reset: {
    textDecoration: "none",
    display: "flex",
    cursor: "auto"
  }
}))

const NewPassword = () => {
  const cls = useStyles()
  const {t} = useTranslation();
  const changePasswordRequest = useNewPassword()
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false)
  const [queryParameters] = useSearchParams()
  const uid = queryParameters.get("uid")
  const token = queryParameters.get("token")
  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: ""
    },
    validationSchema: Yup.object().shape({
      new_password1: Yup.string()
        .required(t("new_password.error.password_required"))
        .min(8, validationMessages.minLength(8))
        .max(MAX_LENGTH.FIFTY, validationMessages.maxLength(50))
        .matches(/[A-Z]/, validationMessages.oneUpperChar)
        .matches(/[a-z]/, validationMessages.oneLowerChar)
        .matches(validationRegex.specialChar, validationMessages.oneSpecialChar),
      new_password2: Yup.string()
        .required(t("new_password.error.password_required"))
        .min(8, validationMessages.minLength(8))
        .max(MAX_LENGTH.FIFTY, validationMessages.maxLength(50))
        .matches(/[A-Z]/, validationMessages.oneUpperChar)
        .matches(/[a-z]/, validationMessages.oneLowerChar)
        .matches(validationRegex.specialChar, validationMessages.oneSpecialChar)
        .oneOf([Yup.ref("new_password1"), null], t("new_password.error.password_must_match"))
    }),
    onSubmit(values) {
      changePasswordRequest.mutate(
        { uid, token, ...values },
        {
          onSuccess: () => {
            setIsPasswordUpdated(true)
          }
        }
      )
    }
  })
  const passwordUpdateContent = (
    <Grid item xs={11} sm={8}>
      <Paper className={cls.paper}>
        <img src={successLogo} style={{ width: "25px" }} />
        <NCTitle className={cls.title}>{t("new_password.password_reset")}</NCTitle>
        <NCText className={cls.text}>{t("new_password.change_success_message")}.</NCText>
        <Link className={cls.reset} to="/login">
          <NCContent className="cursor-pointer" style={{ color: "#1EAAC4" }}>
            {t("new_password.login_now")} &nbsp;&gt;
          </NCContent>
        </Link>
      </Paper>
    </Grid>
  )

  const newPasswordContent = (
    <Grid item xs={11} sm={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>{t("new_password.heading")}</NCTitle>
        <NCText className={cls.text}>{t("new_password.sub_heading")}</NCText>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <NCContent error={!!formik?.errors?.new_password1}>
              {t("new_password.new_password")}
            </NCContent>
            <Field
              as={NCPasswordInput}
              className={cls.input}
              placeholder={t("new_password.enter_password")}
              name="new_password1"
              type="password"
              error={!!formik?.errors?.new_password1}
              helperText={
                <ErrorMessage
                  className={cls.errorMessage}
                  component={NCText}
                  name="new_password1"
                />
              }
            />
            <NCContent error={!!formik?.errors?.new_password2}>
              {t("new_password.confirm_password")}
            </NCContent>
            <Field
              as={NCPasswordInput}
              className={cls.input}
              placeholder="Enter Password"
              name="new_password2"
              type="password"
              error={!!formik?.errors?.new_password2}
              helperText={
                <ErrorMessage
                  className={cls.errorMessage}
                  component={NCText}
                  name="new_password2"
                />
              }
            />
            <NCButton type="submit" variant="contained" color="primary">
              {t("new_password.change_password")}
            </NCButton>
            {changePasswordRequest.isLoading && <NCLoader open={true} />}
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )
  return isPasswordUpdated ? (
    <AuthComponent>{passwordUpdateContent}</AuthComponent>
  ) : (
    <AuthComponent>{newPasswordContent}</AuthComponent>
  )
}

export default NewPassword
