import { Box, Grid, makeStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import NCText from "../../shared/NCText"
import NotificationAlert from "../notification/NotificationAlert"
import TopNavBar from "../TopNavBar"
import FormSection from "../FormSection"
import NCCheckbox from "../../shared/NCCheckbox"
import {
  useGetFeatureList,
  useGetUserRoleDetails,
} from "../../services/queries"
import NCLoader from "../../shared/NCLoader"
import { useNavigate, useParams } from "react-router-dom"
import NCButton from "../../shared/NCButton"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import FormField from "../FormField"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useUpdateRole } from "../../services/mutations"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  titleSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  checkedItems: {
    fontSize: "14px",
    fontWeight: "400"
  },
  checklistItems: {
    fontSize: "14px",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  rideContainer: {
    marginBottom: "10px"
  },
  container: {
    "& .MuiCheckbox-root": {
      paddingRight: "3px"
    }
  },
}))

const UserAccessManagementUpdateRole = () => {
  const { roleId } = useParams();

  const queryClient = useQueryClient()
  const updateUserRoleMutation = useUpdateRole(roleId);

  const {t} = useTranslation();

  const cls = useStyles()
  const nav = useNavigate()

  const { isLoading: userRoleDetailsLoading, data: userRoleDetails } =
    useGetUserRoleDetails(roleId);

  const userId = userRoleDetails?.data?.data?.user_details?.id;

  const { isLoading: allFeaturesLoading, data: allFeatures } =
    useGetFeatureList(userId, { enabled: !!userId })

  const formik = useFormik({
    initialValues: {
      role: "",
      selectedFeatures: []
    },
    validationSchema: Yup.object().shape({
      role: Yup.string().required(t("user_role.error.role_required"))
    }),
    onSubmit: values => {

      const featureIds = values.selectedFeatures
        ?.filter(data => data.checked)
        ?.map(data => data.id);

      const payload = {
        role: values.role,
        features: featureIds
      }

      updateUserRoleMutation.mutate(payload, {
        onSuccess: async () => {
          toast.success(t("user_role.update_success"))
          queryClient.invalidateQueries(["getNotificationData"])
          queryClient.refetchQueries(["getNotificationSummary"])
          nav("/user-access-management")
        }
      })
    }
  })

  useEffect(() => {
    const features = allFeatures?.data?.data?.results || [];
    const assignedRoles = userRoleDetails?.data?.data?.fetaures_data || [];
    const roleName = userRoleDetails?.data?.data?.role;
    const markFeature = features?.map(feat => {
      return {
        ...feat,
        checked: !!assignedRoles?.find(role => role.id === feat.id)
      }
    })
    formik.setFieldValue("selectedFeatures", markFeature)
    formik.setFieldValue("role", roleName)
  }, [allFeatures, userRoleDetails])

  const title = (
    <Grid item className={cls.titleSection}>
      {t("user_role.role_assignment")}
    </Grid>
  )

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          <NCText variant={"h5"} style={{ marginRight: "10px" }}>
          {t("user_role.user_access_management")}
          </NCText>
        </Box>

        <Box marginLeft={"5px"} style={{ display: "flex" }}>
          <NotificationAlert />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12} container className={cls.container}>
        <Grid item xs={12} className={cls.rideContainer}>
          <FormikProvider value={formik}>
            <Form className={cls.form} onSubmit={formik.handleSubmit}>
              <FormSection title={title}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormField
                      label={t("user_role.role_name")}
                      name={"role"}
                      required={true}
                      error={formik?.errors?.role}
                    />
                  </Grid>
                </Grid>
                <FormSection
                  title={
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {t("user_role.permissions")}
                    </span>
                  }
                  customBgColor="#E5E7EB"
                  subSection={true}
                >
                  <Grid container spacing={5}>
                    <Grid
                      item
                      container
                      xs={12}
                      className={cls.checklistItems}
                      style={{ gap: "20px" }}
                    >
                      {formik.values.selectedFeatures.map((feature, i) => (
                        <Grid item md={3} xs={12}>
                          <NCText className={cls.checkedItems}>
                            <NCCheckbox
                              checked={feature.checked}
                              key={i}
                              color="primary"
                              name={`selectedFeatures[${i}]`}
                              size="small"
                              onChange={() => {
                                formik.setFieldValue(`selectedFeatures[${i}]`, {
                                  ...feature,
                                  checked: !feature.checked
                                })
                              }}
                            />
                            {feature.feature}
                          </NCText>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </FormSection>
              </FormSection>
              <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <NCButton
                        onClick={() => nav("/user-access-management")}
                        variant="outlined"
                        color="primary"
                      >
                        {t("common.cancel")}
                      </NCButton>
                    </Grid>
                    <Grid item>
                      <NCButton
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t("common.update")}
                      </NCButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>
      {(allFeaturesLoading || userRoleDetailsLoading) && <NCLoader open={true} />}
    </Grid>
  )
}

export default UserAccessManagementUpdateRole