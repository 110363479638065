import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Grid } from "@material-ui/core"
import {
  useAddSubEmployee,
  useUpdateSubEmployee
} from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import FormField from "../FormField"
import PhoneNumber from "../PhoneNumber"
import { useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"

const AddSubAdminEmployeeModal = ({
  subAdminId,
  modalState = false,
  handleClose,
  isEdit = false,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const addEmployeeRequest = isEdit
    ? useUpdateSubEmployee(data.id)
    : useAddSubEmployee()
  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      phone: data.phone || ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("admin.first_name_required")),
      last_name: Yup.string().required(t("admin.last_name_required")),
      email: Yup.string().required(t("admin.email_required")),
      phone: Yup.string().required(t("admin.phone_required"))
    }),
    enableReinitialize: true,
    onSubmit(values) {
      addEmployeeRequest.mutate(
        { ...values, subadmin: subAdminId, id: data.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getSubEmployeeList"])
            queryClient.invalidateQueries(["getSubAdminAuditLog"])
            isEdit
              ? toast.success(t("admin.employee_updated"))
              : toast.success(t("admin.employee_added"))
            handleClose()
            !isEdit && formik.resetForm()
          }
        }
      )
    }
  })
  const employeeModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.first_name")}
              name={"first_name"}
              required={true}
              error={formik?.errors?.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.last_name")}
              name={"last_name"}
              required={true}
              error={formik?.errors?.last_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.email_address")}
              name={"email"}
              required={true}
              disabled={isEdit}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              as={PhoneNumber}
              label={t("common.phone_number")}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )

  return (
    <NCModal
      open={modalState}
      title={isEdit ? t("admin.update_employee") : t("admin.add_employee")}
      text={employeeModal}
      onClose={() => handleClose()}
      submitButton={
        <NCButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={formik.handleSubmit}
          style={{ width: "auto" }}
        >
          {t("common.submit")}
        </NCButton>
      }
    />
  )
}
export default AddSubAdminEmployeeModal
