import { Field } from "formik"
import NCContent from "../shared/NCContent"
import NCInput from "../shared/NCInput"
import { makeStyles, Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"
const useStyles = makeStyles({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  errorMessage: {
    fontSize: "12px",
    color: "red"
  }
})
const FormField = ({
  as = NCInput,
  label,
  startIcon,
  endIcon,
  name,
  placeholder,
  type = "input",
  required = false,
  children,
  error = false,
  ...props
}) => {
  const cls = useStyles()

  const {t} = useTranslation();

  return (
    <Box width={"100%"} className={props.className}>
      {label ? (
        <NCContent htmlFor={`${label}-input`} error={error}>
          {label}
          {required ? "*" : null}
        </NCContent>
      ) : (
        ""
      )}
      <Field
        {...props}
        as={as}
        id={`${label}-input`}
        className={cls.input}
        placeholder={
          placeholder ? placeholder : label ? `${t("common.enter")} ${label}` : null
        }
        startIcon={startIcon ? startIcon : null}
        endIcon={endIcon ? endIcon : null}
        name={name}
        type={type}
        select={children ? true : null}
        children={children ? children : null}
        error={error}
        helperText={error}
      />
    </Box>
  )
}

export default FormField
