import { Navigate, Outlet } from "react-router-dom"
import Sidebar from "../src/components/Sidebar"
import { isUserLoggedIn } from "./libs/utils"
import Pubnub from "./Pubnub"
import { STORAGE } from "./libs/storage"

const AuthenticatedRoute = () => {
  if (!isUserLoggedIn()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" replace />
  }

  const uuid = STORAGE.getPubnubUUID();

  return (
    <div>
      <Pubnub uuid={uuid}>
        <Sidebar>
          <Outlet />
        </Sidebar>
      </Pubnub>
    </div>
  )
}

export default AuthenticatedRoute
