import axios from "axios"
import { API_PATH } from "../libs/config"
import { toast } from "react-toastify"
import { STORAGE } from "./storage"
import { USER_TYPE } from "../libs/config"
import { LANGUAGE_LOCALE } from "../libs/config"

// const getBaseUrl = () => {
//   let baseUrl = API_BASE_PATH
//   if (
//     typeof process !== "undefined" &&
//     process?.env?.NODE_ENV === "development"
//   ) {
//     baseUrl = API_BASE_PATH
//   }
//   return baseUrl
// }

// const instance = axios.create({
//   baseURL: getBaseUrl()
// })
const instance = axios.create()

const notLoginCall = config => {
  const byPassUrl = [
    API_PATH.LOGIN,
    API_PATH.PRIVACY_POLICY,
    API_PATH.TERMS_AND_CONDITIONS,
    API_PATH.RESET_PASSWORD,
    API_PATH.NEW_PASSWORD
  ]
  return !byPassUrl.includes(config.url)
}

instance.interceptors.request.use(
  request => {
    if (!request.headers.Authorization) {
      const token = STORAGE.getItem("noahCares").token
      const locale = STORAGE.getLocale() || LANGUAGE_LOCALE["default_lang"]
      request.headers["Accept-Language"] = locale;
      if (token) {
        request.headers.Authorization = `Token ${token}`
      }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  },
  { runWhen: notLoginCall }
)

instance.interceptors.response.use(
  response => {
    if (
      response?.config?.url === API_PATH.LOGIN &&
      response?.data?.data?.user?.is_two_factor_enabled == false
    ) {
      let token = response?.data?.data?.token
      let user = response?.data?.data?.user
      if ([1, 2, 3, 4].includes(user.user_type)) {
        STORAGE.setItem("noahCares", { token, user })
      } else {
        STORAGE.clear()
        toast.error(
          `User with role ${USER_TYPE[user.user_type]} have no rights to login`
        )
      }
    } else if (
      response?.config?.url === API_PATH.LOGIN &&
      response?.data?.data?.user?.is_two_factor_enabled == true
    ) {
      let two_factor_identifier = response?.data?.data?.two_factor_identifier
      STORAGE.setItem("noahCares", { two_factor_identifier })
    } else if (
      response?.config?.url.includes(API_PATH.VERIFY_OTP) &&
      response?.config?.url.includes("verify_otp")
    ) {
      let token = response?.data?.data?.token
      let user = response?.data?.data?.user
      if ([1, 2, 3, 4].includes(user.user_type)) {
        STORAGE.setItem("noahCares", { token, user })
      } else {
        STORAGE.clear()
        toast.error(
          `User with role ${USER_TYPE[user.user_type]} have no rights to login`
        )
      }
    }
    return response
  },
  error => {
    toast.error(error?.response?.data?.message || error.message)
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      location.replace("/login")
    }
    return Promise.reject(error)
  }
)

export default instance
