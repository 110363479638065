import { useSearchParams } from "react-router-dom"
import { useGetDriverBasicDetail } from "../../services/queries"
import { AppBar, Box, Grid, Toolbar, Typography } from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { GENDER } from "../../libs/config"
import FormSection from "../../components/FormSection"
import NCLoader from "../../shared/NCLoader"
import { ProfileIcon } from "../../libs/icons"
import LogoTextIcon from "../../assets/icons/menu_icons/logotext.svg"
import NCModal from "../../shared/NCModal"
import { useEffect, useState } from "react"
import NCButton from "../../shared/NCButton"
import { useTranslation } from "react-i18next"

const BasicDetails = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation();
  const uuid = searchParams.get("uuid")

  const { isLoading, data: driverResult } = useGetDriverBasicDetail(uuid)
  const driverData = driverResult?.data?.data?.results[0] || null

  const [showModal,setShowModal] = useState(false);

  const renderRowItem = (key, value) => {
    return (
      <Grid item md={4}>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText className="overflow-ellipsis">{value}</NCText>
      </Grid>
    )
  }

  useEffect(()=>{
    if(!isLoading &&  !driverData){
      setShowModal(true);
    }
  },[isLoading])

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={LogoTextIcon} width={"70%"} />
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid
        container
        style={{
          padding: "20px"
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item md={6}>
              <Box mb={2} display={"flex"}>
                <NCText variant={"h4"} style={{ marginRight: "10px" }}>
                  {t("drivers.driver_detail")}
                </NCText>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TopNavBar />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={2}>
              {driverData?.avatar ? (
                <Box display={"flex"} justifyContent={"center"}>
                <img
                  src={driverData?.avatar}
                  width={"200px"}
                  height="200px"
                  style={{ borderRadius: driverData?.avatar ? "100%" : "0" }}
                  htmlFor="contained-button-file"
                />
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  style={{
                    background: "#EFFDFF",
                    padding: "20px 26px",
                    borderRadius: "16px"
                  }}
                >
                  <ProfileIcon />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={10}>
              <FormSection title="Driver Details">
                <Box mb={2}>
                  <Grid container>
                  {renderRowItem(t("common.first_name"), driverData?.first_name)}
                  {renderRowItem(t("common.middle_name"), driverData?.middle_name)}
                  {renderRowItem(t("common.last_name"), driverData?.last_name)}
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container>
                  {renderRowItem(t("common.gender"), GENDER[driverData?.gender])}
                  </Grid>
                </Box>
              </FormSection>
              <FormSection title="Vehicle Details">
                <Box mb={2}>
                  <Grid container>
                  {renderRowItem(t("list.mark"), driverData?.vehicle?.mark)}
                  {renderRowItem(t("list.model"), driverData?.vehicle?.model)}
                  {renderRowItem(t("list.vin"), driverData?.vehicle?.vin_no)}
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container>
                  {renderRowItem(
                    t("list.license_plate"),
                    driverData?.vehicle?.licence_plate
                  )}
                  </Grid>
                </Box>
              </FormSection>
            </Grid>
          </Grid>
        </Grid>
        <NCLoader open={isLoading} />
        <NCModal
          open={showModal}
          title={t("drivers.driver_detail")}
          text={t("drivers.approve_to_view_details")}
          onClose={() => setShowModal(false)}
          cancelButton={true}
          submitButton={
            <NCButton
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                setShowModal(false);
              }}
              style={{ width: "auto" }}
            >
              {t("common.ok")}
            </NCButton>
          }
        />
      </Grid>
    </>
  )
}

export default BasicDetails
