import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { Box, IconButton, Toolbar, AppBar, Typography } from "@material-ui/core"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import {
  AdminIcon,
  AidMonitorsIcon,
  // AnalyticsIcon,
  AppointmentIcon,
  ArrowIcon,
  ArrowSelectedIcon,
  BellMenuIcon,
  ClientsIcon,
  DispatchersIcon,
  DriversIcon,
  FeedbackIcon,
  DirectChatIcon,
  MapIcon,
  OverviewIcon,
  // PhoneCallIcon,
  TrainingIcon,
  UserRoleIcon,
  VehicleIcon,
  IncidentIcon,
  ManageReports,
  InspectionIcon,
  TripLogIcon,
  LiveLocationIcon,
  BankDetailsIcon,
  TransactionsIcon
} from "../libs/icons"
import LogoIcon from "../assets/icons/menu_icons/logo.svg"
import LogoTextIcon from "../assets/icons/menu_icons/logotext.svg"
import UserImg from "../assets/icons/menu_icons/user.svg"
import HamburgerIcon from "../assets/icons/menu_icons/hamburger-menu.svg"
import NCSvgIcon from "../shared/NCSvgIcon"
import { loggedInUser } from "../libs/utils"
import { USER_TYPE } from "../libs/config"
import UserMenu from "./UserMenu"
import NCText from "../shared/NCText"
import { STORAGE } from "../libs/storage"
import withContext from "./DrawerConsumer"
import ChatIcon from "../assets/icons/chat.svg"
import AddFeedback from "../containers/feedback/Add"
import { useState } from "react"
import NCTooltip from "../shared/NCTooltip"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "grid"
    }
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100vh",
    borderRadius: "0 20px 20px 0"
  },
  drawerOpen: {
    width: drawerWidth,
    background: "linear-gradient(174.61deg, #1EAAC4 0%, #10658E 104.65%)",
    // position: "relative",
    borderRadius: "0 20px 20px 0",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaper: {
    width: drawerWidth,
    background: "linear-gradient(174.61deg, #1EAAC4 0%, #10658E 104.65%)"
  },
  drawerClose: {
    background: "linear-gradient(174.61deg , #1EAAC4 0%, #10658E 104.65%)",
    borderRadius: "0 20px 20px 0",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  menuList: {
    padding: "8px 20px 8px 20px",
    marginTop: "10px",
    paddingBottom: "50px"
  },
  listItem: {
    color: "#FAFAFA",
    borderRadius: "20px",
    padding: "8px",
    "&:hover": {
      textDecoration: "none"
    },
    [theme.breakpoints.up("xl")]: {
      margin: "40px 0"
    }
  },
  leftArrow: {
    position: "absolute",
    background: "#fff",
    borderRadius: "20px 0 0 20px",
    right: 0,
    top: "25px",
    zIndex: 1,
    padding: "10px 10px 5px 10px"
  },
  rightArrow: {
    top: "25px",
    left: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      left: theme.spacing(9) + 1
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    zIndex: 1,
    background: theme.palette.primary.main,
    borderRadius: "0 20px 20px 0",
    position: "fixed",
    padding: "10px 10px 5px 10px"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1)
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3)
    }
    // height: `cal(100%- ${theme.spacing(3)}px)`,
    // overflowX: "auto"
  },
  bottomMenu: {
    borderBottomRightRadius: "8px",
    padding: "5px",
    // background:"#10658E"
    background: "linear-gradient(0deg, #1EAAC4 -1220%, #10658E 284.65%)"
  }
}))

// const ACCESS_ROLES = {
//   SUPER_ADMIN: 1,
//   ADMIN: 2,
//   SUB_ADMIN: 3,
//   DISPATCHER: 4
// }

const MENU_ITEMS = [
  {
    name: "navigation.requests",
    icon: OverviewIcon,
    to: "/overview",
    role: "REQUESTS"
  },
  {
    name: "navigation.clients",
    icon: ClientsIcon,
    to: "/clients",
    role: "CLIENTS"
  },
  {
    name: "navigation.admins",
    icon: AdminIcon,
    to: "/admins",
    role: "ADMINS"
  },
  {
    name: "navigation.sub_admins",
    icon: AdminIcon,
    to: "/subadmins",
    role: "SUBADMINS"
  },
  {
    name: "navigation.dispatchers",
    icon: DispatchersIcon,
    to: "/dispatchers",
    role: "DISPATCHERS"
  },
  {
    name: "navigation.drivers",
    icon: DriversIcon,
    to: "/drivers",
    role: "DRIVERS"
  },
  {
    name: "navigation.aid_monitor",
    icon: AidMonitorsIcon,
    to: "/aidmonitor",
    role: "AID_MONITORS"
  },
  {
    name: "navigation.map",
    icon: MapIcon,
    to: "/map-view-listing",
    role: "MAP"
  },
  {
    name: "navigation.vehicles",
    icon: VehicleIcon,
    to: "/vehicles",
    role: "VEHICLES"
  },
  {
    name: "navigation.training",
    icon: TrainingIcon,
    to: "/training",
    role: "TRAINING"
  },
  {
    name: "navigation.inspection",
    icon: InspectionIcon,
    to: "/inspection",
    role: "INSPECTION"
  },
  {
    name: "navigation.chat",
    icon: DirectChatIcon,
    to: "/direct-message",
    role: "CHAT"
  },
  // {
  //   name: "Phone calls",
  //   icon: PhoneCallIcon,
  //   to: "/phonecalls",
  //   role: "PHONE_CALLS"
  // },
  // {
  //   name: "Analytics",
  //   icon: AnalyticsIcon,
  //   to: "/analytics",
  //   role: "ANALYTICS"
  // },
  {
    name: "navigation.users",
    icon: UserRoleIcon,
    to: "/user-access-management",
    role: "USER_ROLE"
  },
  {
    name: "navigation.incident_reports",
    icon: IncidentIcon,
    to: "/incidents",
    role: "INCIDENT_REPORT"
  },
  {
    name: "navigation.manage_reports",
    icon: ManageReports,
    to: "/manage-reports",
    role: "REPORTS"
  },
  {
    name: "navigation.my_reports",
    icon: ManageReports,
    to: "/my-reports",
    role: "MY_REPORTS"
  },
  {
    name: "navigation.feedback",
    icon: FeedbackIcon,
    to: "/feedback",
    role: "FEEDBACK"
  },
  {
    name: "navigation.appointments",
    icon: AppointmentIcon,
    to: "/appointments",
    role: "APPOINTMENTS"
  },
  {
    name: "navigation.trip_log",
    icon: TripLogIcon,
    to: "/trip-log",
    role: "TRIP_LOG"
  },
  {
    name: "navigation.bank_details",
    icon: BankDetailsIcon,
    to: "/bank-account",
    role: "BANK_DETAILS"
  },
  {
    name: "navigation.live_location",
    icon: LiveLocationIcon,
    to: "/live-location",
    role: "LIVE_LOCATION"
  },
  {
    name: "navigation.cancelled_rides",
    icon: BellMenuIcon,
    to: "/notification",
    role: "CANCELLED_RIDES"
  },
  {
    name: "navigation.transactions",
    icon: TransactionsIcon,
    to: "/transactions",
    role: "TRANSACTIONS"
  }
]

const MiniDrawer = props => {
  const { t } = useTranslation()
  const [chatWindow, showChatWindow] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const classes = useStyles()
  const open = props.drawer
  const user = loggedInUser()

  const accessList = STORAGE.getAccessList()

  const handleDrawerClose = () => {
    // setOpen(!open)
    props.setDrawer(!props.drawer)
    STORAGE.setItem("noahCares", {
      ...STORAGE.getItem("noahCares"),
      drawerState: !props.drawer
    })
  }

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setIsDrawerOpen(open)
  }

  const handleNavClick = navName => {
    setSelectedItem(navName)
    setIsDrawerOpen(false)
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      {isSmallScreen && (
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <img src={HamburgerIcon} />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={LogoTextIcon} width={"70%"} />
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {isSmallScreen ? (
        <Drawer
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <List className={classes.menuList}>
            {MENU_ITEMS.filter(item => accessList.includes(item.role)).map(
              (item, index) => (
                <ListItem
                  button
                  key={index}
                  className={classes.listItem}
                  style={
                    isDrawerOpen ? { width: "auto" } : { width: "fit-content" }
                  }
                  component={Link}
                  to={item.to}
                  onClick={() => setIsDrawerOpen(false)}
                  selected={location.pathname.includes(item.to)}
                >
                  <Box fontSize={"16px"}>
                    <NCSvgIcon component={item.icon} inheritViewBox />
                  </Box>
                  <Box display={isDrawerOpen ? "block" : "none"} ml={"10px"}>
                    <NCText variant={"body2"}>{t(item.name)}</NCText>
                  </Box>
                </ListItem>
              )
            )}
          </List>
          <Box
            position={"fixed"}
            bottom={"0px"}
            left={"0.5px"}
            width={"inherit"}
          >
            {user.user_type === 2 || user.user_type === 3 ? (
              <Box bgcolor={"rgba(0, 0, 0, 0.04)"} py={"10px"}>
                {isDrawerOpen && (
                  <Box
                    pr={"26px"}
                    pl={"26px"}
                    mb={"10px"}
                    className={classes.bottomMenu}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      onClick={() => showChatWindow(!chatWindow)}
                      className="cursor-pointer"
                    >
                      <img src={ChatIcon} alt="user" width={"25px"} />
                      <Box color={"#fff"} ml={"10px"}>
                        <NCText variant={"body2"}>{t("feedback.add.send_feedback")}</NCText>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              ""
            )}
            {isDrawerOpen && (
              <Box pr={"26px"} pl={"26px"} className={classes.bottomMenu}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <img src={UserImg} alt="user" width={"25px"} />
                    <Box color={"#fff"} ml={"10px"}>
                      <NCTooltip title={user.name}>
                        <div>
                          <NCText
                            variant={"body2"}
                            className={"overflow-ellipsis"}
                            style={{ width: "80%", paddingRight: "0" }}
                          >
                            {user.name || ""}
                          </NCText>
                        </div>
                      </NCTooltip>
                      <NCText variant={"caption"}>
                        {USER_TYPE[user.user_type]}
                      </NCText>
                    </Box>
                  </Box>
                  <UserMenu />
                </Box>
              </Box>
            )}
          </Box>
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          {open ? (
            <NCSvgIcon
              onClick={handleDrawerClose}
              component={ArrowSelectedIcon}
              className={classes.leftArrow}
              inheritViewBox={false}
            />
          ) : (
            ""
          )}
          {open ? (
            <Box pt={3} pl={2}>
              <img src={LogoTextIcon} width={"70%"} />
            </Box>
          ) : (
            <Box pt={3} pl={2}>
              <img src={LogoIcon} width={"90%"} />
            </Box>
          )}
          <List className={classes.menuList}>
            {MENU_ITEMS.filter(item => accessList.includes(item.role)).map(
              (item, index) => (
                <ListItem
                  button
                  key={index}
                  className={classes.listItem}
                  style={{
                    width: open ? "auto" : "fit-content",
                    backgroundColor:
                      selectedItem === item && isDrawerOpen
                        ? "rgba(0, 0, 0, 0.08)"
                        : ""
                  }}
                  component={Link}
                  to={item.to}
                  onClick={() => handleNavClick(item)}
                  selected={location.pathname.includes(item.to)}
                >
                  <Box fontSize={"16px"}>
                    <NCSvgIcon component={item.icon} inheritViewBox />
                  </Box>
                  <Box display={open ? "block" : "none"} ml={"10px"}>
                    <NCText variant={"body2"}>{t(item.name)}</NCText>
                  </Box>
                </ListItem>
              )
            )}
          </List>
          <Box
            position={"fixed"}
            bottom={"0px"}
            width={"inherit"}
            left={"0.5px"}
          >
            {user.user_type === 2 || user.user_type === 3 ? (
              <Box bgcolor={"rgba(0, 0, 0, 0.04)"} py={"10px"}>
                {open ? (
                  <Box
                    pr={"26px"}
                    pl={"26px"}
                    mb={"10px"}
                    className={classes.bottomMenu}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      onClick={() => showChatWindow(!chatWindow)}
                      className="cursor-pointer"
                    >
                      <img src={ChatIcon} alt="user" width={"25px"} />
                      <Box color={"#fff"} ml={"10px"}>
                        <NCText variant={"body2"}>{t("feedback.add.send_feedback")}</NCText>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box px={"10px"} mb={"10px"}>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={() => showChatWindow(!chatWindow)}
                    >
                      <img src={ChatIcon} width={"25px"} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ) : (
              ""
            )}
            {open ? (
              <Box pr={"26px"} pl={"26px"} className={classes.bottomMenu}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <img src={UserImg} alt="user" width={"25px"} />
                    <Box color={"#fff"} ml={"10px"}>
                      <NCTooltip title={user.name}>
                        <div>
                          <NCText
                            variant={"body2"}
                            className={"overflow-ellipsis"}
                            style={{ width: "80%", paddingRight: "0" }}
                          >
                            {user.name || ""}
                          </NCText>
                        </div>
                      </NCTooltip>
                      <NCText variant={"caption"}>
                        {USER_TYPE[user.user_type]}
                      </NCText>
                    </Box>
                  </Box>
                  <UserMenu />
                </Box>
              </Box>
            ) : (
              <Box px={"10px"}>
                <UserMenu
                  icon={<img src={UserImg} alt="user" width={"25px"} />}
                />
              </Box>
            )}
          </Box>
        </Drawer>
      )}
      <main className={classes.content}>
        {open ? (
          ""
        ) : (
          <NCSvgIcon
            onClick={handleDrawerClose}
            component={ArrowIcon}
            className={classes.rightArrow}
            inheritViewBox={false}
          />
        )}
        {props.children}
        {chatWindow ? <AddFeedback showChatWindow={showChatWindow} /> : null}
      </main>
    </div>
  )
}

export default withContext(MiniDrawer)
