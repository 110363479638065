import { createContext, useState } from "react"
import { Box } from "@material-ui/core"
import { ToastContainer } from "react-toastify"
import { QueryClient, QueryClientProvider } from "react-query"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import NCLoader from "./shared/NCLoader"
import "react-toastify/dist/ReactToastify.min.css"
import Routes from "./Routes"
import { STORAGE } from "./libs/storage"
import NCModal from "./shared/NCModal"
import NCButton from "./shared/NCButton"
import usePageTracking from "./libs/hooks/usePagTracking"
import { useTranslation } from "react-i18next"

export const SampleContext = createContext()

const App = () => {
  usePageTracking();
  const {t} = useTranslation();
  const drawerState = STORAGE.getItem("noahCares")?.drawerState || false
  const [isLoader, showLoader] = useState(false)
  const [emailModal, showEmailModal] = useState(false)
  const [modalHeader, showModalHeader] = useState("")
  const [emailMessage, setEmailMessage] = useState("")
  const [drawer, setDrawer] = useState(drawerState || false)
  const queryClient = new QueryClient({})
  const renderContent = (
    <Box pt={"15px"} pb={"30px"}>
      {emailMessage}
    </Box>
  )
  return (
    <SampleContext.Provider
      value={{
        drawer,
        setDrawer,
        showLoader,
        showEmailModal,
        showModalHeader,
        setEmailMessage
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <QueryClientProvider client={queryClient}>
          <Routes />
          <NCLoader open={isLoader} />
          <ToastContainer
            position="bottom-right"
            autoClose={1000}
            closeButton={false}
            draggable={false}
            theme="dark"
          />
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
      <NCModal
        open={emailModal}
        title={
          <Box fontWeight={"bold"} fontSize={"16px"}>
            {modalHeader}
          </Box>
        }
        text={renderContent}
        onClose={() => showEmailModal(false)}
        cancelButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => showEmailModal(false)}
            style={{ width: "auto" }}
          >
            {t("common.continue")}
          </NCButton>
        }
        submitButton={" "}
      />
    </SampleContext.Provider>
  )
}

SampleContext.displayName = "SampleData"

export default App
