import { Grid, makeStyles, Paper } from "@material-ui/core"
import NCContent from "../shared/NCContent"
import NCInput from "../shared/NCInput"
import NCTitle from "../shared/NCTitle"
import NCText from "../shared/NCText"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import { ErrorMessage, Form, Field, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { useResetPassword } from "../services/mutations"
// import { toast } from "react-toastify"
import NCLoader from "../shared/NCLoader"
import withContext from "../components/DrawerConsumer"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
  },
  title: {
    marginTop: "12px",
    marginBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& .MuiFormLabel-root": {
      marginBottom: "5px"
    },
    "& .MuiButtonBase-root": {
      marginTop: "25px"
    }
  }
}))

const ResetPassword = props => {
  const cls = useStyles()
  const {t} = useTranslation();
  const resetPasswordRequest = useResetPassword()
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("reset_password.error.invalid_email"))
        .required(t("reset_password.error.email_required"))
    }),
    onSubmit(values) {
      resetPasswordRequest.mutate(values, {
        onSuccess: () => {
          // toast.success("Password reset e-mail has been sent.")
          props.setEmailMessage(
            t("reset_password.message.check_spam")
          )
          props.showEmailModal(true)
        },
        onError: () => {
          //console.log("error block")
        }
      })
    }
  })
  return (
    <AuthComponent>
      <Grid item xs={11} sm={8}>
        <Paper elevation={0} className={cls.paper}>
          <NCTitle className={cls.title}>{t("reset_password.heading")}</NCTitle>
          <NCText className={cls.text}>
            {t("reset_password.sub_heading")}
          </NCText>
          <FormikProvider value={formik}>
            <Form className={cls.form} onSubmit={formik.handleSubmit}>
              <NCContent error={!!formik?.errors?.email}>
              {t("reset_password.email_address")} *
              </NCContent>
              <Field
                as={NCInput}
                className={cls.input}
                placeholder={`${t("common.enter")} ${t("reset_password.email_address")}`}
                name="email"
                error={!!formik?.errors?.email}
                helperText={
                  <ErrorMessage
                    className={cls.errorMessage}
                    component={NCText}
                    name="email"
                  />
                }
              />
              <NCButton variant="contained" color="primary" type="submit">
                {t("reset_password.send_link")}
              </NCButton>
              {resetPasswordRequest.isLoading && <NCLoader open={true} />}
            </Form>
          </FormikProvider>
        </Paper>
      </Grid>
    </AuthComponent>
  )
}

export default withContext(ResetPassword)
