import { formatDate } from "../../libs/utils"
import NCTable from "../../shared/table/NCTable"
import { useState } from "react"
import { useGetInspectionListByDriver } from "../../services/queries"
import { INSPECTION_TYPE } from "../../libs/const"
import { IconButton } from "@material-ui/core"
import { DetailIcon } from "../../libs/icons"
import { useNavigate } from "react-router-dom"
import Filter from "../../shared/table/Filter"
import { INSPECTION_REPORT_TYPE } from "../../libs/config"

const DriverInspectionList = ({ driverId }) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [statusFilter, setStatusFilter] = useState([])

  const nav = useNavigate()

  const { data } = useGetInspectionListByDriver(page, null, pageSize, {
    driver: driverId,
    type:
      statusFilter && statusFilter.length && statusFilter?.[0]?.value
        ? statusFilter?.[0]?.value?.map(id => parseInt(id))?.join(",") || ""
        : null
  })

  const { next, previous, total_pages, results } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const getInspectionDetailsById = id => {
    return results.find(item => item.id === id)
  }

  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton
          onClick={() =>
            nav(`/drivers/${driverId}/${cell.id}`, {
              state: {
                details: getInspectionDetailsById(cell.id)
              }
            })
          }
        >
          <DetailIcon />
        </IconButton>
      </div>
    )
  }

  function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <Filter
        options={Object.keys(INSPECTION_REPORT_TYPE).map(key => {
          return {
            key,
            name: INSPECTION_REPORT_TYPE[key]
          }
        })}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }
  const columns = [
    {
      Header: "Time and date",
      id: "time",
      accessor: cell => formatDate(cell?.created),
      disableSortBy: true
    },
    {
      Header: "Inspection ID",
      accessor: cell => cell?.id,
      disableSortBy: true
    },
    {
      Header: "Inspection Type",
      id: "type",
      accessor: cell =>
        INSPECTION_TYPE?.find(list => list.id == cell?.type)?.name,
      disableSortBy: true,
      defaultCanFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: "Images Uploaded",
      id: "images",
      accessor: cell => cell?.inspection_images?.length,
      Cell: ({ value }) => `${value}/5`,
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  return (
    <NCTable
      columns={columns}
      page={page}
      onNext={onNext}
      onPrev={onPrev}
      pageCount={total_pages}
      gotoPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      data={results || []}
      filter={statusFilter}
      setFilter={setStatusFilter}
      overrideFooterCss={{
        position: "static",
        margin: 0
      }}
    />
  )
}

export default DriverInspectionList
