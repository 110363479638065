import { Box } from "@material-ui/core"
import { useLocation, useNavigate } from "react-router-dom"
import NCText from "../shared/NCText"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  ".MuiBox-root": {
    padding: "5px"
  }
}))
const TopNavBar = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation();
  const nav = useNavigate()
  const cls = useStyles()

  const renderPath = () => {
    let pathParts = pathname.split("/")
    pathParts.splice(0, 1)
    let prevPath = ""
    return pathParts.reduce((acc, path, index) => {
      let obj = { path, name: path }
      obj.name = obj.name.replaceAll("-", " ")
      obj.name = obj.name.charAt(0).toUpperCase() + obj.name.slice(1)      
      if (index) {
        obj.name = ` / ${t(obj.name)}`
      }else{
        obj.name = t(obj.name)
      }
      obj.path = `${prevPath}/${path}`
      prevPath = obj.path
      acc.push(obj)
      return acc
    }, [])
  }

  return (
    <Box
      bgcolor={"#F3FAFC"}
      px={2}
      py={2}
      mb={2}
      display={"flex"}
      alignItems={"baseline"}
      margin="0px -24px 16px -32px"
    >
      {renderPath().map(item => {
        return pathname === item.path ? (
          <Box className={cls.text} height={"5px"} ml={1}>
            <NCText>{item.name}</NCText>
          </Box>
        ) : (
          <Box
            className={cls.text}
            height={"5px"}
            color={"#1EAAC4"}
            style={{ cursor: "pointer" }}
          >
            <NCText onClick={() => nav(item.path)}>{item.name}</NCText>
          </Box>
        )
      })}
    </Box>
  )
}

export default TopNavBar
