import { Box } from "@material-ui/core"
import NCText from "../NCText"
import { clsx } from "clsx"
import withContext from "../../components/DrawerConsumer"
import { useTranslation } from "react-i18next"

const Footer = ({
  onPrev,
  onNext,
  gotoPage,
  pageCount,
  pageSize,
  setPageSize,
  currentPage,
  overrideFooterCss,
  inlineFooter
}) => {
  const { t } = useTranslation();
  const getFooter = () => (
    <div className="table-footer">
      <NCText variant="body2">
        {t("no_of_rows")}:&nbsp;
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(e.target.value || undefined)
            gotoPage(1)
          }}
        >
          {[2, 5, 10, 20].map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </NCText>
      <div>
        <button className="" onClick={onPrev} disabled={currentPage === 1}>
          {t("prev")}
        </button>{" "}
        {pageCount > 0 && (
          <button
            className={clsx({ active: currentPage === 1 })}
            onClick={() => gotoPage(1)}
          >
            1
          </button>
        )}
        {pageCount > 1 && (
          <button
            className={clsx({ active: currentPage === 2 })}
            onClick={() => gotoPage(2)}
          >
            2
          </button>
        )}
        {pageCount > 2 && (
          <button
            className={clsx({ active: currentPage === 3 })}
            onClick={() => gotoPage(3)}
          >
            3
          </button>
        )}
        {pageCount > 3 && "..."}
        {pageCount - 3 > 2 && (
          <button
            className={clsx({ active: currentPage === pageCount - 2 })}
            onClick={() => gotoPage(pageCount - 2)}
          >
            {pageCount - 2}
          </button>
        )}
        {pageCount - 3 > 1 && (
          <button
            className={clsx({ active: currentPage === pageCount - 1 })}
            onClick={() => gotoPage(pageCount - 1)}
          >
            {pageCount - 1}
          </button>
        )}
        {pageCount - 3 > 0 && (
          <button
            className={clsx({ active: currentPage === pageCount })}
            onClick={() => gotoPage(pageCount)}
          >
            {pageCount}
          </button>
        )}
        <button className="" onClick={onNext} disabled={currentPage === pageCount}>
          {t("next")}
        </button>
      </div>
    </div>
  )
  return inlineFooter ? (
    getFooter()
  ) : (
    <Box
      p={"10px"}
      position={"fixed"}
      bottom={0}
      // width={props.drawer ? "79.5%" : "92.5%"}
      width={"-webkit-fill-available"}
      bgcolor={"#F3FAFC"}
      m={"20px 0 0 -30px"}
      style={overrideFooterCss ? overrideFooterCss : {}}
    >
      {getFooter()}
    </Box>
  )
}

export default withContext(Footer)
