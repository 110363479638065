import { Box, Grid, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Map from "../../components/map/Map"
import {
  DropLocationIcon,
  EditWhiteIcon,
  EndMarkerIcon,
  PickupLocationIcon,
  StartMarkerIcon
} from "../../libs/icons"
import FormField from "../../components/FormField"
import { FormikProvider, useFormik } from "formik"
import { useParams } from "react-router-dom"
import { rideStatus } from "../../libs/const"
import { formatAMPM } from "../../libs/utils"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { useTranslation } from "react-i18next"
import FormSection from "../../components/FormSection"
import { useGetTripReportDetail } from "../../services/queries"
import { GENDER, USER_TYPE_ROLES } from "../../libs/config"
import NCLoader from "../../shared/NCLoader"
import NCButton from "../../shared/NCButton"
import {
  useMarkAsPaid,
  usePostReportToAdmin,
  useUpdateInvoice
} from "../../services/mutations"
import { toast } from "react-toastify"
import { NOAH_CARES, STORAGE } from "../../libs/storage"
import NCSvgIcon from "../../shared/NCSvgIcon"
import EditInvoice from "./EditInvoice"

const useStyles = makeStyles(theme => ({
  container: {
    border: "1px solid #F3F4F6"
  },
  headingContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "10px"
    }
  },
  heading: {
    fontSize: "26px",
    padding: "10px 0px"
  },
  requestId: {
    fontWeight: 700
  },
  status: {
    fontSize: "18px",
    fontWeight: 600
  },
  statusText: {
    color: "#7D7D7D",
    fontSize: "14px"
  },
  boxContainer: {
    border: "1px solid #D1D5DB"
  },
  circle: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 10px"
  },
  yColor: {
    backgroundColor: "#F9B50B"
  },
  gColor: {
    backgroundColor: "#4CAF50"
  },
  rightContainerTitle: {
    color: "#2E3B36",
    fontSize: "24px",
    padding: "15px 20px",
    borderBottom: "1px solid #E3E3E3"
  },
  detailContainer: {
    marginTop: "20px"
  },
  rightContainer: {
    marginLeft: "5px"
  },
  editButton: {
    flexDirection: "row",
    alignItems: "center"
  },
  invoiceItem: {
    marginBottom: "20px"
  },
  invoiceText: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "Roboto",
    color: "#2E3B36"
  }
}))

const ReportDetails = () => {
  const cls = useStyles()
  const { t } = useTranslation()
  const { id } = useParams()
  const [center, setCenter] = useState(null)
  const [dropAddress, setDropAddress] = useState(null)
  const [pickupAddress, setPickUpAddress] = useState(null)
  const [editVisible, setEditVisible] = useState(false)
  const { isLoading, data: reportData } = useGetTripReportDetail(id)
  const tripReportData = reportData?.data?.data || {}
  const status = tripReportData?.status
  const user = STORAGE.getItem(NOAH_CARES)?.user
  const isSuperAdmin = user?.user_type === USER_TYPE_ROLES.SUPER_ADMIN // Used to check if Admin section should be shown or not
  const formik = useFormik({
    initialValues: {
      pickupAddress: "",
      estimatedPickUpTime: ""
    }
  })
  const sendReportRequest = usePostReportToAdmin()
  const editInvoiceRequest = useUpdateInvoice()
  const markPaidRequest = useMarkAsPaid()

  useEffect(() => {
    if (tripReportData?.pickup_address) {
      setCenter({
        lat: tripReportData?.pickup_address?.latitude,
        lng: tripReportData?.pickup_address?.longitude
      })
      setPickUpAddress({
        lat: tripReportData?.pickup_address?.latitude,
        lng: tripReportData?.pickup_address?.longitude
      })
      setDropAddress({
        lat: tripReportData?.drop_off_address?.latitude,
        lng: tripReportData?.drop_off_address?.longitude
      })
      formik.setFieldValue(
        "pickupAddress",
        tripReportData?.pickup_address?.address
      )
      formik.setFieldValue(
        "estimatedPickUpTime",
        formatAMPM(tripReportData?.pickup_time)
      )
      formik.setFieldValue(
        "dropoffAddress",
        tripReportData?.drop_off_address?.address
      )
      formik.setFieldValue(
        "estimatedDropOffTime",
        formatAMPM(tripReportData?.drop_off_time)
      )
    }
  }, [reportData])

  const sendReportToAdmin = () => {
    const dataToSend = {
      trips: [tripReportData?.id]
    }
    sendReportRequest.mutate(dataToSend, {
      onSuccess: () => {
        toast.success("Admin has been successfully added")
      }
    })
  }

  const markReportAsPaid = () => {
    const dataToSend = {
      trips: [tripReportData?.id]
    }
    markPaidRequest.mutate(dataToSend, {
      onSuccess: response => {
        toast.success(response?.data?.message)
      }
    })
  }

  const updateInvoice = invoiceData => {
    const updatedInvoiceData = invoiceData.map(item => ({
      id: item.id,
      price: item.price
    }))
    const data = {
      id: tripReportData?.id,
      invoice: updatedInvoiceData
    }
    // Handle invoice update here
    editInvoiceRequest.mutate(data, {
      onSuccess: () => {
        toast.success("Invoice updated added")
      },
      onError: error => {
        toast.error(error)
      },
      onSettled: () => {
        setEditVisible(false)
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid>
              <NCText variant={"h4"}>{t("specific_report")}</NCText>
            </Grid>
            {isSuperAdmin && (
              <Box display="flex" justifyContent="flex-end">
                <Grid container pacing={1}>
                  {tripReportData?.report_status !== "PAID" && (
                    <Grid item style={{ marginRight: "10px" }}>
                      <NCButton
                        variant="contained"
                        color="primary"
                        onClick={markReportAsPaid}
                      >
                        {t("mark_paid")}
                      </NCButton>
                    </Grid>
                  )}
                  {tripReportData?.report_status === "PENDING" && (
                    <Grid item>
                      <NCButton
                        variant="contained"
                        color="primary"
                        onClick={sendReportToAdmin}
                      >
                        {t("send_to_admin")}
                      </NCButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12} className={cls.container}>
        <Box mb={2} p={1}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className={cls.headingContainer}
          >
            <Box
              className={cls.heading}
              display={"flex"}
              gridGap={"10px"}
              alignItems={"center"}
            >
              <span>{t("map.request")}</span>
              <span className={cls.requestId}>{reportData?.data?.id}</span>
              <span
                className={[
                  cls.circle,
                  status === 5 ? cls.yColor : cls.gColor
                ].join(" ")}
              ></span>
              <span className={cls.status}>{t("status_o."+rideStatus[status])}</span>
            </Box>
            <Box display={"flex"} alignItems={"center"} gridGap={"10px"}>
              {status === 5 ? (
                <>
                  <PickupLocationIcon />
                  <NCText><span>Client Location</span></NCText>
                  <DropLocationIcon />
                  <NCText><span>Driver Location</span></NCText>
                </>
              ) : (
                <>
                  <StartMarkerIcon />
                  <NCText><span>Driver Location</span></NCText>
                  <EndMarkerIcon />
                  <NCText><span>Drop Location</span></NCText>
                </>
              )}
            </Box>
          </Box>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={6}>
              <Box height={"300px"} p={1} className={cls.boxContainer}>
                {pickupAddress && (
                  <Map
                    startAddress={pickupAddress}
                    dropAddress={dropAddress}
                    showClientToDriver={status === 5}
                    showStartToEnd={status !== 5}
                    center={center}
                    zoomControl={true}
                    zoom={15}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box height={"100%"} className={cls.boxContainer} ml={1} p={0}>
                <FormikProvider value={formik}>
                <NCText><div className={cls.rightContainerTitle}>{t("map.ride_details")}</div></NCText>
                  <Box p={1}>
                    <FormField
                      disabled={true}
                      label={t("map.pickup_address")}
                      name={"pickupAddress"}
                    />
                  </Box>
                  <Box p={1}>
                    <FormField
                      disabled={true}
                      label={
                        status === 5 ? t("map.pickup_est_time") : t("map.pickup_time")
                      }
                      name={"estimatedPickUpTime"}
                    />
                  </Box>
                  {status !== 5 && (
                    <>
                      <Box p={1}>
                        <FormField
                          disabled={true}
                          label={t("map.drop_address")}
                          name={"dropoffAddress"}
                        />
                      </Box>
                      <Box p={1}>
                        <FormField
                          disabled={true}
                          label={t("map.drop_est_time")}
                          name={"estimatedDropOffTime"}
                        />
                      </Box>
                    </>
                  )}
                </FormikProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        className={cls.detailContainer}
      >
        <Grid item xs={8}>
          <FormSection title={t("client_details")}>
            <Box mb={2}>
              <Grid container>
                {RowItem(
                  t("first_name"),
                  tripReportData?.client_details?.first_name
                )}
                {RowItem(
                  t("middle_name"),
                  tripReportData?.client_details?.middle_name
                )}
                {RowItem(
                  t("last_name"),
                  tripReportData?.client_details?.last_name
                )}
              </Grid>
            </Box>
            <Box mb={2}>
              <Grid container>
                {RowItem(t("email"), tripReportData?.client_details?.email)}
                {RowItem(t("phone_num"), tripReportData?.client_details?.phone)}
                {RowItem(
                  t("gender"),
                  GENDER[tripReportData?.client_details?.gender]
                )}
              </Grid>
            </Box>
          </FormSection>
          {isSuperAdmin && (
            <FormSection title={t("admin_details")}>
              <Box mb={2}>
                <Grid container>
                  {RowItem(
                    t("company_name"),
                    tripReportData?.client_request?.client_subadmin_info?.admin
                      ?.company_name
                  )}
                  {RowItem(
                    t("company_email"),
                    tripReportData?.client_request?.client_subadmin_info?.admin
                      ?.company_email_address
                  )}
                </Grid>
              </Box>
            </FormSection>
          )}
          <FormSection title="Driver Details">
            <Box mb={2}>
              <Grid container>
                {RowItem(t("first_name"), tripReportData?.driver?.first_name)}
                {RowItem(t("middle_name"), tripReportData?.driver?.middle_name)}
                {RowItem(t("last_name"), tripReportData?.driver?.last_name)}
              </Grid>
            </Box>
            <Box mb={2}>
              <Grid container>
                {RowItem(t("email"), tripReportData?.driver?.email)}
                {RowItem(t("phone_num"), tripReportData?.driver?.phone)}
                {RowItem(t("gender"), GENDER[tripReportData?.driver?.gender])}
              </Grid>
            </Box>
          </FormSection>
          <FormSection title="Vehicle Details">
            <Box mb={2}>
              <Grid container>
                {RowItem(t("model"), tripReportData?.driver?.vehicle.mark)}
                {RowItem(
                  t("licence_plate"),
                  tripReportData?.driver?.vehicle.licence_plate
                )}
                {RowItem(t("vin"), tripReportData?.driver?.vehicle.vin_no)}
              </Grid>
            </Box>
          </FormSection>
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: "20px" }}>
          <FormSection
            title={t("invoice")}
            subTitle={
              isSuperAdmin &&
              tripReportData?.report_status !== "PAID" && (
                <NCButton
                  variant="contained"
                  color="primary"
                  endIcon={
                    <NCSvgIcon component={EditWhiteIcon} inheritViewBox />
                  }
                  onClick={() => setEditVisible(true)}
                  className={cls.editButton}
                >
                  {"edit"}
                </NCButton>
              )
            }
          >
            <Box mb={4} paddingTop={"20px"}>
              {tripReportData?.invoice?.map(item =>
                InvoiceItem(item?.name, item?.price, cls)
              )}
            </Box>
          </FormSection>
        </Grid>
      </Grid>
      <NCLoader open={isLoading} />
      <EditInvoice
        open={editVisible}
        onClose={() => setEditVisible(false)}
        invoice={tripReportData?.invoice || []}
        onSubmit={invoice => updateInvoice(invoice)}
      />
    </Grid>
  )
}

const RowItem = (key, value) => {
  return (
    <Grid item md={4}>
      <NCText>
        <Box fontSize={"12px"} color={"#9CA3AF"}>
          {key}
        </Box>
      </NCText>
      <Grid xs={12}>
        <NCText className="overflow-ellipsis">{value}</NCText>
      </Grid>
    </Grid>
  )
}

const InvoiceItem = (key, value, cls) => {
  return (
    <Grid container xs={12} className={cls?.invoiceItem}>
      <Grid item xs={10}>
        <Box className={cls.invoiceText}>{key}</Box>
      </Grid>
      <Grid item xs={2} justifyContent="flex-end">
        <Box className={cls.invoiceText}>${value}</Box>
      </Grid>
    </Grid>
  )
}

export default ReportDetails
