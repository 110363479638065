import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import React from "react"
import NCText from "../../shared/NCText"
import NotificationAlert from "../notification/NotificationAlert"
import NCInput from "../../shared/NCInput"
import { useState } from "react"
import useDebounce from "../../libs/hooks/Debounce"
import { SEARCH_CONFIG, TABLE_CONFIG, USER_TYPE } from "../../libs/config"
import { AddIcon, DeleteIcon, EditIcon, SearchIcon } from "../../libs/icons"
import NCTable from "../../shared/table/NCTable"
import { useGetUserRoleListForAccessManagement } from "../../services/queries"
import { useNavigate } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import NCModal from "../../shared/NCModal"
import { useDeleteUserRole } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"

const UserAccessManagementList = () => {
  const nav = useNavigate()
  const {t} = useTranslation();
  const mobileTheme = useTheme()
  const isMediumScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null);

  const deleteUserRoleMutation = useDeleteUserRole();

  const { isLoading, data } = useGetUserRoleListForAccessManagement(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,

      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: requestData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const navigateToPermissionPage = id => {
    nav(`/user-access-management/${id}`)
  }

  const deleteUserRole = selectedUserRole => {
    deleteUserRoleMutation.mutate(selectedUserRole, {
      onSuccess: () => {
        toast.success(t("user_role.delete_success"))
        queryClient.invalidateQueries(["getUserRoleListForAccessManagement"])
        setShowDeleteModel(false)
      }
    })
  }

  const getDetailsByName = cell => {
    return (
      <div onClick={navigateToPermissionPage.bind(this, cell.id)}>
        <NCText className={"clickable-text"}>{cell?.user_details?.name}</NCText>
      </div>
    )
  }

  const getDetailsByID = cell => {
    return (
      <div onClick={navigateToPermissionPage.bind(this, cell.id)}>
        <NCText className={"clickable-text"}>{cell?.id}</NCText>
      </div>
    )
  }

  const getUserType = cell => {
    return (
      <div>
        <NCText>{USER_TYPE[cell?.user_details?.user_type]}</NCText>
      </div>
    )
  }

  const getEmail = cell => {
    return (
      <div>
        <NCText>{cell?.user_details?.email}</NCText>
      </div>
    )
  }

  const getRole = cell => {
    return (
      <div>
        <NCText>{cell?.role}</NCText>
      </div>
    )
  }

  const handleDeleteClick = (cell) => {
    setSelectedRole(cell.id);
    setShowDeleteModel(true);
  }


  // eslint-disable-next-line no-unused-vars
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => handleDeleteClick(cell)}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => nav(`/user-access-management/${cell.id}`)}>
            <EditIcon />
          </IconButton>
        </Box>
      </div>
    )
  }


  let columns = [
    {
      Header: t("list.id"),
      id: "id",
      accessor: cell => getDetailsByID(cell),
      disableSortBy: true
    },
    {
      Header: t("list.name"),
      id: "name",
      accessor: cell => getDetailsByName(cell),
      disableSortBy: true
    },
    {
      Header: t("list.user_type"),
      id: "user_type",
      disableSortBy: true,
      accessor: cell => getUserType(cell)
    },
    {
      Header: t("list.email"),
      id: "email",
      disableSortBy: true,
      accessor: cell => getEmail(cell)
    },
    {
      Header: t("list.role"),
      id: "role",
      disableSortBy: true,
      accessor: cell => getRole(cell)
    },
    {
      Header: "",
      id: "action",
      disableSortBy: true,
      accessor: cell => getActionColumn(cell)
    }
  ]

  const headerContent = (
    <>
      <Grid item>
        <NCInput
          value={query}
          onChange={e => {
            setPage(1)
            setOrdering(null)
            setQuery(e.target.value)
          }}
          placeholder={t("common.search_here")}
          startIcon={SearchIcon}
          className="rounded"
        />
      </Grid>
      <Grid item style={{ paddingLeft: "10px" }}>
        <NCButton
          variant="contained"
          color="primary"
          endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
          onClick={() => nav("/user-access-management/add-user-role")}
        >
          {t("user_role.add_role")}
        </NCButton>
      </Grid>
    </>
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          <NCText variant={"h5"} style={{ marginRight: "10px" }}>
            {t("user_role.heading")}
          </NCText>
        </Box>

        <Box marginLeft={"5px"} style={{ display: "flex" }}>
          {!isMediumScreen && headerContent}
          <NotificationAlert />
        </Box>
      </Grid>
      {isMediumScreen && <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"space-between"} style={{ flexWrap: "wrap", gap: "10px" }}>
          {headerContent}
        </Box>
      </Grid>}
      <Grid item xs={12} justifyContent="space-between">
        <NCTable
          columns={columns}
          data={requestData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
      <NCModal
        open={showDeleteModel}
        title={t("common.delete")}
        text={t("user_role.confirm_delete")}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => deleteUserRole(selectedRole)}
            style={{ width: "auto" }}
          >
            {t("common.yes")}
          </NCButton>
        }
      />
    </Grid>
  )
}

export default UserAccessManagementList
