import { Box, Grid, makeStyles } from "@material-ui/core"
import NCText from "../../shared/NCText"
import { useEffect, useState } from "react"
import { TABLE_CONFIG } from "../../libs/config"
import { useGetRideDetails } from "../../services/queries"
import { formatAMPM, isDateBefore } from "../../libs/utils"
import { rideStatus } from "../../libs/const"
import AssignDriverModal from "./assign-driver-modal"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { DriverEditIcon } from "../../libs/icons"
import NCTable from "../../shared/table/NCTable"
import NCCheckbox from "../../shared/NCCheckbox"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  container: {
    border: "1px solid #F3F4F6"
  },
  headingContainer: {
    backgroundColor: "#F9FAFB",
    marginBottom: "10px"
  },
  heading: {
    fontSize: "26px",
    padding: "10px 0px"
  },
  requestId: {
    fontWeight: 700
  },
  status: {
    fontSize: "18px",
    fontWeight: 600
  },
  statusText: {
    color: "#7D7D7D",
    fontSize: "14px"
  },
  boxContainer: {
    border: "1px solid #D1D5DB"
  },
  circle: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 10px"
  },
  yColor: {
    backgroundColor: "#F9B50B"
  },
  gColor: {
    backgroundColor: "#4CAF50"
  },
  rightContainerTitle: {
    color: "#2E3B36",
    fontSize: "24px",
    padding: "15px 20px",
    borderBottom: "1px solid #E3E3E3"
  }
}))

const UnscheduleTrip = ({ startTime, endTime, search }) => {
  const cls = useStyles()

  const {t} = useTranslation();

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])

  const [tripChecked, setTripChecked] = useState({})

  const [assignDriverModal, setAssignDriverModal] = useState(false)

  const {
    isLoading,
    data,
    refetch: tripsRefetch
  } = useGetRideDetails(
    {
      page,
      search: search,
      pageSize,
      ordering,
      start_time: startTime,
      end_time: endTime,
      unscheduled: true
    },
    {
      enabled: !!(startTime && endTime)
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: driverData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const onHandleCheck = (status, id) => {
    setTripChecked({ ...tripChecked, [id]: status })
  }

  const columns = [
    {
      Header: t("list.trip_id"),
      id: "id",
      accessor: cell => cell,
      Cell: ({ value }) => (
        <Box pt={1} pb={1}>
          <NCCheckbox
            checked={tripChecked[value?.id]}
            color="primary"
            size="small"
            inputProps={{ "aria-label": "controlled" }}
            onChange={event => {
              onHandleCheck(event.target.checked, value?.id)
            }}
          />
          <NCText style={{ display: "inline" }}>{value?.id}</NCText>
        </Box>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.start_address"),
      id: "start_address",
      disableSortBy: true,
      accessor: cell => cell.pickup_address?.address
    },
    {
      Header: t("list.start_time"),
      id: "arrival",
      accessor: cell => cell.pickup_time,
      disableSortBy: true,
      Cell: ({ value }) => (value ? formatAMPM(value).toUpperCase() : "-")
    },
    {
      Header: t("list.end_address"),
      id: "end_address",
      disableSortBy: true,
      accessor: cell => cell.drop_off_address?.address
    },
    // {
    //   Header: "End Date/Time",
    //   id: "dropoff",
    //   disableSortBy: true,
    //   accessor: cell => cell.timing_details.dropped_time,
    //   Cell: ({ value }) =>
    //     value ? formatDate(value) + " - " + formatAMPM(value) : "-"
    // },
    {
      Header: t("list.status"),
      id: "status",
      disableSortBy: true,
      accessor: cell => rideStatus[cell.status]
    }
  ]

  const anyChecked = Object.values(tripChecked).some(value => value === true)

  useEffect(() => {
    setPage(1)
  }, [search])

  useEffect(() => {
    setTripChecked({})
  }, [startTime])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2} pl={1} pr={1} mt={2} className={cls.headingContainer}>
          <Box
            pt={1}
            pb={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <NCText>{t("trip-log.trips")}</NCText>
            <Box>
              <NCButton
                variant="contained"
                color="primary"
                endIcon={
                  <NCSvgIcon component={DriverEditIcon} inheritViewBox />
                }
                disabled={!(driverData && driverData?.length > 0 && anyChecked) || isDateBefore(startTime)}
                onClick={() => {
                  setAssignDriverModal(true)
                }}
              >
                {t("change_driver.assign_driver")}
              </NCButton>
            </Box>
          </Box>
        </Box>
        <NCTable
          columns={columns}
          data={driverData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
      </Grid>
      {assignDriverModal && (
        <AssignDriverModal
          tripsRefetch={tripsRefetch}
          startTime={startTime}
          trips={tripChecked}
          modalState={assignDriverModal}
          handleClose={() => setAssignDriverModal(false)}
        />
      )}
    </Grid>
  )
}

export default UnscheduleTrip
