import { Avatar, Box, ListItemAvatar, makeStyles } from "@material-ui/core"
import { Chat, MessageList } from "@pubnub/react-chat-components"
import { useContext, useEffect, useRef, useState } from "react"

import AttachmentIcon from "../../assets/icons/uploadAttachment.svg"
import EmojiIcon from "../../assets/icons/emoji.svg"
import ImagesUploadIcon from "../../assets/icons/uploadImages.svg"
import NCSendMessage from "../../shared/NCSendMessage"
import { usePubNub } from "pubnub-react"
import FileSaver from "file-saver"
import { DownloadIcon } from "../../libs/icons"
import EmojiPicker from "emoji-picker-react"
import {
  createDirectChannel,
  downloadUploadedFile,
  publishFile,
  publishMessage,
  setMembership,
  showTime
} from "../../libs/pubnub"
import { NOAH_CARES, STORAGE } from "../../libs/storage"
import { ChatContext } from "./ChatProvider"
import NCText from "../../shared/NCText"
import WebCall from "./web-call"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  chatContainer: {
    height: "calc(100vh - 202px)",
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - (${theme.mixins.toolbar.minHeight}px + 227px))`
    }
  },
  sendText: {
    backgroundColor: "rgba(30, 170, 196, 1)",
    fontSize: "16px",
    color: "white",
    padding: "5px 10px",
    borderRadius: "5px",
    marginRight: "10px"
  },
  receiveText: {
    backgroundColor: "rgba(209, 252, 245, 1)",
    fontSize: "16px",
    color: "black",
    padding: "5px 10px",
    borderRadius: "5px"
  },
  name: {
    fontSize: "32px",
    fontWeight: 700,
    color: "rgba(16, 101, 142, 1)"
  },
  heading: {
    backgroundColor: "white",
    height: "60px",
    padding: "0px 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 8px",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "80px",
      marginBottom: "5px",
      gap: "10px"
    }
  },
  messageInputContainer: {
    display: "flex",
    alignItems: "center"
  },
  messageInput: {
    flex: 1,
    margin: "0px 10px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5px",
      paddingBottom: "5px"
    }
  },
  icons: {
    padding: "10px 10px",
    cursor: "pointer",
    width: "24px",
    height: "24px"
  },
  messageBox: {
    width: "100%"
  },
  inputFile: {
    width: "0px",
    height: "0px",
    visibility: "none"
  },
  download: {
    cursor: "pointer",
    display: "flex",
    gap: "10px"
  },
  image: {
    maxWidth: "400px",
    maxHeight: "400px,",
    [theme.breakpoints.down("md")]: {
      maxWidth: "200px",
      maxHeight: "200px,"
    }
  },
  fileName: {
    wordBreak: "break-word"
  },
  emojipicker: {
    transform: "scale(0.7)"
  },
  emojiContainer: {
    position: "absolute",
    zIndex: 100,
    bottom: "58px",
    right: "42px"
  },
  avatar: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "42px"
    }
  },
  messageDateTimePub: {
    fontSize: "12px",
    marginBottom: "5px",
    textAlign: "right",
    marginRight: "10px",
    color: "#777"
  },
  messageDateTime: {
    fontSize: "12px",
    marginBottom: "5px",
    textAlign: "left",
    marginLeft: "10px",
    color: "#777"
  }
}))

const UserMessaging = () => {
  const cls = useStyles()

  const { t } = useTranslation()

  const chatUser = useContext(ChatContext)
  const selectedUser = chatUser.selectedUser
  const fetchUserList = chatUser.fetchUserList

  const TEXT = "text"
  const DOCUMENT = "document"
  const IMAGE = "image"

  const [message, setMessage] = useState("")
  const [uploadType, setUploadType] = useState(null)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const pubnub = usePubNub()

  const uuid = pubnub.getUUID()

  const inputRef = useRef()
  const inputImageRef = useRef()

  const isPublisher = props => {
    return props?.message?.uuid === uuid || props?.message?.publisher === uuid
  }

  const downloadFile = async (channel, id, name) => {
    const file = await downloadUploadedFile(pubnub, {
      channel,
      id,
      name
    })
    FileSaver.saveAs(file.data)
  }

  const onEmojiClick = event => {
    setMessage(message + event.emoji)
    setShowEmojiPicker(false)
  }

  const handleChange = e => {
    const value = e.target.value
    setMessage(value)
  }

  const handleSendMessageOnEnter = e => {
    if (e.key === "Enter") {
      handleSendMessage()
    }
  }

  const handleSendMessage = async () => {
    if (!message) {
      return
    }
    const userData = STORAGE.getItem(NOAH_CARES).user

    const textMessage = message
    const id = selectedUser?.id
    const name = selectedUser?.name
    const meesagePayload = {
      text: textMessage,
      createdAt: new Date(),
      message: {
        type: TEXT
      },
      type: TEXT,
      user: userData,
      _id: new Date().toString()
    }

    const metaInfo = {
      user: {
        id: id,
        name: name,
        profile: true
      }
    }

    await publishMessage(pubnub, meesagePayload, id, metaInfo)

    const customData = generateCustomData(textMessage)
    setMessage("")
    await createDirectChannel(pubnub, uuid, selectedUser?.uuid, customData)
    updateMembershipAndFetchLatestList(pubnub, selectedUser?.id)
  }

  const generateCustomData = lastMessage => {
    const userData = STORAGE.getItem(NOAH_CARES).user
    const updatedAt = new Date()
    const message = {
      text: lastMessage,
      user: userData,
      _id: new Date().toString(),
      createdAt: updatedAt.toString()
    }
    const customData = {
      custom: {
        [uuid]: getCustomData(
          userData.name,
          userData.avatar,
          updatedAt,
          message
        ),
        [selectedUser?.uuid]: getCustomData(
          selectedUser.name,
          selectedUser.avatar,
          updatedAt,
          message
        )
      }
    }
    return customData
  }

  const getCustomData = (name, avatar, updatedAt, lastMessage) => {
    return JSON.stringify({
      name: name || "",
      avatar: avatar || "",
      updatedAt: updatedAt || "",
      lastMessage
    })
  }

  const sendFile = async e => {
    const userData = STORAGE.getItem(NOAH_CARES).user
    const file = e?.target?.files?.[0]
    if (file) {
      const message = {
        type: uploadType,
        user: userData,
        _id: new Date().toString(),
        createdAt: new Date().toString().toString()
      }
      const metaInfo = {
        user: {
          id: selectedUser?.id,
          name: selectedUser.name,
          profile: true
        }
      }
      publishFile(pubnub, file, selectedUser?.id, message, metaInfo)
      const customData = generateCustomData(selectedUser?.lastMessage)
      await createDirectChannel(pubnub, uuid, selectedUser?.uuid, customData)
      updateMembershipAndFetchLatestList(pubnub, selectedUser?.id)
    }
  }

  useEffect(() => {
    if (selectedUser?.uuid) {
      updateMembershipAndFetchLatestList(pubnub, selectedUser?.id)
    }
  }, [selectedUser?.uuid])

  const updateMembershipAndFetchLatestList = async (pubnub, id) => {
    await setMembership(pubnub, id)
    fetchUserList()
  }

  const messageRenderer = props => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        style={{ width: "100%" }}
        flexDirection={isPublisher(props) ? "row-reverse" : "row"}
      >
        <ListItemAvatar className={cls.avatar}>
          <Avatar
            src={isPublisher(props) ? null : selectedUser?.avatar}
          ></Avatar>
        </ListItemAvatar>
        {(props?.message?.message?.type === TEXT ||
          props?.message?.message?.message?.type === TEXT) && (
          <div>
            <div>
              <NCText
                className={
                  isPublisher(props)
                    ? cls.messageDateTimePub
                    : cls.messageDateTime
                }
              >
                {" "}
                {showTime(props?.message?.message?.createdAt)}
              </NCText>
            </div>
            <span>
              <NCText
                className={isPublisher(props) ? cls.sendText : cls.receiveText}
              >
                {props?.message?.message?.text}
              </NCText>
            </span>
          </div>
        )}
        {props?.message?.message?.message?.type === DOCUMENT && (
          <span
            onClick={() => {
              downloadFile(
                props?.message?.channel,
                props?.message?.message?.file?.id,
                props?.message?.message?.file?.name
              )
            }}
            className={
              isPublisher(props)
                ? [cls.sendText, cls.download].join(" ")
                : [cls.receiveText, cls.download].join(" ")
            }
          >
            <span className={cls.fileName}>
              {props?.message?.message?.file?.name}
            </span>
            <DownloadIcon />
          </span>
        )}
        {props?.message?.message?.message?.type === IMAGE && (
          <span
            style={{
              backgroundColor: "transparent"
            }}
            className={
              isPublisher(props)
                ? [cls.sendText, cls.download].join(" ")
                : [cls.receiveText, cls.download].join(" ")
            }
          >
            <img
              src={props?.message?.message?.file?.url}
              className={cls.image}
            />
          </span>
        )}
      </Box>
    )
  }

  return (
    <div className={cls.chatContainer}>
      <div className={cls.emojiContainer}>
        {showEmojiPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
      </div>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={cls.heading}
      >
        <Box display={"flex"}>
          <ListItemAvatar className={cls.avatar}>
            <Avatar src={selectedUser?.avatar}></Avatar>
          </ListItemAvatar>
          <NCText>
            <span className={cls.name}>{selectedUser?.name || ""}</span>
          </NCText>
        </Box>
        <WebCall uuid={selectedUser.uuid} />
      </Box>
      <Chat currentChannel={selectedUser?.id}>
        <MessageList
          fetchMessages={100}
          messageRenderer={messageRenderer}
          enableReactions={true}
        />
      </Chat>

      <Chat currentChannel={selectedUser?.id}>
        <div className={cls.messageInputContainer}>
          <div className={cls.messageInput}>
            {/* <MessageInput style={{backgroundColor:"white"}} 
            placeholder="Type a message here..."/> */}
            <NCSendMessage
              rows={3}
              className={cls.messageBox}
              onKeyUp={handleSendMessageOnEnter}
              placeholder={t("chat.type_message_here")}
              onSend={handleSendMessage}
              onChange={handleChange}
              value={message}
            />
          </div>
          <div>
            <input
              ref={inputRef}
              type="file"
              className={cls.inputFile}
              onChange={sendFile}
            />
            <input
              ref={inputImageRef}
              type="file"
              accept="image/*"
              className={cls.inputFile}
              onChange={sendFile}
            />
            <img
              src={AttachmentIcon}
              className={cls.icons}
              onClick={() => {
                setUploadType(DOCUMENT)
                inputRef.current.click()
              }}
            />
            <img
              src={ImagesUploadIcon}
              className={cls.icons}
              onClick={() => {
                setUploadType(IMAGE)
                inputImageRef.current.click()
              }}
            />
            <img
              src={EmojiIcon}
              className={cls.icons}
              onClick={() => {
                setShowEmojiPicker(picker => !picker)
              }}
            />
          </div>
        </div>
      </Chat>
    </div>
  )
}

export default UserMessaging
