import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCInput from "../../shared/NCInput"
import { useEffect, useState } from "react"
import { SEARCH_CONFIG } from "../../libs/config"
import useDebounce from "../../libs/hooks/Debounce"
import { SearchIcon } from "../../libs/icons"
import SimpleMenu from "../../components/ExportMenu"
import { useGetDriverList } from "../../services/queries"
import NCCalendarButton from "../../shared/NCCalendarButton"
import ScheduledTrip from "./scheduled-trip"
import UnscheduledTrip from "./unscheduled-trip"
import { useTranslation } from "react-i18next"
import NotificationAlert from "../../components/notification/NotificationAlert"

const useStyles = makeStyles(() => ({
  toggleButton: {
    borderRadius: "20px",
    fontSize: "12px"
  }
}))

const TripLog = () => {
  const cls = useStyles()

  const {t} = useTranslation();

  const SCHEDULE = t("trip-log.schedule_cap")
  const UNSCHEDULE = t("trip-log.unschedule_cap")
  const [currentView, setCurrentView] = useState(SCHEDULE)

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const isXsScreen = useMediaQuery(mobileTheme.breakpoints.down("xs"))

  const [query, setQuery] = useState("")
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const [driverList, setDriverList] = useState([])

  const [selectedDriver, setSelectedDriver] = useState(null)

  const today = new Date()

  const [startTime, setSelectedStartTime] = useState(
    new Date(today.setHours(0, 0, 0, 0))
  )
  const [endTime, setSelectedEndTime] = useState(
    new Date(today.setHours(23, 59, 59, 999))
  )

  const { data: driverData } = useGetDriverList(null, 1, 100)

  const handleToggleView = view => {
    setCurrentView(view)
  }

  useEffect(() => {
    const driverList = driverData?.data?.data?.results || []

    const driverListForSelection = driverList
      ?.map(driver => ({
        key: driver?.driver?.user,
        name: driver?.driver?.name
      }))
      ?.sort((a, b) => a.name.localeCompare(b.name))

    setDriverList(driverListForSelection)
  }, [driverData])

  const date = (
    <Box>
      <NCCalendarButton
        selectedDate={selectedDate => {
          if (selectedDate) {
            const date = new Date(selectedDate)
            const startTime = new Date(date.setHours(0, 0, 0, 0))
            const endTime = new Date(date.setHours(23, 59, 59, 999))
            setSelectedStartTime(startTime)
            setSelectedEndTime(endTime)
          }
        }}
      />
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} sm={6} md={4}>
              <NCText variant={"h4"}>{t("trip-log.heading")}</NCText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              style={{
                marginTop: isSmallScreen ? "10px" : "0px"
              }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <Box display={"flex"}>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                  <NotificationAlert />
                  </Box>
                </Grid>
                {isXsScreen && currentView === SCHEDULE && date}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"space-between"} gridGap={"10px"}>
          <ButtonGroup disableElevation>
            <Button
              className={cls.toggleButton}
              variant={currentView === SCHEDULE ? "contained" : "outlined"}
              onClick={handleToggleView.bind(this, SCHEDULE)}
              color={currentView === SCHEDULE ? "primary" : ""}
            >
              {t("trip-log.scheduled")}
            </Button>
            <Button
              className={cls.toggleButton}
              variant={currentView === UNSCHEDULE ? "contained" : "outlined"}
              onClick={handleToggleView.bind(this, UNSCHEDULE)}
              color={currentView === UNSCHEDULE ? "primary" : ""}
            >
              {t("trip-log.unscheduled")}
            </Button>
          </ButtonGroup>
          <Box display={"flex"} gridGap={"20px"}>
            {currentView === SCHEDULE && (
              <Box>
                <SimpleMenu
                  items={driverList}
                  selItem={driverList?.find(item => item.key === selectedDriver)}
                  itemAction={item => {
                    setSelectedDriver(item.key)
                  }}
                  asDropdown={true}
                  heading={t("trip-log.no_driver_selected")}
                />
              </Box>
            )}
            {!isXsScreen && date}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {currentView === SCHEDULE ? (
          <ScheduledTrip
            startTime={startTime}
            endTime={endTime}
            driver={selectedDriver}
            search={debouncedSearchTerm}
          />
        ) : (
          <UnscheduledTrip
            startTime={startTime}
            endTime={endTime}
            search={debouncedSearchTerm}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default TripLog
