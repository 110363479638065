import React, { useEffect, useState } from "react"
import { Calendar, Views, dateFnsLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "moment-timezone"
import { useMemo } from "react"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfDay from "date-fns/startOfDay"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import "./NCCalendar.scss"
import { useTranslation } from "react-i18next"
const locales = {
  "en-GB": require("date-fns/locale/en-IN")
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfDay,
  startOfWeek,
  getDay,
  locales
})

const NCCalendar = ({ activities, getInterval }) => {
  const [events, setEvents] = useState([])
  const [startDate, setStartDate] = useState(new Date())

  const { t } = useTranslation();

  useEffect(() => {
    if (activities) {
      const activitiesWithTitle = activities.map(activity => {
        if (activity.trip) {
          activity.title = `${t("appointments.ride_planned_for")} ${activity?.client_name} ${t("appointments.by_driver")} ${activity?.driver?.name}.`
        } else {
          activity.title = `${t("appointments.driver")} ${activity?.driver_details?.name} ${t("appointments.availability")}.`
        }
        activity.start_time = activity.start_time.includes("Z")
          ? activity.start_time
          : `${activity.start_time}Z`
        activity.end_time = activity.end_time.includes("Z")
          ? activity.end_time
          : `${activity.end_time}Z`
        return activity
      })
      setEvents(activitiesWithTitle)
    }
  }, [activities])
  const { views } = useMemo(
    () => ({
      views: [Views.DAY, Views.WEEK, Views.MONTH]
    }),
    []
  )
  const onNavigate = (date, view) => {
    let stringDate = format(new Date(date), "yyyy-MM-dd")
    setStartDate(stringDate)
    getInterval(stringDate, view)
  }

  const onView = view => {
    let stringDate = format(new Date(startDate), "yyyy-MM-dd")
    getInterval(stringDate, view)
  }

  const eventPropGetter = data => {
    const newStyle = {
      border: "0px",
      fontSize: "10px"
    }
    if (data.trip) {
      newStyle.backgroundColor = "#3174ad"
    } else {
      newStyle.backgroundColor = "#D1FCF5"
      newStyle.color = "black"
    }
    return {
      style: newStyle
    }
  }

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor={events => {
          return new Date(events.start_time)
        }}
        endAccessor={events => {
          return new Date(events.end_time)
        }}
        defaultView={Views.DAY}
        style={{
          height: "650px",
          width: "auto",
          marginTop: "15px",
          marginBottom: "15px"
        }}
        //step={60}
        views={views}
        eventPropGetter={eventPropGetter}
        onView={onView}
        onNavigate={onNavigate}
      />
    </div>
  )
}

export default NCCalendar
