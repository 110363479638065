import { Box, Grid, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import NCText from "../../shared/NCText"
import NotificationAlert from "../notification/NotificationAlert"
import TopNavBar from "../TopNavBar"
import FormSection from "../FormSection"
import NCCheckbox from "../../shared/NCCheckbox"
import {
  useGetFeatureList,
  useGetUserListForAccessManagement
} from "../../services/queries"
import NCLoader from "../../shared/NCLoader"
import { useNavigate } from "react-router-dom"
import NCButton from "../../shared/NCButton"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import FormField from "../FormField"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useCreateRole } from "../../services/mutations"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  titleSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  checkedItems: {
    fontSize: "14px",
    fontWeight: "400"
  },
  checklistItems: {
    fontSize: "14px",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  rideContainer: {
    marginBottom: "10px"
  },
  container: {
    "& .MuiCheckbox-root": {
      paddingRight: "3px"
    }
  },
}))

const UserAccessManagementAddRole = () => {
  const queryClient = useQueryClient()
  const createRoleRequest = useCreateRole();

  const {t} = useTranslation();

  const cls = useStyles()
  const nav = useNavigate()

  const [search, setSearch] = useState(null)
  const [userid, setUserId] = useState(null)

  const { data: userData, isLoading: userLoading } =
    useGetUserListForAccessManagement(null, search)

  const { isLoading: allFeaturesLoading, data: allFeatures } =
    useGetFeatureList(userid, {
      enabled: !!userid
    })

  const formik = useFormik({
    initialValues: {
      role: "",
      user: null,
      selectedFeatures: []
    },
    validationSchema: Yup.object().shape({
      role: Yup.string().required(t("user_role.error.role_required")),
      user: Yup.string().required(t("user_role.error.user_required"))
    }),
    onSubmit: values => {

      const featureIds = values.selectedFeatures
        ?.filter(data => data.checked)
        ?.map(data => data.id);

      const payload = {
        user: values.user,
        role: values.role,
        features: featureIds
      }

      createRoleRequest.mutate(payload, {
        onSuccess: async () => {
          toast.success(t("user_role.create_success"))
          queryClient.invalidateQueries(["getNotificationData"])
          queryClient.refetchQueries(["getNotificationSummary"])
          nav("/user-access-management")
        }
      })
    }
  })

  useEffect(() => {
    const features = allFeatures?.data?.data?.results || []
    const markFeature = features?.map(feat => {
      return {
        ...feat,
        checked: true
      }
    })
    formik.setFieldValue("selectedFeatures", markFeature)
  }, [allFeatures])

  useEffect(() => {
    const userId = formik?.values?.user
    if (userId && typeof userId === "number") {
      setUserId(userId)
    }
  }, [formik?.values?.user])

  const title = (
    <Grid item className={cls.titleSection}>
      {t("user_role.role_assignment")}
    </Grid>
  )

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          <NCText variant={"h5"} style={{ marginRight: "10px" }}>
            {t("user_role.user_access_management")}
          </NCText>
        </Box>

        <Box marginLeft={"5px"} style={{ display: "flex" }}>
          <NotificationAlert />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12} container className={cls.container}>
        <Grid item xs={12} className={cls.rideContainer}>
          <FormikProvider value={formik}>
            <Form className={cls.form} onSubmit={formik.handleSubmit}>
              <FormSection title={title}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormField
                      label={t("user_role.role_name")}
                      name={"role"}
                      required={true}
                      error={formik?.errors?.role}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField
                      as={NCAutocomplete}
                      options={userData?.data?.data?.results || []}
                      label={t("user_role.select_user")}
                      name={"user"}
                      onChange={v => {
                        formik.setFieldValue("user", v)
                      }}
                      loading={userLoading}
                      onSearch={v => setSearch(v)}
                      required={true}
                      error={formik?.errors?.user}
                    />
                  </Grid>
                </Grid>
                <FormSection
                  title={
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {t("user_role.permissions")}
                    </span>
                  }
                  customBgColor="#E5E7EB"
                  subSection={true}
                >
                  <Grid container spacing={5}>
                    <Grid
                      item
                      container
                      xs={12}
                      className={cls.checklistItems}
                      style={{ gap: "20px" }}
                    >
                      {formik.values.selectedFeatures.map((feature, i) => (
                        <Grid item md={3} xs={12}>
                          <NCText className={cls.checkedItems}>
                            <NCCheckbox
                              checked={feature.checked}
                              key={i}
                              color="primary"
                              name={`selectedFeatures[${i}]`}
                              size="small"
                              onChange={() => {
                                formik.setFieldValue(`selectedFeatures[${i}]`, {
                                  ...feature,
                                  checked: !feature.checked
                                })
                              }}
                            />
                            {feature.feature}
                          </NCText>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </FormSection>
              </FormSection>
              <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <NCButton
                        onClick={() => nav("/user-access-management")}
                        variant="outlined"
                        color="primary"
                      >
                        {t("common.cancel")}
                      </NCButton>
                    </Grid>
                    <Grid item>
                      <NCButton
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t("common.save")}
                      </NCButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>
      {(allFeaturesLoading || userLoading) && <NCLoader open={true} />}
    </Grid>
  )
}

export default UserAccessManagementAddRole
