import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@material-ui/core"
import NCTheme from "./styles/NCTheme"
import App from "./App"
import "./styles/index.scss"
import "./i18n"
import ReactGA from "react-ga4"
import { GA_MEASUREMENT_ID } from "./libs/const"

ReactGA.initialize(GA_MEASUREMENT_ID);

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <ThemeProvider theme={NCTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
)
