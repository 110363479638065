import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Modal } from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { CloseIconSquare } from "../../libs/icons"

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "70%",
    maxWidth: "800px",
    maxHeight: "750px",
    backgroundColor: "white",
    boxShadow: 24,
    padding: "10px",
    borderRadius: "5px",
    outline: "none",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    width: "90%",
    height: "90%",
    objectFit: "contain"
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  close: {
    width: "20px",
    height: "20px"
  },
  divider: {
    width: "100%",
    border: "1px solid #E0E0E0"
  }
}))

const InspectionImageModal = props => {
  const cls = useStyles()
  const item = props?.image

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={`${cls.root} ${props.className}`}>
        <Box className={cls.header} onClick={props.onClose}>
          <NCText variant="h5">Image</NCText>
          <Box onClick={props.onClose}>
            <NCSvgIcon
              component={CloseIconSquare}
              inheritViewBox={true}
              className={cls.close}
            />
          </Box>
        </Box>
        <hr className={cls.divider} />
        <img className={cls.img} src={item.image} />
      </Box>
    </Modal>
  )
}

export default InspectionImageModal
