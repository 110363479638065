import { useGetTermsAndConditions } from "../../services/queries"
import NCLoader from "../../shared/NCLoader"
import PrivacyLayout from "./PrivacyLayout"

const TermsandConditions = () => {
  const { isLoading, data } = useGetTermsAndConditions()
  const pageContent = data?.data?.data?.results?.[0]?.body || null
  return (
    <PrivacyLayout title="">
      {pageContent ? (
        <div dangerouslySetInnerHTML={{ __html: pageContent }} />
      ) : null}
      <NCLoader open={isLoading} />
    </PrivacyLayout>
  )
}
export default TermsandConditions
