import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Grid } from "@material-ui/core"
import { useAddGuardian, useUpdateGuardian } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import FormField from "../FormField"
import PhoneNumber from "../PhoneNumber"
import { useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"

const AddGuardianModal = ({
  clientId,
  modalState = false,
  handleClose,
  isEdit = false,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const addGuardianRequest = isEdit
    ? useUpdateGuardian(data.id)
    : useAddGuardian()
  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      phone: data.phone || ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("client.error.first_name")),
      last_name: Yup.string().required(t("client.error.last_name")),
      email: Yup.string().required(t("client.error.email_required")),
      phone: Yup.string().required(t("client.error.phone"))
    }),
    enableReinitialize: true,
    onSubmit(values) {
      addGuardianRequest.mutate(
        { ...values, client: clientId, id: data.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getGuardianList"])
            queryClient.invalidateQueries(["getClientAuditLog"])
            isEdit
              ? toast.success(t("client.guardian_update"))
              : toast.success(t("client.guardian_add"))
            handleClose()
            !isEdit && formik.resetForm()
          }
        }
      )
    }
  })
  const guardianModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.first_name")}
              name={"first_name"}
              required={true}
              error={formik?.errors?.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.last_name")}
              name={"last_name"}
              required={true}
              error={formik?.errors?.last_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={t("common.email_address")}
              name={"email"}
              required={true}
              disabled={isEdit}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              as={PhoneNumber}
              label={t("common.phone_number")}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )

  return (
    <NCModal
      open={modalState}
      title={isEdit ? t("client.update_guardian") : t("client.add_guardian")}
      text={guardianModal}
      onClose={() => handleClose()}
      submitButton={
        <NCButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={formik.handleSubmit}
          style={{ width: "auto" }}
        >
          {t("common.submit")}
        </NCButton>
      }
    />
  )
}
export default AddGuardianModal
