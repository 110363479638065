import { Box, Grid, makeStyles } from "@material-ui/core"
import NCText from "../../shared/NCText"
import { formatAMPM, formatDate } from "../../libs/utils"

const useStyles = makeStyles(() => ({
  detail: {
    whiteSpace: "normal"
  }
}))

const NotificationItem = ({ detail }) => {
  const cls = useStyles()
  return (
    <Box
      className={
        detail?.is_seen
          ? "notification-item seen"
          : "cursor-pointer notification-item"
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <NCText>
              <div className={"fw-bold"}>{detail.message}</div>
            </NCText>
            <Box bgcolor={"#effdff"} p={0.5}>
              <NCText>
                {formatDate(detail.created)} / {formatAMPM(detail.created)}
              </NCText>
            </Box>
          </Box>
          <Box mt={"-10px"}>
            {detail?.is_seen ? "(Driver changed successfully)" : ""}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <NCText className={"fw-bold d-inline"} variant={"body2"}>
            Note From Driver -
          </NCText>
          <NCText
            variant={"caption"}
            color={"textSecondary"}
            className={cls.detail}
          >
            {" "}
            {detail?.data?.note}
          </NCText>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NotificationItem
