import React, { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import NCText from "../../shared/NCText"
import {
  Grid,
  Box,
  IconButton,
  makeStyles,
  Button,
  ButtonGroup,
  useTheme,
  useMediaQuery
} from "@material-ui/core"
import { useGetOverview, useGetOverviewFile } from "../../services/queries"
import {
  downloadFileFromResponse,
  formatDate,
  loggedInUser
} from "../../libs/utils"
import { REQUEST_STATUS, SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import NCInput from "../../shared/NCInput"
import ExportMenu from "../../components/ExportMenu"
import { AddIcon, DetailIcon, SearchIcon } from "../../libs/icons"
import Filter from "../../shared/table/Filter"
import NCLoader from "../../shared/NCLoader"
import NCTableStatus from "../../shared/table/NCTableStatus"
import { useNavigate } from "react-router"
import useDebounce from "../../libs/hooks/Debounce"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { useClientRequestDecline } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import ApproveRequestModal from "../../components/request/ApproveRequestModal"
import NCModal from "../../shared/NCModal"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"

const useStyle = makeStyles({
  approve_button: {
    borderRadius: "20px",
    backgroundColor: "#1DB954",
    padding: "7px 15px",
    marginRight: "5px",
    color: "#FFFFFF",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#40a840"
    }
  },
  decline_button: {
    color: "#E22134",
    marginLeft: "5px",
    padding: "7px 15px",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  }
})

const OverviewList = () => {
  const { t } = useTranslation()
  const cls = useStyle()
  const nav = useNavigate()
  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  //const [showBox,setShowBox]=useState(false)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [selectedTab, setSelectedTab] = useState(1)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const [downloadType, setDownloadType] = useState()
  const [selectedRequest, setSelectedRequest] = useState()
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const user = loggedInUser()
  const queryClient = useQueryClient()
  const { isLoading, data, refetch: refetchOverview } = useGetOverview(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,

      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : user.user_type === 4
          ? selectedTab
          : null
    }
  )
  const { refetch } = useGetOverviewFile(
    downloadType,
    query,
    { ordering },
    {
      enabled: false
    }
  )

  const declineRequest = useClientRequestDecline()

  const handleApproveClick = cell => {
    setSelectedRequest(cell)
    setShowApproveModal(true)
  }

  const handleDeclineClick = cell => {
    setSelectedRequest(cell)
    setShowDeleteModel(true)
  }
  const declineRequestMethod = () => {
    declineRequest.mutate(selectedRequest.id, {
      onSuccess: () => {
        toast.success(t("requests.decline"))
        queryClient.refetchQueries(["getOverviewList"])
        refetchOverview();
      }
    })
  }

  const {
    next,
    previous,
    total_pages,
    results: requestData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <Filter
        options={[
          { key: "1", name: t("requests.pending") },
          { key: "2", name: t("requests.approved") },
          { key: "3", name: t("requests.declined") },
          { key: "4", name: t("requests.on_hold") }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton onClick={() => nav(`/overview/${cell.id}`)}>
          <DetailIcon />
        </IconButton>
      </div>
    )
  }
  const getDetailsByName = cell => {
    return (
      <div onClick={() => nav(`/overview/${cell.id}`)}>
        <NCText className={"clickable-text"}>{cell?.client_name}</NCText>
      </div>
    )
  }

  const getNewActionColumn = cell => {
    return (
      <div>
        <Box display={"flex"} alignItems={"center"}>
          <NCButton
            onClick={() => handleApproveClick(cell)}
            className={cls.approve_button}
          >
            {t("common.approve")}
          </NCButton>

          <NCButton
            onClick={() => handleDeclineClick(cell)}
            className={cls.decline_button}
          >
            {t("common.decline")}
          </NCButton>
        </Box>
      </div>
    )
  }

  const getIdFromRequestId = value => {
    const selectedRequest = value.data.find(
      item => item.identifier === value.value
    )
    nav(`/overview/${selectedRequest.id}`)
  }

  let columns = [
    {
      Header: t("list.request_id"),
      id: "identifier",
      accessor: cell => cell.identifier,
      Cell: value => (
        <NCText
          className={"clickable-text"}
          onClick={() => getIdFromRequestId(value)}
        >
          {value?.value}
        </NCText>
      ),
      disableSortBy: true
    },
    {
      Header: t("list.client"),
      id: "client_name",
      accessor: cell => getDetailsByName(cell),
      disableSortBy: true
    },
    {
      Header: t("list.submitter"),
      id: "submitter_info.name",
      accessor: "submitter_info.name"
    },
    {
      Header: t("list.date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    user && user.user_type !== 4
      ? {
          Header: t("list.status"),
          id: "status", // required for multiple filter

          accessor: cell => (
            <NCTableStatus status={t("requests."+t(REQUEST_STATUS[cell.status]))} />
          ),
          defaultCanFilter: true,
          Filter: SelectColumnFilter,
          // filter: "includes",
          disableSortBy: true
        }
      : selectedTab === 1
      ? {
          Header: t("list.action"),
          id: "new_action",
          accessor: cell => getNewActionColumn(cell),
          disableSortBy: true
        }
      : selectedTab === 2
      ? {
          Header: t("list.driver"),
          id: "driver",
          disableSortBy: true
        }
      : { id: "null", disableSortBy: true },

    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  if (user.user_type === 1 || user.user_type === 4) {
    columns.splice(2, 0, {
      Header: t("list.district_name"),
      accessor: "client_subadmin_info.admin.company_name",
      disableSortBy: true
    })
  }
  const downloadFile = async type => {
    await setDownloadType(type)
    const data = await refetch()
    const contentDispositionHeaders =
      data?.data?.headers["content-disposition"].split("=")
    downloadFileFromResponse(
      data?.data?.data,
      contentDispositionHeaders[contentDispositionHeaders.length - 1]
        .replaceAll(/[/"]/g, "")
        .trim()
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={4}>
              {!isSmallScreen ? (
                <NCText variant={"h4"}>{t("requests.client_request")}</NCText>
              ) : (
                <Grid container justifyContent="space-between">
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NCText variant={"h4"}>{t("requests.client_request")}</NCText>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid
                container
                spacing={2}
                justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>

                {user && user.user_type !== 4 ? (
                  <Grid item>
                    <ExportMenu itemAction={item => downloadFile(item.key)} />
                  </Grid>
                ) : null}
                {(user && user?.user_type === 1) || user?.user_type === 2 ? (
                  <Grid item style={{ paddingRight: "0" }}>
                    <NCButton
                      variant="contained"
                      color="primary"
                      endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
                      onClick={() => nav("/overview/add-request")}
                    >
                      {t("requests.add_request")}
                    </NCButton>
                  </Grid>
                ) : null}
                {!isSmallScreen && (
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {user && user.user_type === 4 ? (
          <Grid item xs={12}>
            <Grid mb={2}>
              <Box mb={2}>
                <ButtonGroup disableElevation>
                  <Button
                    style={{
                      borderStartStartRadius: "20px",
                      borderBottomLeftRadius: "20px"
                    }}
                    variant={selectedTab === 1 ? "contained" : "outlined"}
                    onClick={() => setSelectedTab(1)}
                    color={selectedTab === 1 ? "primary" : ""}
                  >
                    {t("requests.pending")}
                  </Button>

                  <Button
                    variant={selectedTab === 2 ? "contained" : "outlined"}
                    onClick={() => setSelectedTab(2)}
                    color={selectedTab === 2 ? "primary" : ""}
                  >
                    {t("requests.approved")}
                  </Button>
                  <Button
                    variant={selectedTab === 3 ? "contained" : "outlined"}
                    onClick={() => setSelectedTab(3)}
                    color={selectedTab === 3 ? "primary" : ""}
                    style={{
                      borderStartEndRadius: "20px",
                      borderBottomRightRadius: "20px"
                    }}
                  >
                    {t("requests.declined")}
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <NCTable
          columns={columns}
          data={requestData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
        <ApproveRequestModal
          refetch={refetchOverview}
          data={selectedRequest}
          modalState={showApproveModal}
          handleClose={() => setShowApproveModal(false)}
        />
        <NCModal
          open={showDeleteModel}
          title={t("requests.decline_request")}
          text={t("requests.decline_request_confirmation_text")}
          onClose={() => setShowDeleteModel(false)}
          submitButton={
            <NCButton
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                declineRequestMethod()
                setShowDeleteModel(false)
              }}
              style={{ width: "auto" }}
            >
              {t("common.decline")}
            </NCButton>
          }
        />
      </Grid>
    </Grid>
  )
}
export default OverviewList
