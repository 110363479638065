import React, { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import NCText from "../../shared/NCText"
import { Grid, Box, IconButton, useTheme, useMediaQuery } from "@material-ui/core"
import {
  useGetDispatchersList,
  useGetDispatchersFile
} from "../../services/queries"
import { downloadFileFromResponse, formatDate } from "../../libs/utils"
import { SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import NCInput from "../../shared/NCInput"
import NCButton from "../../shared/NCButton"
import ExportMenu from "../../components/ExportMenu"
import {
  AddIcon,
  SearchIcon,
  EditIcon,
  ReviveIcon,
  DeleteIcon
} from "../../libs/icons"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { useNavigate } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import NCModal from "../../shared/NCModal"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import {
  useActivateDispatcher,
  useDeleteDispatcher
} from "../../services/mutations"
import useDebounce from "../../libs/hooks/Debounce"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"

const DispatcherList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const [downloadType, setDownloadType] = useState()
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [selectedDispatcherId, setSelectedDispatcherId] = useState()
  const nav = useNavigate()
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const deleteDispatcherApi = useDeleteDispatcher()
  const activateDispatcherApi = useActivateDispatcher()
  const queryClient = useQueryClient()

  const { isLoading, data } = useGetDispatchersList(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null
    }
  )
  const { refetch } = useGetDispatchersFile(
    downloadType,
    query,
    { ordering },
    {
      enabled: false
    }
  )

  const {
    next,
    previous,
    total_pages,
    results: dispatcherData
  } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const deleteDispatcher = dispatcherId => {
    deleteDispatcherApi.mutate(dispatcherId, {
      onSuccess: () => {
        toast.success(t("dispatcher.deleted"))
        queryClient.invalidateQueries(["getDispatcherList"])
        setShowDeleteModel(false)
      }
    })
  }
  const handleDeleteClick = cell => {
    if (cell.status === "I") {
      //reactivate
      activateDispatcherApi.mutate(cell.id, {
        onSuccess: () => {
          toast.success(t("dispatcher.activated"))
          queryClient.refetchQueries(["getDispatcherList"])
          setShowDeleteModel(false)
        }
      })
    } else {
      setSelectedDispatcherId(cell.id)
      setShowDeleteModel(true)
    }
  }
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => handleDeleteClick(cell)}>
            {cell.status === "I" ? <ReviveIcon /> : <DeleteIcon />}
          </IconButton>
          <IconButton>
            <EditIcon onClick={() => nav(`/dispatchers/${cell.id}`)} />
          </IconButton>
        </Box>
      </div>
    )
  }

  const getDispatcherByName = cell => {
    return (
      <div onClick={() => nav(`/dispatchers/${cell.id}`)}>
        <NCText className={"clickable-text"}>{cell?.name}</NCText>
      </div>
    )
  }
  const columns = [
    {
      Header: t("list.id"),
      id: "id",
      accessor: cell => cell.id,
      Cell: ({ value }) => (
        <NCText
          className={"clickable-text"}
          onClick={() => nav(`/dispatchers/${value}`)}
        >
          {value}
        </NCText>
      )
    },
    {
      Header: t("list.name"),
      id: "name",
      accessor: cell => getDispatcherByName(cell)
    },
    {
      Header: t("list.email"),
      accessor: "email"
    },
    {
      Header: t("list.phone_number"),
      accessor: "phone"
    },
    {
      Header: t("list.no_of_driver"),
      accessor: "total_drivers_assigned",
      Cell: ({ value }) => value
      // value ? (
      //   <NCText
      //     className={"clickable-text"}
      //     onClick={() => nav(`/drivers/?dispatcher=${row?.original?.id}`)}
      //   >
      //     {value}
      //   </NCText>
      // ) : (
      //   value
      // )
    },
    {
      Header: t("list.date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  const downloadFile = async type => {
    await setDownloadType(type)
    const data = await refetch()
    const contentDispositionHeaders =
      data?.data?.headers["content-disposition"].split("=")
    downloadFileFromResponse(
      data?.data?.data,
      contentDispositionHeaders[contentDispositionHeaders.length - 1]
        .replaceAll(/[/"]/g, "")
        .trim()
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12} md={4}>
              {!isSmallScreen ? (
                <NCText variant={"h4"}>{t("dispatcher.heading")}</NCText>
              ) : (
                <Grid container justifyContent="space-between">
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NCText variant={"h4"}>{t("dispatcher.heading")}</NCText>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} justifyContent={isSmallScreen ? "flex-start" : "flex-end"}>
                <Grid item>
                  <NCInput
                    value={query}
                    onChange={e => {
                      setPage(1)
                      setOrdering(null)
                      setQuery(e.target.value)
                    }}
                    placeholder={t("common.search_here")}
                    startIcon={SearchIcon}
                    className="rounded"
                  />
                </Grid>
                <Grid item>
                  <ExportMenu itemAction={item => downloadFile(item.key)} />
                </Grid>
                <Grid item style={{ paddingRight: "0px" }}>
                  <NCButton
                    variant="contained"
                    color="primary"
                    endIcon={<NCSvgIcon component={AddIcon} inheritViewBox />}
                    onClick={() => nav("/dispatchers/add-dispatcher")}
                  >
                    {t("dispatcher.add_dispatcher")}
                  </NCButton>
                </Grid>
                {!isSmallScreen && (
                  <Grid item style={{ paddingLeft: "0" }}>
                    <NotificationAlert />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <NCTable
          columns={columns}
          data={dispatcherData || []}
          page={page}
          onNext={onNext}
          onPrev={onPrev}
          pageCount={total_pages}
          gotoPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          sorting={ordering}
          setSorting={setOrdering}
          filter={statusFilter}
          setFilter={setStatusFilter}
          isLoading={isLoading}
        />
        {isLoading && <NCLoader open={true} />}
      </Grid>
      <NCModal
        open={showDeleteModel}
        title={t("common.delete")}
        text={t("dispatcher.delete_confirm")}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => deleteDispatcher(selectedDispatcherId)}
            style={{ width: "auto" }}
          >
            {t("common.yes")}
          </NCButton>
        }
      />
    </Grid>
  )
}
export default DispatcherList
