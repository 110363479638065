import { Box, Grid, IconButton, Link, makeStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import NCText from "../../shared/NCText"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { BellIcon, NotificationMark, TextNotification } from "../../libs/icons"
import NCButton from "../../shared/NCButton"
import { useState } from "react"
import {
  useReadAllNotification,
  useReadNotification
} from "../../services/mutations"
import { useGetNotification, useGetNotificationSummary } from "../../services/queries"
import { toast } from "react-toastify"
import { formatAMPM, formatDate, loggedInUser } from "../../libs/utils"
import NCLoader from "../../shared/NCLoader"
import { useQueryClient } from "react-query"
import { useRef } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  read_all_button: {
    fontSize: "18px",
    color: "#1EAAC4",
    padding: "0px",
    //fontFamily: "Roboto",
    "&:hover": {
      background: "none"
    }
  },
  read_button: {
    fontSize: "larger",
    marginTop: "0px",
    padding: "0px",

    "&:hover": {
      background: "none"
    }
  }
})

const NotificationAlert = () => {
  const cls = useStyles()
  const {t} = useTranslation();
  const user = loggedInUser()
  const queryClient = useQueryClient()
  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const [notification, showNotification] = useState(false)
  const [page, setPage] = useState(1)
  const readNotification = useReadNotification()
  const readAllNotification = useReadAllNotification()
  const { data, isLoading } = useGetNotification(page, 10, { exclude_category: "CANCEL_BY_DRIVER" }, {
    enabled:
      user?.user_type === 1 || user?.user_type === 2 || user?.user_type === 3 || user?.user_type === 4
        ? true
        : false
  })
  const { results: allData, next, previous } = data?.data?.data || { results: [] }
  const { data: notificationDetail } = useGetNotificationSummary({ exclude_category: "CANCEL_BY_DRIVER" })
  const notificationSummary = notificationDetail?.data?.data || {}

  const handleReadAllNotification = () => {
    readAllNotification.mutate(null, {
      onSuccess: () => {
        toast.success(t("notifications.read_all"))
        queryClient.refetchQueries(["getNotificationData"])
        queryClient.refetchQueries(["getNotificationSummary"])
      }
    })
  }

  const handleReadNotification = id => {
    readNotification.mutate(
      { notification: id },
      {
        onSuccess: () => {
          toast.success(t("notifications.read"))
          queryClient.refetchQueries(["getNotificationData"])
          queryClient.refetchQueries(["getNotificationSummary"])
        }
      }
    )
  }

  let showRef = useRef()
  useEffect(() => {
    if (user.user_type === 1 || user.user_type === 2 || user.user_type === 3 || user.user_type === 4) {
      let handler = e => {
        if (!showRef.current.contains(e.target)) {
          showNotification(false)
        }
      }
      document.addEventListener("mousedown", handler)

      return () => {
        document.removeEventListener("mousedown", handler)
      }
    }
  })

  return (
    <>
      {user.user_type === 1 || user.user_type === 2 || user.user_type === 3 || user.user_type === 4 ? (
        <Grid item ref={showRef}>
          <Box position={"relative"}>
            <IconButton
              style={{ marginLeft: "10px" }}
              className="icon-button-without-padding"
              onClick={() => showNotification(!notification)}
            >
              <BellIcon height={"35px"} />
            </IconButton>
            <Box
              position={"absolute"}
              justifyContent={"center"}
              display={"flex"}
              bgcolor={"#1EAAC4"}
              color={"#fff"}
              paddingLeft={"6px"}
              paddingBottom={"1px"}
              paddingTop={"2px"}
              borderRadius={"50%"}
              top={"-11px"}
              maxWidth={"auto"}
              minWidth={"16px"}
              height={"auto"}
              right={0}
            >
              <NCText
                variant="caption"
                style={{
                  fontSize: "12px",
                  paddingRight: "6px"
                }}
              >
                {notificationSummary?.unread}
              </NCText>
            </Box>
          </Box>

          {notification && (
            <Box position={"relative"} zIndex={2}>
              <Box
                position={"fixed"}
                right={"5px"}
                bottom={"10px"}
                bgcolor={"#fff"}
                top={"70px"}
                width={isSmallScreen? 360 : 400}
                borderRadius={"20px"}
                border={"1px solid #ccc"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Grid>
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "#F3F4F6",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px"
                    }}
                  >
                    <Box m={2}>
                      <NCText variant={"h6"}>{t("notifications.heading")}</NCText>
                    </Box>
                    <Box m={2}>
                      <NCButton
                        className={cls.read_all_button}
                        startIcon={
                          <NCSvgIcon
                            component={TextNotification}
                            style={{ marginTop: "-10px" }}
                            inheritViewBox
                          />
                        }
                        onClick={() => handleReadAllNotification()}
                      >
                        <NCText variant="subtitle1">{t("notifications.mark_all_read")}</NCText>
                      </NCButton>
                    </Box>
                  </Grid>
                  <Box display={"flex"} justifyContent={"space-between"} p={1}>
                    <Box>{previous ? <Link href="#" onClick={() => setPage(page - 1)}>
                      <NCText>{t("previous")}</NCText>
                    </Link> : null}</Box>
                    <Box>{next ? <Link href="#" onClick={() => setPage(page + 1)}>
                      <NCText>{t("next")}</NCText>
                    </Link> : null}</Box>
                  </Box>
                </Grid>
                <Box
                  m={2}
                  mr={0}
                  mt={0}
                  style={{
                    overflow: "hidden",
                    overflowY: "scroll",
                    paddingRight: "5px"
                  }}
                >
                  {allData.map(currentData => {
                    return (
                      <Grid
                        style={{
                          marginBottom: "10px",
                          borderRadius: "20px",
                          border: "1px solid #ccc",
                          padding: "10px"
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            textWrap: "nowrap",
                            alignItems: "center"
                          }}
                        >
                          <Box display={"flex"}>
                            <NCText className="fw-bold">
                              {currentData?.title}
                            </NCText>
                            {!currentData?.is_seen ? (
                              <NCSvgIcon
                                style={{
                                  marginLeft: "5px",
                                  marginTop: "5px"
                                }}
                                component={NotificationMark}
                              />
                            ) : null}
                          </Box>
                          <Box
                            color={"#9CA3AF"}
                            // marginTop={"5px"}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <NCText style={{ fontSize: "14px" }}>
                              {`${formatDate(
                                currentData?.created
                              )}/${formatAMPM(currentData?.created)}`}
                            </NCText>
                            {!currentData?.is_seen ? (
                              <TextNotification
                                className="cursor-pointer"
                                style={{ width: "20px" }}
                                onClick={() =>
                                  handleReadNotification(currentData?.id)
                                }
                              />
                            ) : null}
                          </Box>
                        </Grid>
                        <hr />
                        <Box mt={"10px"}>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            {currentData?.data?.client_name ? (
                              <Box display={"flex"} alignItems={"center"}>
                                <NCText variant={"body2"} className="fw-bold">
                                {t("common.client_name")}:
                                </NCText>
                                <NCText
                                  style={{
                                    fontSize: "14px",
                                    color: "#6B7280",
                                    marginTop: "2px",
                                    marginLeft: "5px"
                                  }}
                                >
                                  {currentData?.data?.client_name}
                                </NCText>
                              </Box>
                            ) : null}
                          </Grid>
                          <Grid item xs={6}>
                            {currentData?.data?.start_date ? <Box display={"flex"} alignItems={"center"}>
                              <NCText variant={"body2"} className="fw-bold">
                              {t("common.start_date")}:
                              </NCText>
                              <NCText
                                style={{
                                  fontSize: "14px",
                                  color: "#6B7280",
                                  marginLeft: "5px"
                                }}
                              >
                                {formatDate(currentData?.data?.start_date)}
                              </NCText>
                            </Box> : null}
                          </Grid>
                          <Grid
                            container
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            {currentData.data.pickup_time ? (
                              <Grid item xs={6}>
                                <Box display={"flex"} alignItems={"center"}>
                                  <NCText variant={"body2"} className="fw-bold">
                                    {t("common.pickup_time")}:
                                  </NCText>
                                  <NCText
                                    style={{
                                      fontSize: "14px",
                                      color: "#6B7280",
                                      marginLeft: "5px"
                                    }}
                                  >
                                    {currentData.data.pickup_time}
                                  </NCText>
                                </Box>
                              </Grid>
                            ) : null}

                            {currentData?.data?.bleg_pickup_time ? (
                              <Grid item xs={6}>
                                <Box display={"flex"} alignItems={"center"}>
                                  <NCText variant={"body2"} className="fw-bold">
                                    {t("notifications.bleg_eta")}:
                                  </NCText>
                                  <NCText
                                    style={{
                                      fontSize: "14px",
                                      color: "#6B7280",
                                      marginLeft: "5px"
                                    }}
                                  >
                                    {currentData?.data?.bleg_pickup_time}
                                  </NCText>
                                </Box>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>
                        <Grid>
                          <Box color="#9CA3AF">
                            <NCText style={{ fontSize: "14px" }}>
                              {currentData.message}
                            </NCText>
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Box>
                <NCLoader
                  open={
                    isLoading ||
                    readNotification.isLoading ||
                    readAllNotification.isLoading
                  }
                />
              </Box>
            </Box>
          )}
        </Grid>
      ) : null}
    </>
  )
}

export default NotificationAlert
