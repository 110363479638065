/* eslint-disable no-console */
import React, { useState, useEffect } from "react"
import { Device } from "@twilio/voice-sdk"
import { STORAGE } from "../../libs/storage"
import { Avatar, makeStyles } from "@material-ui/core"
import { getTwillioToken, useGetAllUsers } from "../../services/queries"
import { CallEndIcon, MuteIcon, UnmuteIcon } from "../../libs/icons"
import { useNavigate } from "react-router-dom"
import NCText from "../../shared/NCText"

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center"
  },
  avatar: {
    width: "200px",
    height: "200px"
  },
  ring: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#929292"
  },
  iconContainer: {
    width: "200px",
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "10px"
  },
  icon: {
    cursor: "pointer",
    width: "45px",
    height: "45px"
  },
  name: {
    color: "#10658E",
    textAlign: "center",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  }
}))

const CallUser = () => {
  const cls = useStyles()

  const nav = useNavigate()

  const [status, setStatus] = useState("Connecting...")

  const [isMuted, setIsMuted] = useState(false)

  const { data: userList } = useGetAllUsers("Driver 2")

  const [selectedUser, setSelectedUser] = useState(null)

  const [twilioDevice, setTwilioDevice] = useState(null)

  const [call, setCall] = useState(null)

  useEffect(() => {
    const setupDevice = async () => {
      const response = await getTwillioToken()

      const device = new Device(response.data.message, {
        debug: true,
        enableImprovedSignalingErrorPrecision: true,
        sounds: {
          // outgoing:"./sample.mp3",
        }
      })

      device.on("ready", () => {
        console.log("Twilio.Device is ready!")
      })

      device.on("error", error => {
        console.error("Twilio.Device error:", error)
        setCall(null)
      })

      device.on("incoming", connection => {
        console.log("Incoming call from", connection.parameters.From)
        connection.accept()
      })

      device.on("connect", connection => {
        console.log("Successfully establed call")
        connection.accept()
      })

      setTwilioDevice(device)
    }

    setupDevice()
  }, [selectedUser])

  useEffect(() => {
    if (selectedUser?.id && twilioDevice && !call) {
      console.log("Calling Initiated")
      makeCall(selectedUser?.phone)
    }
  }, [selectedUser?.id, twilioDevice])

  const makeCall = async number => {
    const fromUser = STORAGE.getUserDetails()?.id
    const connection = await twilioDevice.connect({
      params: {
        To: number,
        to: selectedUser.id,
        from: fromUser
      }
    })

    connection.on("ringing", function () {
      setStatus("Call Connected")
    })

    connection.on("answered", function () {
      setStatus("Answered")
    })

    connection.on("disconnect", function () {
      setStatus("Call End")
      nav(-1)
    })

    connection.on("reconnecting", () => {
      setStatus("Reconnecting")
    })

    connection.on("reconnected", () => {
      setStatus("Reconnected")
    })

    connection.on("error", callError => {
      console.log(callError)
    })
    setCall(connection)
  }

  const hangUp = () => {
    call?.disconnect()
    setStatus("Call End")
  }

  useEffect(() => {
    if (userList) {
      const users = userList?.data?.data?.results
      setSelectedUser(users[1])
    }
  }, [userList])

  return (
    <div className={cls.container}>
      <NCText>
        <div className={cls.name}>{selectedUser?.name}</div>
      </NCText>
      <Avatar src={selectedUser?.avatar} className={cls.avatar}></Avatar>
      <div className={cls.ring}>{status}</div>
      <div className={cls.iconContainer}>
        {!isMuted ? (
          <MuteIcon
            className={cls.icon}
            onClick={() => {
              call.mute(!call.isMuted())
              setIsMuted(true)
            }}
          />
        ) : (
          <UnmuteIcon
            className={cls.icon}
            onClick={() => {
              call.mute(!call.isMuted())
              setIsMuted(false)
            }}
          />
        )}
        <CallEndIcon className={cls.icon} onClick={hangUp} disabled={!call} />
      </div>
    </div>
  )
}

export default CallUser
