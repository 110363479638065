import { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import NCModal from "../../shared/NCModal"
import { DeleteIcon, EditIcon, ReviveIcon } from "../../libs/icons"
import { Box, IconButton } from "@material-ui/core"
import { ACTIVITY_STATUS, SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import Filter from "../../shared/table/Filter"
import NCTableStatus from "../../shared/table/NCTableStatus"
import { toast } from "react-toastify"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import useDebounce from "../../libs/hooks/Debounce"
import {
  useActivateSubEmployee,
  useDeleteSubEmployee
} from "../../services/mutations"
import { useGetSubEmployees } from "../../services/queries"
import AddSubAdminEmployeeModal from "./AddSubAdminEmployeeModal"
import { formatDate } from "../../libs/utils"
import { useTranslation } from "react-i18next"

const SubAdminEmployeeList = props => {
  const {t} =  useTranslation();
  const [page, setPage] = useState(1)
  const [employeeModal, setEmployeeModal] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [query] = useState("")
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("-created")
  const [statusFilter, setStatusFilter] = useState([])
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const queryClient = useQueryClient()

  const deleteEmployeeApi = useDeleteSubEmployee()
  const activateEmployeeApi = useActivateSubEmployee()
  const { isLoading, data } = useGetSubEmployees(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null,
      subadmin: props.subAdminId
    }
  )
  const { next, previous, total_pages, results } = props.subAdminId
    ? data?.data?.data || {}
    : data?.data?.data?.data || {}
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const handleDeleteClick = cell => {
    if (cell.status === "I") {
      //reactivate
      activateEmployeeApi.mutate(cell.id, {
        onSuccess: () => {
          toast.success(t("admin.employee_activated"))
          queryClient.refetchQueries(["getSubEmployeeList"])
          queryClient.invalidateQueries(["getSubAdminAuditLog"])
          setDeleteModalOpen(false)
        }
      })
    } else {
      setSelectedEmployee(cell)
      setDeleteModalOpen(true)
    }
  }
  const handleClose = () => {
    setDeleteModalOpen(false)
  }
  const handleEditClick = cell => {
    setSelectedEmployee(cell)
    setEmployeeModal(true)
  }
  const handleDelete = () => {
    if (selectedEmployee.status === "I") {
      //reactivate
      activateEmployeeApi.mutate(selectedEmployee.id, {
        onSuccess: () => {
          toast.success(t("admin.employee_activated"))
          queryClient.refetchQueries(["getSubEmployeeList"])
          queryClient.invalidateQueries(["getSubAdminAuditLog"])
          setDeleteModalOpen(false)
        }
      })
    } else if (selectedEmployee.status === "A") {
      //delete
      deleteEmployeeApi.mutate(selectedEmployee.id, {
        onSuccess: () => {
          toast.success(t("admin.employee_deleted"))
          queryClient.refetchQueries(["getSubEmployeeList"])
          queryClient.invalidateQueries(["getSubAdminAuditLog"])
          setDeleteModalOpen(false)
        }
      })
    }
  }
  const SelectColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <Filter
        options={[
          { key: "A", name: t("client.active") },
          { key: "I", name: t("client.inactive") }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <Box display="flex" alignItems={"center"}>
          <IconButton onClick={() => handleEditClick(cell)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(cell)}>
            {cell.status === "I" ? <ReviveIcon /> : <DeleteIcon />}
          </IconButton>
        </Box>
      </div>
    )
  }
  const columns = [
    {
      Header: t("common.first_name"),
      accessor: "first_name"
    },
    {
      Header: t("common.last_name"),
      accessor: "last_name"
    },
    {
      Header: t("common.email"),
      accessor: "email"
    },
    {
      Header: t("common.phone_number"),
      accessor: "phone"
    },
    {
      Header: t("common.date_created"),
      id: "created",
      accessor: cell => cell.created,
      Cell: ({ value }) => formatDate(value)
    },
    {
      Header: t("common.status"),
      id: "status",
      accessor: cell => <NCTableStatus status={ACTIVITY_STATUS[cell.status]} />,
      defaultCanFilter: true,
      Filter: SelectColumnFilter,
      disableSortBy: true
    },
    {
      Header: "",
      id: "action",
      accessor: cell => getActionColumn(cell),
      disableSortBy: true
    }
  ]
  return (
    <>
      <NCTable
        columns={columns}
        data={results || []}
        pageCount={total_pages}
        pageSize={pageSize}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        gotoPage={setPage}
        setPageSize={setPageSize}
        sorting={ordering}
        setSorting={setOrdering}
        filter={statusFilter}
        setFilter={setStatusFilter}
        isLoading={isLoading}
        inlineFooter={true}
      />
      <NCModal
        open={deleteModalOpen}
        title={t("admin.delete_employee")}
        text={t("admin.delete_employee_confirm")}
        onClose={handleClose}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleDelete}
            style={{ width: "auto" }}
          >
            {t("common.yes")}
          </NCButton>
        }
      />
      {props.subAdminId && selectedEmployee ? (
        <AddSubAdminEmployeeModal
          subAdminId={props.subAdminId}
          modalState={employeeModal}
          handleClose={() => {
            setEmployeeModal(false)
            setSelectedEmployee()
          }}
          data={selectedEmployee}
          isEdit={true}
        />
      ) : null}
      {deleteEmployeeApi.isLoading || activateEmployeeApi.isLoading ? (
        <NCLoader open={true} />
      ) : null}
    </>
  )
}

export default SubAdminEmployeeList
