export const VIDEOAUDIO = 1;
export const TEXTDATA = 3;
export const PDF = 2;

export const LESSON_TYPE = [
    {
        name:"training.video_audio",
        value:VIDEOAUDIO
    },
    {
        name:"training.text",
        value:TEXTDATA
    },
    {
        name:"training.pdf",
        value:PDF
    },
]