import { Grid } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useUpdateClient } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import FormField from "../FormField"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import { GENDER } from "../../libs/config"
import PhoneNumber from "../PhoneNumber"
import { useEffect } from "react"
import { getAge } from "../../libs/utils"
import NCAddress from "../../shared/NCAddress"
import NCDatePicker from "../../shared/NCDatePicker"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { useTranslation } from "react-i18next"

const EditClientModal = ({
  clientId,
  modalState = false,
  handleClose,
  data = {}
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const updateClientApi = useUpdateClient()
  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      middle_name: data.middle_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      phone: data.phone || "",
      gender: data.gender || "",
      dob: data.dob || "",
      age: data.age || "",
      address: data.address || "",
      latitude: data?.latitude || "",
      longitude: data?.longitude || "",
      place: data?.place || "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("client.error.first_name")),
      last_name: Yup.string().required(t("client.error.last_name")),
      email: Yup.string().email(t("client.error.email")),
      phone: Yup.string().required(t("client.error.phone")),
      gender: Yup.string().required(t("client.error.gender")),
      dob: Yup.date().typeError(t("client.error.dob_type_error")).required(t("client.error.dob")),
      address: Yup.string().required(t("client.error.address")),
      latitude: Yup.string().required(t("client.error.latitude")),
      longitude: Yup.string().required(t("client.error.longitude")),
      place: Yup.string().required(t("client.error.place")),
    }),
    enableReinitialize: true,
    onSubmit(values) {
      updateClientApi.mutate(
        { ...values, id: clientId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getClientDetail"])
            queryClient.invalidateQueries(["getClientAuditLog"])
            toast.success(t("client.client_edit"))
            handleClose()
          }
        }
      )
    }
  })
  useEffect(() => {
    if (formik.values.dob) {
      const age = getAge(formik.values.dob)
      formik.setFieldValue("age", age)
    }
  }, [formik.values.dob])
  const clientModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormField
              label={t("common.first_name")}
              name={"first_name"}
              required={true}
              error={formik?.errors?.first_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={t("common.middle_name")}
              name={"middle_name"}
              error={formik?.errors?.middle_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={t("common.last_name")}
              name={"last_name"}
              required={true}
              error={formik?.errors?.last_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={t("common.email_address")}
              name={"email"}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={PhoneNumber}
              label={t("common.phone_number")}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={NCAutocomplete}
              options={
                Object.keys(GENDER).map(item => {
                  return { id: item, name: GENDER[item] }
                }) || []
              }
              label={t("common.gender")}
              name={"gender"}
              onChange={v => {
                formik.setFieldValue("gender", v)
              }}
              required={true}
              error={formik?.errors?.gender}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={NCDatePicker}
              label={t("common.dob")}
              name={"dob"}
              onChange={v => formik.setFieldValue("dob", v)}
              max={new Date().toISOString().split("T")[0]}
              required={true}
              error={formik?.errors?.dob}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={t("common.age")}
              name={"age"}
              required={true}
              readOnly={true}
              error={formik?.errors?.age}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={NCAddress}
              label={t("common.address")}
              name={"address"}
              onChange={e => {
                formik.setFieldValue("place", e?.placeId);
                formik.setFieldValue("longitude", e?.geometry?.longitude);
                formik.setFieldValue("latitude", e?.geometry?.latitude);
                formik.setFieldValue("address", e?.name);
              }}
              required={true}
              error={formik?.errors?.address}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={t("client.edit_client")}
        text={clientModal}
        onClose={() => handleClose()}
        size={"lg"}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            {t("common.update")}
          </NCButton>
        }
      />
      {updateClientApi.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default EditClientModal
