import { useParams } from "react-router-dom"
import { useGetDriverDetail } from "../../services/queries"
import { Box, Grid } from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { GENDER, VEHICLE_FACILITIES } from "../../libs/config"
import FormSection from "../../components/FormSection"
import NCLoader from "../../shared/NCLoader"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useTranslation } from "react-i18next"

const VehicleDetail = () => {
  const { vehicleId } = useParams()
  const { isLoading, data: driverResult } = useGetDriverDetail(vehicleId)
  const driverData = driverResult?.data?.data || {}
  const {t} = useTranslation();

  const isFacilityAvailable = facilityName => {
    if (!driverData?.vehicle?.vehicle_facilities) return false
    const facility = driverData?.vehicle?.vehicle_facilities.find(
      f => f.type === facilityName
    )
    return facility ? true : false
  }

  const getFacilityQuantityFromArray = facilityName => {
    if (!driverData?.vehicle?.vehicle_facilities) return "--"
    const facility = driverData?.vehicle?.vehicle_facilities.find(
      f => f.type === facilityName
    )
    return facility ? facility.quantity : "--"
  }


  const renderRowItem = (key, value) => {
    return (
      <Grid item md={4}>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText className="overflow-ellipsis">{value}</NCText>
      </Grid>
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12}>
            <Box mb={2} display={"flex"} justifyContent={"space-between"}>
              <NCText variant={"h4"} style={{ marginRight: "10px" }}>
                {t("vehicles.vehicle_details")}
              </NCText>
              <NotificationAlert />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} md={10}>
            <FormSection title={t("vehicles.vehicle_details")}>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("list.mark"), driverData?.vehicle?.mark)}
                  {renderRowItem(t("list.model"), driverData?.vehicle?.model)}
                  {renderRowItem(t("list.vin"), driverData?.vehicle?.vin_no)}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("list.driver_id"), driverData?.vehicle?.driver)}
                  {renderRowItem(
                    t("list.license_plate"),
                    driverData?.vehicle?.licence_plate
                  )}
                  {renderRowItem(t("vehicles.gender"), GENDER[driverData?.gender])}
                </Grid>
              </Box>
            </FormSection>
            <FormSection title={t("vehicles.vehicle_offering")}>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.buckle_guard"),
                    isFacilityAvailable(VEHICLE_FACILITIES.BUCKLE_GUARD)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(
                      VEHICLE_FACILITIES.BUCKLE_GUARD
                    )
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                     t("vehicles.booster"),
                    isFacilityAvailable(VEHICLE_FACILITIES.BOOSTER)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(VEHICLE_FACILITIES.BOOSTER)
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.safety_vest"),
                    isFacilityAvailable(VEHICLE_FACILITIES.SAFETY_VEST)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(VEHICLE_FACILITIES.SAFETY_VEST)
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.car_seat"),
                    isFacilityAvailable(VEHICLE_FACILITIES.CAR_SEAT)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.quantity"),
                    getFacilityQuantityFromArray(VEHICLE_FACILITIES.CAR_SEAT)
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                     t("vehicles.wheel_chair"),
                    isFacilityAvailable(VEHICLE_FACILITIES.WHEELCHAIR)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.service_dog"),
                    isFacilityAvailable(VEHICLE_FACILITIES.SERVICE_DOG)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                  {renderRowItem(
                    t("vehicles.travel_alone"),
                    isFacilityAvailable(VEHICLE_FACILITIES.TRAVEL_ALONE)
                      ? t("common.yes")
                      : t("common.no")
                  )}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(
                    t("vehicles.vehicle_capacity"),
                    driverData?.vehicle?.capacity
                  )}
                </Grid>
              </Box>
            </FormSection>
            <FormSection title={t("vehicles.driver_details")}>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("common.first_name"), driverData?.first_name)}
                  {renderRowItem(t("common.middle_name"), driverData?.middle_name)}
                  {renderRowItem(t("common.last_name"), driverData?.last_name)}
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container>
                  {renderRowItem(t("common.email_address"), driverData?.email)}
                  {renderRowItem(t("common.phone_number"), driverData?.phone)}
                  {renderRowItem(t("common.gender"), GENDER[driverData?.gender])}
                </Grid>
              </Box>
            </FormSection>
          </Grid>
        </Grid>
      </Grid>

      <NCLoader open={isLoading} />
    </Grid>
  )
}

export default VehicleDetail
