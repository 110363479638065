import { Box, Button, Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { useParams } from "react-router-dom"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import FormSection from "../../components/FormSection"
import { useGetSubAdminAuditLog, useGetSubAdminDetail } from "../../services/queries"
import SubAdminEmployeeList from "../../components/subadmin/SubAdminEmployeeList"
import AddSubAdminEmployeeModal from "../../components/subadmin/AddSubAdminEmployeeModal"
import { useState } from "react"
import EditSubAdminModal from "../../components/subadmin/EditSubAdminModal"
import NCLoader from "../../shared/NCLoader"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { getAddressValue, loggedInUser } from "../../libs/utils"
import NCAuditButton from "../../shared/NCAuditButton"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "20px",
    fontSize: "12px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between",
    fintWeight: "600"
  }
}))
const SubAdminDetail = () => {
  const cls = useStyles()
  const { t } = useTranslation();
  let { subAdminId } = useParams()
  const user = loggedInUser()
  const [employeeModal, setEmployeeModal] = useState(false)
  const [subAdminModal, setsubAdminModal] = useState(false)

  const { isLoading, data } = useGetSubAdminDetail(subAdminId)
  const result = data?.data?.data || {}

  const { data: subadmin } = useGetSubAdminAuditLog(
    {
      subadmin_id: subAdminId
    },
    { enabled: subAdminId && user?.user_type === 1 ? true : false }
  )
  const subadminAuditData = subadmin?.data?.data?.results || []

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))

  const subAdminTitle = (
    <Grid item className={cls.titleSection}>
      {t("subadmin.subadmin_school_details")}
      <Button
        onClick={() => setsubAdminModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        {t("subadmin.edit_subadmin")}
      </Button>
    </Grid>
  )
  const employeeTitle = (
    <Grid item className={cls.titleSection}>
      {t("common.employees")}
      <Button
        onClick={() => setEmployeeModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        {t("common.add_employee")}
      </Button>
    </Grid>
  )
  const renderRowItem = (key, value) => {
    return (
      <Grid item md={4}>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText variant="body2">{value}</NCText>
      </Grid>
    )
  }
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box mb={2}>
          <NCText variant={"h5"}>{t("subadmin.subadmin_school_detail")}</NCText>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          {!isSmallScreen && user.user_type === 1 && subAdminId ? (
            <Box mb={2} mr={1}>
              <NCAuditButton data={subadminAuditData} isLoading={isLoading} />
            </Box>
          ) : null}
          <NotificationAlert />
        </Box>
        {/* <Box display={"flex"} justifyContent={"flex-end"}>
          <NotificationAlert />
        </Box> */}
      </Grid>
      {isSmallScreen && (
        <Grid item xs={6} sm={4}>
          {user.user_type === 1 && subAdminId ? (
            <Box mb={2} mr={1}>
              <NCAuditButton data={subadminAuditData} isLoading={isLoading} />
            </Box>
          ) : null}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormSection title={subAdminTitle}>
          <Grid container>
            {renderRowItem(t("common.name"), result?.name)}
            {renderRowItem(t("common.company"), result?.admin?.company_name)}
            {renderRowItem(t("common.address"), getAddressValue(result?.address))}
          </Grid>
        </FormSection>
        <FormSection title={employeeTitle}>
          <SubAdminEmployeeList subAdminId={subAdminId} />
        </FormSection>
      </Grid>
      <AddSubAdminEmployeeModal
        subAdminId={subAdminId}
        modalState={employeeModal}
        handleClose={() => setEmployeeModal(false)}
      />
      <EditSubAdminModal
        subAdminId={subAdminId}
        modalState={subAdminModal}
        handleClose={() => setsubAdminModal(false)}
        data={result}
      />
      <NCLoader open={isLoading} />
    </Grid>
  )
}
export default SubAdminDetail
