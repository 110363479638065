import { Form, FormikProvider, useFormik } from "formik"
import { Box, Grid } from "@material-ui/core"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import FormField from "../../components/FormField"
import NCAutocomplete from "../../shared/NCAutocomplete"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import { useGetDriverList, useGetRideDetails } from "../../services/queries"
import {
  useAllocateDriverForUnschedule,
  useGetRecommendedDriver,
  usePostTripChangeDriver
} from "../../services/mutations"
import NCText from "../../shared/NCText"
import { useTranslation } from "react-i18next"
import { getEndTimeOfTheDay, getStartTimeOfTheDay } from "../../libs/utils"

const AssignDriverModal = ({
  driverId,
  startTime,
  tripsRefetch,
  stopsRefetch,
  trips,
  modalState = false,
  handleClose
}) => {
  const [search, setSearch] = useState(null)
  const [recommendedDriver, setRecommendedDriver] = useState([]);
  const { data } = useGetDriverList(search, 1, 100)
  const {t} = useTranslation();
  const changeDriverAPI = usePostTripChangeDriver()
  const allocateDriverAPI = useAllocateDriverForUnschedule()
  const recommendedDriverAPI = useGetRecommendedDriver();
  const queryClient = useQueryClient()
  const { results: list, isLoading } = data?.data?.data || []
  const [driverList,setDriverList] = useState([]);
  const formik = useFormik({
    initialValues: {
      new_driver: ""
    },
    validationSchema: Yup.object().shape({
      new_driver: Yup.string().required(t("change_driver.error.driver_required"))
    }),
    enableReinitialize: true,
    onSubmit: values => {
      if (driverId) {
        changeDriverAPI.mutate(
          {
            ...values,
            old_driver: driverId,
            start_time: st,
            end_time: et
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["getDriverList"])
              toast.success(t("change_driver.success"))
              tripsRefetch()
              stopsRefetch()
              handleClose()
            }
          }
        )
      } else {
        const tripsId = Object.keys(trips)
          .filter(key => trips[key])
          .map(key => parseInt(key))

        allocateDriverAPI.mutate(
          {
            driver: values.new_driver,
            start_time: st,
            end_time: et,
            trips: tripsId
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["getDriverList"])
              toast.success(t("change_driver.success"))
              tripsRefetch()
              handleClose()
            }
          }
        )
      }
    }
  })

  const [message, setMessage] = useState({
    message: "",
    alert: false
  })

  const st = getStartTimeOfTheDay(startTime);
  const et = getEndTimeOfTheDay(startTime);

  const { data: alertData, isLoading: alertLoading } = useGetRideDetails(
    {
      driver: formik?.values?.new_driver,
      start_time: st,
      end_time: et,
      ongoing: true
    },
    {
      enabled: !!(
        formik?.values?.new_driver &&
        typeof formik?.values?.new_driver === "number"
      )
    }
  )

  useEffect(() => {
    const results = alertData?.data?.data?.results
    // eslint-disable-next-line no-console
    if (results) {
      if (results?.length === 0) {
        setMessage({
          message: t("change_driver.available_message"),
          alert: false
        })
      } else {
        setMessage({
          message:
          t("change_driver.not_available_message"),
          alert: true
        })
      }
    } else {
      setMessage({
        message: "",
        alert: false
      })
    }
  }, [alertData?.data?.data?.results])

  useEffect(()=>{

    const tripsList = Object.keys(trips);
    recommendedDriverAPI.mutate(
      {
        trips: tripsList
      },
      {
        onSuccess: (resp) => {
          setRecommendedDriver(resp?.data?.data);          
        }
      }
    )

  },[])

  useEffect(()=>{
    if(list?.length > 0 && recommendedDriver?.length > 0){
      const myList = list?.filter(l => !recommendedDriver?.find(rd=> rd.id === l.driver.id));
      const myRecommended = recommendedDriver?.map(rd=> ({driver:rd}))
      setDriverList([...myRecommended,...myList])
    }else{
      setDriverList(list);      
    }
  },[list, recommendedDriver])

  const assignModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NCText
              style={{ color: "#2E3B36", fontSize: "16px", fontWeight: "600" }}
            >
              {t("change_driver.subheading")}
            </NCText>
          </Grid>
          <Grid item xs={12}>
            <FormField
              as={NCAutocomplete}
              options={
                driverList?.map(driver => ({
                  id: driver?.driver?.user,
                  name: driver?.driver?.name
                })) || []
              }
              label={t("common.driver")}
              name={"new_driver"}
              onChange={v => {
                // eslint-disable-next-line no-console
                if (typeof v === "number") {
                  formik.setFieldValue("new_driver", v)
                } else {
                  formik.setFieldValue("new_driver", "")
                }
              }}
              onSearch={v => setSearch(v)}
              loading={isLoading}
              required={true}
              error={formik?.errors?.new_driver}
            />
          </Grid>
        </Grid>
      </Form>
      <Box
        style={{
          color: message?.alert ? "#E22134" : "#4CAF50",
          fontSize: "14px",
          fontWeight: 600
        }}
      >
        {message?.message}
      </Box>
    </FormikProvider>
  )

  const title = driverId ? t("change_driver.change_driver") : t("change_driver.assign_driver");

  return (
    <>
      <NCModal
        open={modalState}
        title={title}
        text={assignModal}
        onClose={() => handleClose()}
        size={"lg"}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            {title}
          </NCButton>
        }
      />
      {(changeDriverAPI.isLoading || alertLoading) && <NCLoader open={true} />}
    </>
  )
}

export default AssignDriverModal
