import { Box, Grid, makeStyles } from "@material-ui/core"
import TopNavBar from "../TopNavBar"
import { useLocation } from "react-router-dom"
import FormSection from "../FormSection"
import { CardMedia } from "@mui/material"
import { useTranslation } from "react-i18next"
import NCText from "../../shared/NCText"
import InspectionImageModal from "./InspectionImageModal"
import { useState } from "react"
import { GeoLocationIcon } from "../../libs/icons"

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 25px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "20px"
    }
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#10658E"
  },
  subheading: {
    fontSize: "18px"
  },
  media: {
    maxWidth: "192px",
    maxHeight: "173px"
  },
  quest: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  ansPositive: {
    color: "#1DB954"
  },
  ansNegative: {
    color: "#E22134"
  }
}))

const DriverInspectionDetails = () => {
  const cls = useStyles()
  const { t } = useTranslation()
  const [image, setImage] = useState(null)
  const { state } = useLocation()
  const { details } = state

  const onImageClick = image => {
    setImage(image)
  }

  const onModalClose = () => {
    setImage(null)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12} className={cls.card}>
        <FormSection
          title={
            <div style={{ fontSize: "24px" }}>
              {t("inspection.images_uploaded")}
            </div>
          }
          subTitle={
            <Box className={cls.date} marginRight={"10px"}>
              <GeoLocationIcon
                title={`${details.latitude}, ${details.longitude}`}
                className={cls.geoIcon}
              />
            </Box>
          }
        >
          <Box className={cls.cardDescription}>
            <Grid container spacing={2}>
              {details?.inspection_images?.map(image => {
                return (
                  <Grid item>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      onClick={() => onImageClick(image)}
                    >
                      <CardMedia
                        component="img"
                        image={image.image}
                        alt="description"
                        sx={{
                          objectFit: "center"
                        }}
                        className={cls.media}
                      />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </FormSection>
        <Grid item xs={12} className={cls.card}>
          <FormSection
            title={
              <div style={{ fontSize: "24px" }}>
                {t("inspection.questions")}
              </div>
            }
          >
            <Box className={cls.cardDescription}>
              <Grid>
                {details?.data?.map(questions => {
                  return (
                    <Grid className={cls.quest}>
                      <NCText>{questions.name}</NCText>
                      <NCText
                        className={
                          questions.answer ? cls.ansPositive : cls.ansNegative
                        }
                      >
                        {questions.answer
                          ? t("inspection.yes").toUpperCase()
                          : t("inspection.no").toUpperCase()}
                      </NCText>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </FormSection>
        </Grid>
      </Grid>
      {image && (
        <InspectionImageModal
          open={Boolean(image)}
          image={image}
          onClose={onModalClose}
        />
      )}
    </Grid>
  )
}

export default DriverInspectionDetails
