import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Grid, makeStyles } from "@material-ui/core"
import NCText from "../shared/NCText"
import { LANGUAGES, LANGUAGE_LOCALE } from "../libs/config"
import { STORAGE } from "../libs/storage"
import FormField from "./FormField"
import NCAutocomplete from "../shared/NCAutocomplete"
import { FormikProvider, useFormik } from "formik"
import { useUpdateLanguage } from "../services/mutations"
import { loggedInUser } from "../libs/utils"

const useStyles = makeStyles({
  input: {
    width: "200px"
  }
})

const LanguageSelector = () => {
  const { i18n, t } = useTranslation()
  const cls = useStyles()

  const user = loggedInUser()

  const updateLanguageMutation = useUpdateLanguage(user.id);

  const formik = useFormik({
    initialValues: {
      lang: i18n.language.toLowerCase() || "en-us"
    }
  })

  const handleLanguageChange = lang => {
    const selectedLanguage = lang
    i18n.changeLanguage(selectedLanguage)
    const locale = LANGUAGE_LOCALE[selectedLanguage];
    STORAGE.setLocale(locale)
    updateLanguageMutation.mutate({
      language: locale
    });
  }

  // useEffect(() => {
  // document.body.dir = i18n.dir()
  // }, [i18n, i18n.language])

  return (
    <Box bgcolor={"#fff"} p={1} mb={1} borderRadius={"8px"}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item display="flex" flexWrap="wrap">
          <NCText variant={"body2"}>{t("account_setting.select_language")}</NCText>
        </Grid>
        <Grid item variant="contained" color="primary">
          <FormikProvider value={formik}>
            <Grid container className={cls.input}>
              <Grid item xs={12}>
                <FormField
                  as={NCAutocomplete}
                  options={
                    LANGUAGES.map(item => {
                      return { id: item.code, name: item.lang }
                    }) || []
                  }
                  label={""}
                  name={"lang"}
                  onChange={lang => {
                    const checkLanguage = !!LANGUAGES?.find(
                      item => item.code === lang
                    )
                    formik.setFieldValue("lang", lang)
                    checkLanguage && handleLanguageChange(lang)
                  }}
                />
              </Grid>
            </Grid>
          </FormikProvider>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LanguageSelector
