import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import PhoneNumber from "../../components/PhoneNumber"
import NCButton from "../../shared/NCButton"
import { useAddAidMonitor, useUpdateAidMonitor } from "../../services/mutations"
import {
  useGetAidMonitor,
  useGetAidMonitorAuditLog,
  useGetDispatchersList
} from "../../services/queries"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import NCAddress from "../../shared/NCAddress"
import NCAutocomplete from "../../shared/NCAutocomplete"
import { GENDER } from "../../libs/config"
import NCAuditButton from "../../shared/NCAuditButton"
import { loggedInUser } from "../../libs/utils"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const AddAidMonitor = () => {
  const {t} = useTranslation();
  const [search, setSearch] = useState()
  const { id } = useParams()
  const user = loggedInUser()
  const edit = !window.location.href.includes("add")
  const addAidMonitor = edit ? useUpdateAidMonitor() : useAddAidMonitor()
  const { isLoading, data: aidMonitorResult } = edit ? useGetAidMonitor(id) : {}
  const aidMonitorData = aidMonitorResult?.data?.data || {}
  const { data: diapatcherList, isLoading: dispatcherLoading } = useGetDispatchersList(null, search)
  const dispatchers = diapatcherList?.data?.data?.results || []
  const queryClient = useQueryClient()
  const nav = useNavigate()

  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: aidMonitorData?.first_name || "",
      middle_name: aidMonitorData?.middle_name || "",
      last_name: aidMonitorData?.last_name || "",
      email: aidMonitorData?.email || "",
      phone: aidMonitorData?.phone || "",
      gender: aidMonitorData?.gender || "",
      address: aidMonitorData?.address || "",
      latitude: aidMonitorData?.latitude || "",
      longitude: aidMonitorData?.longitude || "",
      dispatcher: aidMonitorData?.dispatcher || ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t("admin.first_name_required")),
      last_name: Yup.string().required(t("admin.last_name_required")),
      phone: Yup.string().required(t("admin.phone_required")),
      email: Yup.string()
        .email(t("admin.invalid_email"))
        .required(t("admin.email_required")),
      gender: Yup.string().required(t("client.error.gender")),
      address: Yup.string().required(t("client.error.address")),
      latitude: Yup.string().required(t("client.error.latitude")),
      longitude: Yup.string().required(t("client.error.longitude")),
      dispatcher: Yup.string().required(t("monitor.dispatcher_required"))
    }),
    onSubmit: (values, { resetForm }) => {
      let updatedValues = { ...values }
      if (id) {
        updatedValues.id = id
      }
      addAidMonitor.mutate(updatedValues, {
        onSuccess: async () => {
          toast.success(
            id ? t("monitor.updated") : t("monitor.added")
          )
          resetForm({ values: "" })
          queryClient.invalidateQueries(["getAidMonitorList"])
          queryClient.invalidateQueries(["getAidMonitorAuditLog"])
          nav("/aidmonitor")
        }
      })
    }
  })

  const { data } = useGetAidMonitorAuditLog(
    {
      monitor_id: id
    },
    { enabled: id ? true : false }
  )

  const auditData = data?.data?.data?.results || []

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2} display={"flex"}>
          <NCText variant={"h4"} style={{ marginRight: "10px" }}>
            {edit ? "Update Aid Monitor" : "Add Aid Monitor"}
          </NCText>
        </Box>
        <Box mb={2} mr={3}>
          <Box display={"flex"}>
            {!isSmallScreen && user.user_type === 1 && id ? (
              <NCAuditButton data={auditData} isLoading={isLoading} />
            ) : null}
            <Box marginLeft={"5px"}>
              <NotificationAlert />
            </Box>
          </Box>
        </Box>
      </Grid>
      {isSmallScreen && (
        <Grid item xs={6} sm={4}>
          {user.user_type === 1 && id ? (
            <NCAuditButton data={auditData} isLoading={isLoading} />
          ) : null}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title=" Add Aid monitor">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={t("common.first_name")}
                      name={"first_name"}
                      required={true}
                      error={formik?.errors?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={t("common.middle_name")}
                      name={"middle_name"}
                      required={true}
                      error={formik?.errors?.middle_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={t("common.last_name")}
                      name={"last_name"}
                      required={true}
                      error={formik?.errors?.last_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={NCAutocomplete}
                      options={
                        Object.keys(GENDER).map(item => {
                          return { id: item, name: GENDER[item] }
                        }) || []
                      }
                      label={t("common.gender")}
                      name={"gender"}
                      onChange={v => {
                        formik.setFieldValue("gender", v)
                      }}
                      required={true}
                      error={formik?.errors?.gender}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={t("common.email_address")}
                      name={"email"}
                      required={true}
                      error={formik?.errors?.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={PhoneNumber}
                      label={t("common.phone")}
                      name={"phone"}
                      onChange={e => formik.setFieldValue("phone", `+${e}`)}
                      required={true}
                      error={formik?.errors?.phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={NCAddress}
                      label={t("common.address")}
                      name={"address"}
                      onChange={e => {
                        formik.setFieldValue(
                          "longitude",
                          e?.geometry?.longitude
                        )
                        formik.setFieldValue("latitude", e?.geometry?.latitude)
                        formik.setFieldValue("address", e?.name)
                      }}
                      required={true}
                      error={formik?.errors?.address}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection title={t("drivers.assign_dispatcher")}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormField
                      as={NCAutocomplete}
                      options={dispatchers || []}
                      label={t("monitor.select_dispatcher")}
                      name={"dispatcher"}
                      onChange={v => {
                        formik.setFieldValue("dispatcher", v)
                      }}
                      onSearch={v => setSearch(v)}
                      loading={dispatcherLoading}
                      required={true}
                      error={formik?.errors?.dispatcher}
                    />
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
          </Grid>
          <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <NCButton
                    onClick={() => nav("/aidmonitor")}
                    variant="outlined"
                    color="primary"
                  >
                    {t("common.cancel")}
                  </NCButton>
                </Grid>
                <Grid item>
                  <NCButton variant="contained" color="primary" type="submit">
                    {edit ? t("common.update") : t("common.save")}
                  </NCButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {addAidMonitor.isLoading && <NCLoader open={true} />}
          {isLoading && <NCLoader open={true} />}
        </Form>
      </FormikProvider>
    </Grid>
  )
}
export default AddAidMonitor
