import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from "@material-ui/core"
import { useState } from "react"
import { formatIncidentDate } from "../libs/utils"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  userListContainer: {
    marginTop: "10px",
    color: "rgba(16, 101, 142, 1)"
  },
  userItem: {
    borderBottom: "1px solid rgba(217, 217, 217, 1)",
    cursor: "pointer",
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  selectedUserItem: {
    borderBottom: "1px solid rgba(217, 217, 217, 1)",
    cursor: "pointer",
    paddingTop: "16px",
    paddingBottom: "16px",
    backgroundColor: "rgba(209, 252, 245, 1)"
  },
  avatar: {
    width: "76px",
    height: "76px",
    marginRight:"10px",
  },
}))

const NCList = props => {
  const cls = useStyles({})

  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState(null)

  const title = {
    fontSize: "18px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 700,
    color: "#000"
  }

  const subheading = {
    fontSize: "16px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 700,
    color: "#10658E"
  }

  return (
    <List className={cls.userListContainer} disablePadding>
      {props?.items?.map(item => {
        return (
          <ListItem
            className={
              selectedId !== item?.id ? cls.userItem : cls.selectedUserItem
            }
            onClick={() => {
              setSelectedId(item?.id)
              props?.onClick && props?.onClick(item)
            }}
          >
            <ListItemAvatar>
              <Avatar className={cls.avatar} src={item?.avatar}></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item?.title}
              primaryTypographyProps={{
                component: "span", // Setting the component as "div"
                style: title
              }}
              secondary={
                <div>
                  <span style={subheading}>{item.name}</span>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#000"
                    }}
                  >
                    <span>{t("incident.date_of_report")} </span>
                    <span
                      style={{
                        color: "#999999"
                      }}
                    >
                      {item.date &&
                        formatIncidentDate(item.date)}
                    </span>
                  </div>
                </div>
              }
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default NCList
