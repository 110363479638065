import { Box, Grid } from "@material-ui/core"
import React from "react"
import NCTitle from "../shared/NCTitle"
import { CloseIcon } from "../libs/icons"
import NCText from "../shared/NCText"
import NCLoader from "../shared/NCLoader"
import { formatAMPM, formatDate, loggedInUser } from "../libs/utils"
import { useEffect } from "react"
import { useRef } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

const ActivityLog = props => {
  const { showChatWindow, data, isLoading } = props
  const mobileTheme = useTheme()
  const isSmallScreen = useMediaQuery(mobileTheme.breakpoints.down("sm"))
  const user = loggedInUser()

  let showRef = useRef()

  useEffect(() => {
    if (user.user_type === 1) {
      let handler = e => {
        if (!showRef.current.contains(e.target)) {
          showChatWindow(false)
        }
      }
      document.addEventListener("mousedown", handler)

      return () => {
        document.removeEventListener("mousedown", handler)
      }
    }
  })

  return (
    <div ref={showRef}>
      <Box
        position={"fixed"}
        right={"5px"}
        bottom={"10px"}
        top={"70px"}
        width={isSmallScreen? 330 : 360}
        bgcolor={"#fff"}
        borderRadius={"20px"}
        border={"1px solid #ccc"}
        display={"flex"}
        flexDirection={"column"}
        p={2}
      >
        <Grid item>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={3}
          >
            <Box>
              <NCTitle style={{ fontSize: "22px" }}>Activity Log</NCTitle>
            </Box>
            <Box
              border={"1px solid red"}
              p={1}
              borderRadius={4}
              className="cursor-pointer"
              onClick={() => showChatWindow(false)}
            >
              <CloseIcon style={{ margin: "2px" }} width={13} height={12} />
            </Box>
          </Box>
          <hr></hr>
        </Grid>
        <Box
          style={{
            overflow: "hidden",
            overflowY: "scroll",
            paddingRight: "5px"
            // width: "400px"
          }}
        >
          {data.map(currentData => {
            if (currentData.history_change_reason) {
              return (
                <Grid
                  style={{
                    border: "1px solid #ccc",
                    padding: "3px",
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginBottom: "20px"
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Box>
                      <NCText
                        style={{
                          fontWeight: "bold",
                          marginRight: "25px"
                        }}
                      >
                        {currentData.history_user}
                      </NCText>
                    </Box>
                    <Box color={"#9CA3AF"}>
                      <NCText style={{ fontSize: "14px" }}>
                        {`${formatDate(currentData.history_date)}, ${formatAMPM(
                          currentData.history_date
                        )}`}
                      </NCText>
                    </Box>
                  </Box>
                  <Box paddingBottom={1} paddingTop={1} paddingLeft={1} p={1}>
                    <NCText
                      variant={"body2"}
                      style={{
                        color: "#6B7280"
                      }}
                    >
                      {currentData.history_change_reason}
                    </NCText>
                  </Box>
                </Grid>
              )
            }
          })}
        </Box>
      </Box>
      {isLoading && <NCLoader open={true} />}
    </div>
  )
}

export default ActivityLog
