import { Box, Grid, makeStyles } from "@material-ui/core"
import NCText from "../../shared/NCText"
import { useGetTrainingById } from "../../services/queries"
import { useNavigate, useParams } from "react-router-dom"
import NCLoader from "../../shared/NCLoader"
import TopNavBar from "../../components/TopNavBar"
import FormSection from "../../components/FormSection"
import { downloadFileFromURL } from "../../libs/utils"
import NCButton from "../../shared/NCButton"
import { PDF, TEXTDATA, VIDEOAUDIO } from "./training-const"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  addLessonHeading: {
    backgroundColor: "rgba(11, 155, 182, 0.21)",
    padding: "10px 20px",
    marginBottom: "10px",
    borderRadius: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  link: {
    cursor: "pointer",
    color: "#3498db"
  },
  markCorrect: {
    fontSize: "18px",
    color: "#1EAAC4",
    display: "flex",
    alignItems: "center",
    height: "100%",
    cursor: "pointer",
    textDecoration: "underline"
  },
  correct: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "#1DB954"
  },
  lessonType: {
    margin: "10px 0px"
  }
}))

const TrainingDetails = () => {
  const cls = useStyles()
  const nav = useNavigate()
  const { id } = useParams()
  const {t} = useTranslation();
  const { data, isLoading } = useGetTrainingById(id)

  const trainingData = data?.data?.data || {}

  const getPDFName = (link) => {
    return link?.split("/")?.pop() || ""
  }

  const handlePDFDownload = (url) => {
    const name = url?.split("/")?.pop() || "";
    downloadFileFromURL(url,name)
  }

  const renderRowItem = (key, value) => {
    return (
      <>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText className="overflow-ellipsis">{value}</NCText>
      </>
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" mb={20}>
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <NCText variant={"h4"}>{t("training.training_details")}</NCText>
                <div>
                  <NCButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      nav(`/training/${id}/edit`)
                    }}
                  >
                    {t("training.edit_training")}
                  </NCButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <FormSection
              title={
                <span style={{ fontWeight: "500" }}>{t("training.training_details")}</span>
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {renderRowItem(t("training.name_of_training"), trainingData?.name)}
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderRowItem(t("training.topic"), trainingData?.topic)}
                </Grid>
                <Grid item xs={12}>
                  {renderRowItem("training.full_description", trainingData?.description)}
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid item xs={12}>
            <>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                className={cls.addLessonHeading}
              >
                <NCText>{t("training.lessons")}</NCText>
              </Box>

              {trainingData.lessons?.map((lesson, index) => {
                return (
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container className={cls.lessonType}>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                          {lesson?.type === VIDEOAUDIO && renderRowItem(t("training.lesson_type"),t("training.video_audio"))}
                          {lesson?.type === PDF && renderRowItem(t("training.lesson_type"),t("training.pdf"))}
                          {lesson?.type === TEXTDATA && renderRowItem(t("training.lesson_type"),t("training.text"))}
                        </Grid>
                        {lesson?.type === PDF && (
                          <Grid item xs={12} style={{marginTop:"15px"}}>
                            <FormSection
                              title={
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500"
                                    }}
                                  >
                                    {t("training.upload_pdf")}
                                  </span>
                                </Box>
                              }
                            >
                              <FormSection
                                title={"File Name"}
                                subSection={true}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  p={1}
                                >
                                  <NCText>
                                    <Box
                                      className={cls.link}
                                      fontWeight={"700"}
                                      onClick={handlePDFDownload.bind(this,lesson?.file)}
                                    >
                                      {getPDFName(lesson?.file)}
                                    </Box>
                                  </NCText>
                                </Box>
                              </FormSection>
                            </FormSection>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {lesson?.type !== PDF && (
                      <Grid item xs={12} style={{marginBottom:"20px"}}>
                        {lesson?.type === VIDEOAUDIO &&
                          renderRowItem(t("training.video_audio"), lesson?.vimeo_url)}
                        {lesson?.type === TEXTDATA &&
                          renderRowItem(t("training.text"), lesson?.text)}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormSection
                        title={
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500"
                              }}
                            >
                              {t("training.lesson_no")}{index + 1}
                            </span>
                          </Box>
                        }
                      >
                        <Grid container spacing={2}>
                          {lesson.questions?.map((question, qIndex) => {
                            return (
                              <>
                                <Grid item xs={12}>
                                  {renderRowItem(
                                    `${t("training.question_no")} ${qIndex + 1}`,
                                    question?.question
                                  )}
                                </Grid>
                                {question?.answers?.map((answer, aIndex) => {
                                  return (
                                    <>
                                      <Grid item md={9} xs={12}>
                                        {renderRowItem(
                                          `${t("training.answer_no")} ${aIndex + 1}`,
                                          answer?.answer
                                        )}
                                      </Grid>
                                      <Grid item md={3} xs={12}>
                                        {!answer.is_correct ? null : (
                                          <div className={cls.correct}>
                                            <NCText> {t("training.correct_answer")} </NCText>
                                          </div>
                                        )}
                                      </Grid>
                                    </>
                                  )
                                })}
                                <Grid item xs={12}>
                                  <hr style={{ borderTop: "1px solid #eee" }} />
                                </Grid>
                              </>
                            )
                          })}
                        </Grid>
                      </FormSection>
                    </Grid>
                  </Grid>
                )
              })}
            </>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <NCLoader />}
    </Grid>
  )
}

export default TrainingDetails
