import { Box, Grid, makeStyles } from "@material-ui/core"
import { ABOUT_US, CONTACT_US, DOWNLOAD_APP, FAQs } from "./const"
import { Link, useLocation } from "react-router-dom"
import { useEffect } from "react"

const useStyles = makeStyles(theme => ({
  circle: {
    width: "16px",
    height: "16px",
    borderRadius: "50%"
  },
  lightCircle: {
    border: "3px solid transparent",
    backgroundColor: "white"
  },
  darkCircle: {
    border: "3px solid white",
    backgroundColor: "black"
  },
  lightOutline: {
    border: "3px solid #10658E",
    outline: "solid",
    outlineColor: "white"
  },
  darkOutline: {
    outline: "solid",
    outlineColor: "black"
  },
  darkMode: {
    color: "black"
  },
  lightMode: {
    color: "white"
  },
  highlighter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative"
  },
  navLinkDark: {
    marginLeft: "10px",
    textDecoration: "none",
    color: "black",
    fontSize: "16px"
  },
  navLinkLight: {
    marginLeft: "10px",
    textDecoration: "none",
    color: "white",
    fontSize: "16px"
  },
  navContainer: {
    marginLeft: "5rem",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  dot: {
    lineHeight: 0.4
  }
}))

const Navigation = ({ currentPage, mode }) => {
  const cls = useStyles()

  const location = useLocation()

  const lines = Array(12)
    .fill(0)
    .map((_, index) => (
      <span
        style={{ width: "22px", display: "inline-block", textAlign: "center" }}
        className={mode === "dark" ? cls.darkMode : cls.lightMode}
        key={index}
      >
        '
      </span>
    ))

  const items = [
    {
      name: DOWNLOAD_APP,
      href: "#downloadApp"
    },
    {
      name: ABOUT_US,
      href: "#aboutUs"
    },
    {
      name: CONTACT_US,
      href: "#contactUs"
    },
    {
      name: FAQs,
      href: "#faqs"
    }
  ]

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""))
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [location])

  return (
    <Grid
      container
      className={cls.navContainer}
      direction="row"
      alignItems="center"
    >
      <Grid item xs={12}>
        {items?.map((item, index) => {
          const circleClassName =
            item.name === currentPage
              ? mode === "dark"
                ? cls.circle + " " + cls.darkCircle + " " + cls.darkOutline
                : cls.circle + " " + cls.lightCircle + " " + cls.lightOutline
              : mode === "dark"
              ? cls.circle + " " + cls.darkCircle
              : cls.circle + " " + cls.lightCircle

          return (
            <Box justifyContent="flex-start" display="flex" key={index}>
              <div>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={cls.dot}
                  alignContent={"center"}
                >
                  <div className={circleClassName}></div>
                  {index < 3 && lines}
                </Box>
              </div>
              <Link
                to={item.href}
                className={mode === "dark" ? cls.navLinkDark : cls.navLinkLight}
              >
                {item.name}
              </Link>
            </Box>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default Navigation
