import React, { useEffect, useState } from "react"
import { Avatar, Box, CardMedia, Grid, makeStyles } from "@material-ui/core"
import Car from "../../assets/images/car.png"
import { useParams } from "react-router-dom"
import { useGetIncidentreportDetail } from "../../services/queries"
import NCLoader from "../../shared/NCLoader"
import { formatIncidentDate, formatTime } from "../../libs/utils"
import { GeoLocationIcon } from "../../libs/icons"
import FormSection from "../../components/FormSection"
import NCText from "../../shared/NCText"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 25px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "20px"
    }
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#10658E"
  },
  subheading: {
    fontSize: "18px"
  },
  lightSubheading: {
    color: "#666"
  },
  date: {
    color: "#666",
    padding: "10px",
    display: "flex",
    gap: "50px",
    alignItems: "center"
  },
  driverName: {
    fontSize: "32px",
    color: "#10658E"
  },
  driverSubheading: {
    fontSize: "16px"
  },
  carModel: {
    color: "#2C2C2C",
    fontSize: "14px"
  },
  carname: {
    fontSize: "12px",
    color: "#2C2C2C"
  },
  avatar: {
    width: "72px",
    height: "72px"
  },
  avatarSmall: {
    width: "48px",
    height: "48px"
  },
  rightContainer: {
    backgroundColor: "#eee"
  },
  card: {
    backgroundColor: "white",
    borderRadius: "20px"
  },
  cardHeading: {
    fontSize: "26px",
    color: "#111827",
    marginBottom: "20px",
    backgroundColor: "#eee"
  },
  cardDescription: {
    fontSize: "16px",
    background: "white",
    paddingLeft: "15px",
    paddingRight: "15px"
  },
  cardSubheadings: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "10px"
  },
  media: {
    maxWidth: "253px",
    maxHeight: "143px"
  },
  geoIcon: {
    cursor: "pointer"
  },
  payemntText: {
    background: "#F3F3F3",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "6px",
    paddingBottom: "6px",
    display: "inline-block",
    borderRadius: "50px"
  },
  paymentText: {
    fontSize: "12px",
    color: "#000000",
    fontWeight: 600
  },
  label: {
    fontWeight: 500
  },
  blueText: {
    color: "#1EAAC4",
    fontSize: "14px",
    fontWeight: "bold"
  },
  labelSmall: {
    fontWeight: 700,
    fontSize: "10px",
    color: "#111827"
  },
  address: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#374151",
    marginTop: "10px",
    marginBottom: "10px"
  },
  price: {
    fontWeight: 400,
    fontSize: "22px"
  },
  cardNum: {
    fontWeight: 200,
    fontSize: "12px",
    color: "#212121",
    marginTop: "10px"
  }
}))

const IncidentsDetail = () => {
  const cls = useStyles()

  const { id } = useParams()

  const { t } = useTranslation()

  const [details, setDetails] = useState(null)

  const { data, isLoading } = useGetIncidentreportDetail(id)

  const [imageList, setImageList] = useState([])

  const [videoList, setVideoList] = useState([])

  useEffect(() => {
    const reportDetails = data?.data?.data
    const images = reportDetails?.files?.filter(data => {
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "webp",
        "svg"
      ]
      const extension = data?.document.split(".").pop().toLowerCase()

      if (imageExtensions.includes(extension)) {
        return true
      }
      return false
    })

    const videos = reportDetails?.files?.filter(data => {
      const videoExtensions = [
        "mp4",
        "avi",
        "mov",
        "wmv",
        "flv",
        "mkv",
        "webm",
        "ogg"
      ]

      const extension = data?.document.split(".").pop().toLowerCase()

      if (videoExtensions.includes(extension)) {
        return true
      }
      return false
    })

    setImageList(images)
    setVideoList(videos)
    setDetails(reportDetails)
  }, [data])

  return (
    <>
      <Box className={cls.heading}>
        <div>
          <NCText>
            <div className={cls.title}>{details?.title}</div>
          </NCText>
          <div className={cls.subheading}>
            <NCText>
              <span className={cls.lightSubheading}>
                {t("incident.incident_id")}:{" "}
              </span>
              <Box display={"inline"} fontWeight={"bold"}>
                {details?.id}
              </Box>
            </NCText>
          </div>
        </div>
        <Box display={"flex"} gridGap={"10px"} alignItems={"center"}>
          <Avatar
            className={cls.avatar}
            src={details?.driver_details?.avatar}
          ></Avatar>
          <div>
            <NCText>
              <div className={cls.driverName}>
                {details?.trip?.driver?.name}
              </div>
              <div className={cls.driverSubheading}>
                <Box display={"inline"} fontWeight={"bold"}>
                  {t("incident.date_of_report")}:{" "}
                </Box>
                <span className={cls.lightSubheading}>
                  {formatIncidentDate(details?.created_at)}
                </span>
              </div>
            </NCText>
          </div>
        </Box>
        <Box display={"flex"} alignItems={"flex-end"} flexDirection={"column"}>
          <NCText>
            <Box className={cls.carModel}>
              {`${details?.trip?.driver?.vehicle_info?.model || ""} - ${details?.trip?.driver?.vehicle_info?.mark || ""}`}
            </Box>
            <img src={Car} width={"43px"} />
            {
              <Box className={cls.carname}>
                {details?.trip?.driver?.vehicle_info?.licence_plate}
              </Box>
            }
          </NCText>
        </Box>
      </Box>
      <Box className={cls.rightContainer}>
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12}>
            <Grid item xs={12} className={cls.card}>
              <FormSection
                title={
                  <div style={{ fontSize: "24px" }}>
                    {t("incident.description")}
                  </div>
                }
              >
                <Box className={cls.cardDescription}>
                  <NCText>{details?.description}</NCText>
                </Box>
              </FormSection>
            </Grid>
            <Grid item xs={12} className={cls.card}>
              <FormSection
                title={
                  <div style={{ fontSize: "24px" }}>
                    {t("incident.uploads")}
                  </div>
                }
              >
                <Box className={cls.cardDescription}>
                  <NCText>
                    <div className={cls.cardSubheadings}>
                      {t("incident.picture")}
                    </div>
                  </NCText>
                  <Grid container>
                    {imageList?.map(image => {
                      return (
                        <Grid item md={6} xs={12}>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            flexDirection={"column"}
                          >
                            <CardMedia
                              component="img"
                              image={image.document}
                              alt="description"
                              sx={{
                                objectFit: "cover"
                              }}
                              className={cls.media}
                            />
                            <div className={cls.date}>
                              <NCText>
                                {formatIncidentDate(image.created_at)}
                              </NCText>
                              <GeoLocationIcon
                                title={`${image.latitude}, ${image.longitude}`}
                                className={cls.geoIcon}
                              />
                            </div>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                  <NCText>
                    <div className={cls.cardSubheadings}>
                      {t("incident.videos")}
                    </div>
                  </NCText>
                  <Grid container>
                    {videoList?.map(video => {
                      return (
                        <Grid item md={6} xs={12}>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            flexDirection={"column"}
                          >
                            <Box
                              component="video"
                              src={video.document}
                              controls
                              sx={{
                                objectFit: "cover"
                              }}
                              className={cls.media}
                            />
                            <div className={cls.date}>
                              <NCText>
                                {formatIncidentDate(video.created_at)}
                              </NCText>
                              <GeoLocationIcon
                                title={`${video.latitude}, ${video.longitude}`}
                                className={cls.geoIcon}
                              />
                            </div>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </FormSection>
            </Grid>
            <Grid item xs={12} className={cls.card}>
              <FormSection
                title={
                  <div style={{ fontSize: "24px" }}>
                    {t("incident.ride_details")}
                  </div>
                }
              >
                <Box className={cls.cardDescription}>
                  <Box marginTop={2}>
                    <NCText className={cls.cardSubheadings}>
                      {t("incident.booked_by")}
                    </NCText>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                      spacing={2}
                    >
                      <Grid container xs={10}>
                        <Grid item xs={1}>
                          <Avatar
                            className={cls.avatarSmall}
                            src={details?.trip?.client?.avatar}
                          />
                        </Grid>
                        <Grid item xs={10} style={{ paddingLeft: "25px" }}>
                          <div className={cls.label}>
                            <NCText>{details?.trip?.client?.name}</NCText>
                          </div>
                          <Box
                            className={cls.payemntText}
                            display="flex"
                            flexWrap={"wrap"}
                            alignItems="center"
                            justifyContent="center"
                            marginY="2px"
                          >
                            <NCText>
                              <span className={cls.paymentText}>{`${t(
                                "incident.pay_method"
                              )}`}</span>
                            </NCText>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          style={{ textAlign: "right" }}
                        >
                          <NCText subtitle2="h2">
                            {t("incident.booking_time")}
                          </NCText>
                          <NCText>
                            <Box
                              className={cls.blueText}
                              flexDirection="row"
                              alignSelf="flex-end"
                            >
                              {formatIncidentDate(
                                details?.trip?.created,
                                "MMM D, YYYY"
                              )}
                            </Box>
                          </NCText>
                          <NCText>
                            <Box className={cls.blueText}>
                              {formatIncidentDate(
                                details?.trip?.created,
                                "hh:mma"
                              )}
                            </Box>
                          </NCText>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box marginTop={2}>
                    <NCText>
                      <div className={cls.cardSubheadings}>
                        {t("incident.pick_drop_details")}
                      </div>
                    </NCText>
                    <NCText>
                      <span className={cls.labelSmall}>
                        {t("map.pickup_location")}
                      </span>
                    </NCText>
                    <NCText>
                      <span className={cls.address}>
                        {details?.trip?.pickup_address?.address}
                      </span>
                    </NCText>
                    <NCText>
                      <div
                        className={cls.blueText}
                        style={{ fontSize: "10px" }}
                      >
                        {`${t("incident.arrived_on")}: ${formatTime(
                          details?.trip?.timing_details?.arrival_time
                        )}`}
                      </div>
                    </NCText>
                  </Box>
                  <Box marginTop={2}>
                    <NCText>
                      <span className={cls.labelSmall}>
                        {t("map.drop_location")}
                      </span>
                    </NCText>
                    <NCText>
                      <span className={cls.address}>
                        {details?.trip?.drop_off_address?.address}
                      </span>
                    </NCText>
                    <NCText>
                      <div
                        className={cls.blueText}
                        style={{ fontSize: "10px" }}
                      >
                        {`${t("incident.drop_completed")}: ${formatTime(
                          details?.trip?.timing_details?.dropped_time
                        )}`}
                      </div>
                    </NCText>
                  </Box>
                  <Box marginTop={2}>
                    <NCText>
                      <div className={cls.cardSubheadings}>
                        {t("incident.price_details")}
                      </div>
                    </NCText>
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        <NCText subtitle2="h2">{t("incident.price")}</NCText>
                        <div className={`${cls.blueText} ${cls.price}`}>
                          {details?.trip?.payment_detail?.fare ? ("$" + details?.trip?.payment_detail?.fare) : "-"}
                        </div>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Box
                          display={"flex"}
                          flexDirection="column"
                          justifyContent={"flex-end"}
                          alignItems={"flex-end"}
                        >
                          <NCText subtitle2="h2">
                            {t("incident.card_type")}:{" "}
                            {details?.trip?.card_type?.toUpperCase()}
                          </NCText>
                          <Box
                            className={cls.cardNum}
                          >{details?.trip?.last_4_digit ? `**** ${details?.trip?.last_4_digit}` : "-"}</Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </FormSection>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {isLoading && <NCLoader open={true} />}
    </>
  )
}

export default IncidentsDetail
