import { Grid, makeStyles } from "@material-ui/core"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  footer: {
    background: "#10658E",
    padding: "20px 20px 40px 20px",
    color: "white",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  trademark: {
    flex: "1",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px"
    }
  },
  link: {
    color: "white",
    textDecoration: "none"
  }
}))

const Footer = () => {
  const cls = useStyles()

  return (
    <div className={cls.footer}>
      <div className={cls.trademark}>
        &copy; Noah Cares. All right reserved.
      </div>
      <Grid container justifyContent="center" style={{ flex: "4" }}>
        <Grid item>
          <Link className={cls.link} to={"/terms-conditions"}>
            Terms & Conditions
          </Link>
        </Grid>
        <Grid item>
          <Link
            className={cls.link}
            to={"/privacy-policy"}
            style={{ marginLeft: "80px" }}
          >
            Privacy Statement
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
